module App {
    export class AppTrashController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;

        //compute
        trashGrid: kendo.ui.Grid;
        trashGridOptions: kendo.ui.GridOptions;
        selectedBase: number;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$http', 'appConfig', 'authService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void { }
        // ========================================
        // CALLBACK
        // ========================================
        onOwnershipSelect(i: IOwnerShipViewModel) {
            this.selectedBase = i.Key;
            if (i) this.trashGridOptions = this.getGridOptions(i.Key);
        }
        // ========================================
        // PUBLIC
        // ========================================
        doesExist(input:ITrashViewModel): ng.IPromise<any> {
            if (input.IsFolder) {
                return this.appConfig.request.Folder.FolderExists(this.$http, input.ParentFolderId, input.Name).then(okResponse => {
                    return okResponse.data;
                }, errResponse => {
                    this.helperService.error(errResponse);
                });

            } else {
                return this.appConfig.request.Folder.FileExists(this.$http, input.ParentFolderId, input.Name).then(okResponse => {
                    return okResponse.data;
                }, errResponse => {
                    this.helperService.error(errResponse);
                });
            }

        };

        restore(input:ITrashViewModel) {
            if (input.IsFolder) {
                this.appConfig.request.Restore.RestoreFolderFromTrash(this.$http, this.selectedBase, input.Id).then(okResponse => {
                    this.trashGrid.dataSource.read();
                }, e => this.helperService.error(e));
            } else {
                this.appConfig.request.Restore.RestoreFileFromTrash(this.$http, input.Id).then(okResponse => {
                    this.trashGrid.dataSource.read();
                }, e => this.helperService.error(e));
            }
        };



        // ========================================
        // PRIVATE
        // ========================================
        private getGridOptions(baseId: number): kendo.ui.GridOptions {
            var that = this;
            return {
                "columns": [
                    {
                        "field": "Name",
                        "title": "{{'Tenant.Trash.Name' | translate}}",
                        "filterable": true,
                        "template": '<span class="glyphicon #if (IsFolder) {# glyphicon-folder-close #} else {# glyphicon-file #}#"></span> #=Name#<br/><small class="pull-right">(in #=Location#)</small>'
                    }, {
                        "field": "TrashDate",
                        "title": "{{'Tenant.Trash.TrashDate' | translate}}",
                        "format": "{0:g}",
                        "filterable": true
                    }, {
                        "field": "Size",
                        "title": "{{'Tenant.Trash.Size' | translate}}",
                        "filterable": false,
                        "template": "#=SizeReadable#"
                    }, {
                        "width": 200,
                        "command": [
                            { "name": "restore", "text": "{{'Tenant.Trash.Restore' | translate}}", "click": function(e: Event) {
                                e.preventDefault();
                                //var tr = $(e.target).closest("tr");
                                //var item = this.dataItem(tr);
                                let dataItem = <any> that.trashGrid.dataItem(that.trashGrid.select()) ;
                                that.doesExist(dataItem).then(data => {
                                    if (data) {
                                        if (dataItem.get("IsFolder")) {
                                            that.helperService.alert('Tenant.Trash.FolderExistErr', true);
                                        } else {
                                            that.helperService.alert('Tenant.Trash.FileExistErr', true);
                                        }
                                    } else {
                                        that.restore(dataItem);
                                    }
                                });
                                that.$scope.$digest();
                                
                            } }
                        ]
                    }
                ],
                "pageable": true,
                "sortable": true,
                "scrollable": false,
                "filterable": true,
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.Restore.TrashUrl(baseId), this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 20,
                    "serverSorting": true,
                    "serverFiltering": true,
                    //"serverGrouping": true,
                    //"serverAggregates": true,
                    "sort": [{ "field": "TrashDate", "dir": "desc" }],
                    "filter": [],
                    "error": function(e:any) {
                        that.authService.kendoError(e, that.trashGrid);
                    },
                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "number" },
                                "ParentFolderId": { "type": "number" },
                                "IsFolder": { "type": "boolean" },
                                "Name": { "type": "text" },
                                "Size": { "type": "number" },
                                "TrashDate": { "type": "date" },
                                "Location": { "type": "text" },
                                "SizeReadable": { "type": "text" }
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    }

                }
            }
        };
    }


    export class AppTrashComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<'
        };

        controller = AppTrashController;
        templateUrl="/AdminTemplate/TrashTemplate";
    }

    angular.module("app").component("appTrash", new AppTrashComponent());
}


