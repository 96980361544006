module App {
    // Add Information To Route
    export interface IAppState extends angular.ui.IState {
        title?: string;
        twoFactor?: boolean;
        url: string;
        auth?: boolean;
    }

    export class AppResolves {
        // http promises
        branding(companyId: string) {
            return [
                '$http', 'appConfig', ($http: ng.IHttpService, appConfig: AppConfig) =>
                appConfig.request.CompanyManagement.GetBranding($http, null)
            ];
        }

        tenantList = ['$http', 'appConfig', ($http: ng.IHttpService, appConfig: AppConfig) =>
            appConfig.request.TenantManagement.GetTenantList($http)];

        inheritedPermissions = ['$http', '$transition$', '$q', 'appConfig', ($http: ng.IHttpService, $transition$:any, $q: ng.IQService, appConfig: AppConfig) => {
            const folderId = $transition$.params()['folderId'];
            return appConfig.request.Folder.GetInheritedPermissions($http, folderId).then(ok => {
                return ok.data;
            }, err => $q.reject(err));
        }];

        myPermissions = ['$http', '$transition$', '$q', 'appConfig', ($http: ng.IHttpService, $transition$:any, $q: ng.IQService, appConfig: AppConfig) => {
            const folderId = $transition$.params()['folderId'];
            return appConfig.request.Folder.GetMyPermissions($http, folderId).then(ok => {
                return ok.data;
            }, err => $q.reject(err));
        }];

        // custom promises
        currentUser = ['authService', (authService: AuthService) =>
            authService.init(false, false)];

        currentUserReload = ['authService', (authService: AuthService) =>
            authService.init(true, false)];

        
        eventlogAction = ['coreDataService', (coreDataService: CoreDataService) =>
            coreDataService.enumToList('EventlogAction')];

        fileAccessType = ['coreDataService', (coreDataService: CoreDataService) =>
            coreDataService.enumToList('FileAccessType')];

        folderAccessType = ['coreDataService', (coreDataService: CoreDataService) =>
            coreDataService.enumToList('FolderAccessType')];

        languageType = ['coreDataService', (coreDataService: CoreDataService) =>
            coreDataService.enumToList('Language')];

        noteType = ['coreDataService', (coreDataService: CoreDataService) =>
            coreDataService.enumToList('NoteType')];

        folder = ['$transition$', 'filesystemService', ($transition$: any, filesystemService: FilesystemService) => {
                    const folderId = $transition$.params()['folderId'];
                    return filesystemService.getFolder(folderId);
                }
            ];
           
        // special promises
        trans(t: string[]) {
            return ['$translate', ($translate: angular.translate.ITranslateService) =>
                $translate(t)];
        }

        param(p: string) {
            return ['$transition$', ($transition$: any) => {
                const allP = $transition$.params();
                //console.log(allP as any);
                if (allP[p]) return $transition$.params()[p];
                return ""; 
            }];
        } 

    };


    angular.module("app", ["ui.router", "ngSanitize", "kendo.directives", "flow", "pascalprecht.translate", "ngCookies", "tmh.dynamicLocale", 'LocalStorageModule']);

    angular.module("app").config([
        "$locationProvider", "$stateProvider", "$urlRouterProvider", "$httpProvider", "$translateProvider", "$translatePartialLoaderProvider", "tmhDynamicLocaleProvider",
        ($locationProvider: ng.ILocationProvider, $stateProvider: ng.ui.IStateProvider, $urlRouterProvider: ng.ui.IUrlRouterProvider, $httpProvider: ng.IHttpProvider, $translateProvider: angular.translate.ITranslateProvider,
            $translatePartialLoaderProvider: any, tmhDynamicLocaleProvider: any) => {


            // =============================
            // LOCATION
            // =============================
            $locationProvider.hashPrefix('');

            // =============================
            // NG-HTTP
            // =============================
            $httpProvider.defaults.headers = $httpProvider.defaults.headers || {}
            $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
            // Disable Caching for IE (Expires and/or If-Modified-Since can be inserted if further problems occur)
            //initialize get if not there

            //Cache Control: Prevent Caching of IE GET Requests
            $httpProvider.defaults.headers.get = $httpProvider.defaults.headers.get || {};
            $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Sat, 01 Jan 2000 00:00:00 GMT';
            $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache, no-store, must-revalidate';
            $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

            // INTERCEPTORS
            $httpProvider.interceptors.push("authInterceptor");

            // =============================
            // NG-TRANSLATE
            // =============================
            // select default partial. More partials will be loaded by the controllers.
            $translatePartialLoaderProvider.addPart('Branding');
            $translatePartialLoaderProvider.addPart('Core');
            $translatePartialLoaderProvider.addPart('Datarooms');
            $translatePartialLoaderProvider.addPart('Manager');
            $translatePartialLoaderProvider.addPart('Messenger');
            $translatePartialLoaderProvider.addPart('Note');
            $translatePartialLoaderProvider.addPart('Tenant');

            $translateProvider
                // partial translation tables from server
                .useLoader("$translatePartialLoader", { urlTemplate: "/Resources/{part}-{lang}.json" })

                // map machine locale to used locale, all non matched get the wildcard
                .registerAvailableLanguageKeys(['en', 'de', 'it'], {
                    'en*': 'en',
                    'de*': 'de',
                    'it*': 'it',
                    '*': 'de'
                })

                // get locale from browser settings
                .determinePreferredLanguage()

                // fallback if a single ID is not available in the used language
                // warning: search will not start at the beginning but from the currently active locale towards right direction
                .fallbackLanguage(['en'])

                // save manually selected language
                .useCookieStorage()

                // needed or you will get warnings in the browser
                .useSanitizeValueStrategy('sanitizeParameters')

                // log missing translations to console
                .useMissingTranslationHandlerLog();

            // =============================
            // DYNAMIC LOCALE
            // =============================
            tmhDynamicLocaleProvider.localeLocationPattern('/Scripts/angular-locale_{{locale}}.js');

            // =============================
            // UI-ROUTER
            // =============================

            var res = new AppResolves();

            $stateProvider
                // Standard actions
                // ===============
                .state("search", <IAppState>{
                    url: "/Search",
                    title: "Datarooms.Search.Heading",
                    component: "appSearch",
                    resolve: {
                        currentUser: res.currentUser,
                    },
                    auth: true,
                })
                .state("statistics", <IAppState>{
                    url: "/Statistics",
                    title: "Tenant.Statistics.Header",
                    component: "appStatistics",
                        auth: true,
                })
                .state("upload", <IAppState>{
                    url: "/Upload/{folderId}", 
                    params: {
                        folderId: null //optional param
                    },
                    title: "Datarooms.Upload.Heading",
                    component: "appUpload",
                    resolve: {
                        folderId: res.param('folderId')
                    },
                        auth: true,
                })
                .state("notes", <IAppState>{
                    url: "/Notes",
                    title: "Note.Heading",
                    component: "appNote",
                    resolve: {
                        currentUser: res.currentUser,
                        trans: res.trans(['Manager.Tenant.ExcelPrefix']),
                        noteType: res.noteType
                    },
                        auth: true,
                })
                .state("conversations", <IAppState>{
                    url: "/Conversations",
                    title: "Messenger.ConversationsTitle",
                    component: "appConversations",
                        auth: true,
                })
                .state("conversation", <IAppState>{
                    url: "/Conversation/{userId}",
                    title: "Messenger.ConversationTitle",
                    component: "appConversation",
                    resolve: {
                        currentUser: res.currentUser,
                        userId: res.param('userId'),
                    },
                        auth: true,
                })


                // Account actions
                // ===============
                .state("settings", <IAppState>{
                    url: "/Settings",
                    title: "Core.Settings.Header",
                    component: "appSettings",
                    resolve: {
                        currentUser: res.currentUser,
                    },
                    auth: true,

                })
                .state("login", <IAppState>{
                    url: "/Login?redirect",
                    title: "Core.Login.Heading",
                    component: "appLogin",
                    resolve: {
                        currentUser: res.currentUser,
                        redirect: res.param('redirect'),
                    }
                })
                .state("changePassword", <IAppState>{
                    url: "/ChangePassword",
                    title: "Core.Password.Heading",
                    component: "appPassword",
                    resolve: {
                        currentUser: res.currentUser,
                    },
                    auth: true,

                })
                .state("twoFactorLogin", <IAppState>{
                    url: "/TwoFactorLogin",
                    title: "Core.TwoFactorLogin.Header",
                    component: "appTwoFactorLogin",
                    resolve: {
                        currentUser: res.currentUserReload,
                    },
                    auth: true,

                })


                // Public Share Actions
                // ====================
                .state("publicStart", <IAppState>{
                    url: "/Public/{shareId}",
                    title: "Datarooms.Datarooms.Heading",
                    component: 'appPublic',
                    resolve: {
                        currentUser: res.currentUser,
                        shareId: res.param('shareId'),
                    }
                })
                .state("public", <IAppState>{
                    url: "/Public/{shareId}/{folderId}?fileId",
                    params: {
                        folderId: null //optional param
                    },
                    title: "Datarooms.Datarooms.Heading",
                    component: 'appPublic',
                    resolve: {
                        currentUser: res.currentUser,
                        shareId: res.param('shareId'),
                        folderId: res.param('folderId'),
                        fileId: res.param('fileId'),
                    }
                })

                // Folder Actions
                // ==============
                .state("share", <IAppState>{
                    url: "/Share",
                    title: "Datarooms.Datarooms.Heading",
                    component: "appShare",
                    resolve: {
                        currentUser: res.currentUser,
                    },
                    auth: true,

                })
                .state("folder", <IAppState>{
                    url: "/Folder/{folderId}?error&timewarp",
                    params: {
                        folderId: null, //optional param
                    },
                    title: "Datarooms.Datarooms.Heading",
                    component: "appFolder",
                    resolve: {
                        currentUser: res.currentUser,
                        folderId: res.param('folderId'),
                        timewarp: res.param('timewarp'),
                        error: res.param('error'),
                    },
                    auth: true,
                    reloadOnSearch: false,
                })
                .state("trash", <IAppState>{
                    url: "/Trash",
                    title: "Tenant.Trash.Heading",
                    component: "appTrash",
                    resolve: {
                        currentUser: res.currentUser,
                    },
                    auth: true,
                    twoFactor: true,
                })
                .state("folderInformation", <IAppState>{
                    url: "/FolderInformation/{folderId}",
                    title: "Datarooms.FolderInformation.Heading",
                    component: "appFolderInformation",
                    resolve: {
                        currentUser: res.currentUser,
                        folderAccessType: res.folderAccessType,
                        folderId: res.param('folderId')
                    },
                    auth: true,

                })
                .state("fileInformation", <IAppState>{
                    url: "/FileInformation/{fileId}",
                    title: "Datarooms.FileInformation.Header",
                    component: "appFileInformation",
                    resolve: {
                        currentUser: res.currentUser,
                        fileAccessType: res.fileAccessType,
                        fileId: res.param('fileId'),
                    },
                    auth: true,

                })
                .state("folderSecurity", <IAppState>{
                    url: "/FolderSecurity/{folderId}",
                    title: "Datarooms.FolderSecurity.Heading",
                    component: "appFolderSecurity",
                    resolve: {
                        currentUser: res.currentUser,
                        folder: res.folder,
                        inheritedPermissions: res.inheritedPermissions,
                        myPermissions: res.myPermissions
                    },
                    auth: true,
                    twoFactor: true,
                })
                .state("downloadFile", <IAppState>{
                    url: "/DownloadFile/{fileId}?error",
                    title: "Datarooms.DownloadFile.Header",
                    component: 'appDownloadFile',
                    resolve: {
                        currentUser: res.currentUser,
                        trans: res.trans(['Datarooms.DownloadFile.NotFound', 'Datarooms.DownloadFile.Logon']),
                        fileId: res.param('fileId'),
                        error: res.param('error'),
                    }
                })
                .state("downloadFolder", <IAppState>{
                    url: "/DownloadFolder/{folderId}?error",
                    title: "Datarooms.DownloadFolder.Header",
                    component: 'appDownloadFolder',
                    resolve: {
                        currentUser: res.currentUser,
                        trans: res.trans(['Datarooms.DownloadFolder.NotFound', 'Datarooms.DownloadFolder.Logon']),
                        folderId: res.param('folderId'),
                        error: res.param('error'),
                    }
                })


                // Manager Actions
                // ===============
                .state("tenant", <IAppState>{
                    url: "/Tenant",
                    title: "Manager.Tenant.Heading",
                    component: 'appTenant',
                    resolve: {
                        currentUser: res.currentUser,
                        trans: res.trans(['Manager.Tenant.ExcelPrefix']),
                        language: res.languageType,
                    },
                    auth: true,
                    twoFactor: true,
                })
                .state("systemevent", <IAppState>{
                    url: "/Systemevent",
                    title: "Datarooms.Eventlog.Header",
                    component: 'appSystemevent',
                    resolve: {
                        eventlogAction: res.eventlogAction,
                    },
                    auth: true,
                    twoFactor: true,
                })
                .state("token", <IAppState>{
                    url: "/Token",
                    title: "Manager.Token.Heading",
                    component: 'appTokenManager',
                    resolve: {
                        currentUser: res.currentUser,
                        trans: res.trans(['Manager.Token.ExcelPrefix', 'Manager.Token.MobileApp', 'Manager.Token.SecurityToken']),
                        tenants: res.tenantList,
                    },
                    auth: true,
                    twoFactor: true,
                })
                .state("workerJobs", <IAppState>{
                    url: "/WorkerJobs",
                    title: "Worker Jobs",
                    component: 'appWorkerJobs',
                    auth: true,
                    twoFactor: true,
                })
                .state("languageCompare", <IAppState>{
                    url: "/LanguageCompare",
                    title: "Language Compare",
                    component: "appLanguageCompare",
                })
                .state("database", <IAppState>{
                    url: "/Database/{tenantId}",
                    title: "Database Check",
                    component: "appDatabase",
                    resolve: {
                        tenantId: res.param('tenantId'),
                    },
                    auth: true,


                })

                // Admin Actions
                // =============
                .state("accesslog", <IAppState>{
                    url: "/Accesslog",
                    title: "Datarooms.Accesslog.Heading",
                    component: 'appAccesslog',
                    resolve: {
                        currentUser: res.currentUser,
                        fileAccessType: res.fileAccessType,
                        folderAccessType: res.folderAccessType,
                    },
                    auth: true,
                    twoFactor: true,
                })
                .state("branding", <IAppState>{
                    url: "/Branding",
                    title: "Branding.Branding",
                    component: 'appBranding',
                    resolve: {
                        currentUser: res.currentUser,
                    },
                    auth: true,
                    twoFactor: true,
                })
                .state("currentUploads", <IAppState>{
                    url: "/CurrentUploads",
                    title: "Datarooms.CurrentUpload.Heading",
                    component: "appCurrentUpload",
                    resolve: {
                        currentUser: res.currentUser,
                        trans: res.trans(['Datarooms.CurrentUpload.Confirm'])
                    },
                    auth: true,
                    twoFactor: true,
                })
                .state("folderDesigner", <IAppState>{
                    url: "/FolderDesigner/{folderId}",
                    title: "Datarooms.FolderDesigner.Heading",
                    component:'appFolderDesigner',
                    resolve: {
                        currentUser: res.currentUser,
                        trans: res.trans(['Core.Buttons.Ok', 'Core.Buttons.Cancel']),
                        branding: res.branding(null),
                        folderId: res.param('folderId')
                    },
                    auth: true,
                    twoFactor: true,
                })
                .state("event", <IAppState>{
                    url: "/Event",
                    title: "Datarooms.Eventlog.Header",
                    component: 'appEvent',
                    resolve: {
                        eventlogAction: res.eventlogAction,
                    },
                    auth: true,
                    twoFactor: true,
                })
                .state("systemsettings", <IAppState>{
                    url: "/Systemsettings",
                    title: "Tenant.Systemsettings.Heading",
                    component: "appSystemsettings",
                    auth: true,
                    twoFactor: true,
                })
                .state("users", <IAppState>{
                    url: "/Users",
                    title: "Tenant.Users.Heading",
                    component: 'appUsers',
                    resolve: {
                        currentUser: res.currentUser,
                        trans: res.trans(['Tenant.Users.UserTab.ConfirmDeleteUser', 'Tenant.Users.UserTab.NewUser',
                            'Tenant.Users.UserTab.FullLicense', 'Tenant.Users.UserTab.LightLicense', 'Tenant.Users.UserTab.SmartPhoneAndToken',
                            'Tenant.Users.UserTab.NoTwoFactor', 'Tenant.Users.UserTab.SmartPhoneOnly', 'Tenant.Users.UserTab.TokenOnly',
                            'Tenant.Users.UserTab.ResetPasswordButton', 'Tenant.Users.UserTab.ChangeTwoFactorMethod']),
                        language: res.languageType,
                    },
                    twoFactor: true,
                    auth: true,
                })
                .state("tenantToken", <IAppState>{
                    url: "/TenantToken",
                    title: "Tenant.Token.Heading",
                    component: 'appTenantToken',
                    resolve: {
                        currentUser: res.currentUser,
                        trans: res.trans(['Tenant.Token.ExcelPrefix'])
                    },
                    auth: true,
                    twoFactor: true,
                })
                .state("noAdmin", <IAppState>{
                    url: "/NoAdmin/{folderId}",
                    title: "Datarooms.NoAdmin.BackToFolder",
                    component: "appNoAdmin",
                    resolve: {
                        currentUser: res.currentUser,
                        folderId: res.param("folderId"),
                    },
                    auth: true,
                    twoFactor: true,
                })

                // Information
                // ===========
                .state("home", <IAppState>{
                    url: "/Home",
                    title: "Core.Menu.Home",
                    component: "appHome",
                    resolve: {
                        currentUser: res.currentUser,
                    }
                })
                .state("impressum", <IAppState>{
                    url: "/Impressum",
                    title: "Impressum",
                    templateUrl: "/GuestTemplate/ImpressumTemplate",
                })
                .state("apiDocumentation", <IAppState>{
                    url: "/ApiDocumentation",
                    title: "API Documentation",
                    templateUrl: "/UserTemplate/ApiDocumentationTemplate",
                })
                ;

            $urlRouterProvider.otherwise("/Home");
        }
    ]);

    angular.module("app").run([
        "$rootScope", "$state", "$translate", "$transitions", "authService", "conversationService", "navigationService", "$timeout",
        ($rootScope: ng.IRootScopeService, $state: ng.ui.IStateService, $translate: angular.translate.ITranslateService,
            $transitions: any, authService: AuthService, conversationService: ConversationService, navigationService: NavigationService, $timeout: ng.ITimeoutService) => {

            authService.loadAuth();
            conversationService.init();

            // Redirect on required auth
            $transitions.onBefore({ to: (state: IAppState) => state.auth }, (t: any) => {
                authService.init(false, false).then(cu => {
                    if (!cu.isAuthenticated) {
                        navigationService.setRedirectRoute(t.$to().name, t.targetState()._params);
                        $state.go("login");
                    }
                });
            });

            // Redirect on required 2fa
            $transitions.onBefore({ to: (state: IAppState) => state.twoFactor }, (t: any) => {
                authService.init(false, false).then(cu => {
                    if (!cu.IsTwoFactorAuthenticated) {
                        navigationService.setRedirectRoute(t.$to().name, t.targetState()._params);
                        $state.go("twoFactorLogin");
                    }
                });

            });

            // set title
            $transitions.onSuccess({}, (t: any) => {
                //ToDo: Save Successful State

                if (t.$to().title && t.$to().title.indexOf('.')>-1) {
                    //timout needed for the browser history to be correct!
                    $timeout(() => {
                        $translate(t.$to().title).then(trans => $rootScope['title'] = trans);
                    }, 0, false);
                } else {
                    $rootScope['title'] = "";
                }
            });

            // Error
            $transitions.onError({}, (t: any) => {
                console.error("Route failed");
            });


        }
    ]);

}
