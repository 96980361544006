module App {
    export class DonutController implements ng.IComponentController {
        //binding
        maximumValue: number;
        currentValue: number;
        color: string;
        title: string;

        //compute
        donut: kendo.dataviz.ui.Chart;
        color2: string;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$translate', 'helperService'];
        constructor(
            private $translate: angular.translate.ITranslateService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            var selector = '#css2js .bg-' + this.color;
            this.color2 = $(selector).css('color');

        }

        $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj['currentValue']) {
                this.$translate(this.title).then(t => {
                    this.donut.setOptions(this.getOptions(t));
                    this.donut.refresh();
                    this.donut.redraw();
                });

            }
        }

        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        private getOptions(title:string): kendo.dataviz.ui.ChartOptions {
            return {
                title: {
                    position: "bottom",
                    text: title,
                    font: "12px Candara",
                    color: "white",
                    margin: {
                        top: -5,
                        right: -5,
                        bottom: -5,
                        left: -5
                    }
                    //margin: -5,
                },
                legend: {
                    visible: false,
                },
                chartArea: {
                    height: 100,
                    width: 70,
                    background: "transparent",
                    margin: { top: 20, left: 0, right: 0, bottom: 0 },
                },
                series: [{
                    name: "11",
                    type: "donut",
                    startAngle: 90,
                    size: 5,
                    holeSize: 20,
                    padding: 0,
                    overlay: { gradient: "none", },
                    labels: {
                        visible: false,
                        position: "center",
                        font: "30px Candara",
                        color: "white",
                        background: "transparent",
                        margin: {
                            top: -5,
                            right: -5,
                            bottom: -5,
                            left: -5
                        },
                        padding: {
                            top: -5,
                            right: -5,
                            bottom: -5,
                            left: -5
                        },

                        //margin: 0,
                        //padding: 0,
                        distance: 70,

                    },
                    data: [
                    {
                        category: this.title,
                        value: this.currentValue,
                        color: this.color2,
                    }, {
                        value: this.maximumValue - this.currentValue,
                        color: "transparent"
                    }]
                }]
            }
        };
    }


    export class DonutComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            maximumValue: '@',
            currentValue: '<',
            color: '@',
            title: '@',
        };

        controller:any = DonutController;
        //templateUrl='';
        template = `
            <div class="donutLabel">{{$ctrl.currentValue}}</div>
            <div kendo-chart="$ctrl.donut"></div>
        `;
    }

    angular.module("app").component("donut", new DonutComponent());
}
