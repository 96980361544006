module App {
    export class AppBrandingController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;

        //compute
        okMessage = false;
        errMessage = false;
        flowObject = this.uploadService.flow;
        data: IBrandingViewModel;
        logoIsLoading: boolean;
        backgroundIsLoading: boolean;
        bannerIsLoading: boolean;
        cid: string = this.appConfig.helper.companyId();
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', 'appConfig', 'authService', 'helperService', 'uploadService'];
        constructor(
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private helperService: HelperService,
            private uploadService: UploadService
        ) {
        }

        $onInit(): void {
            this.authService.setTokenCookie();
            $('#newLogo').attr('src', this.appConfig.request.Download.Logo(this.cid));
            $('#newBackground').attr('src', this.appConfig.request.Download.Background(this.cid, false));
            $('#newBanner').attr('src', this.appConfig.request.Download.Banner(this.cid, false));

            this.getData();
            this.uploadService.setSuccessCallback(this.generateCallback());
        }
        // ========================================
        // PUBLIC
        // ========================================

        textOptions: kendo.ui.EditorOptions = {
            tools: [
                { name: "bold" }, { name: "italic" }, { name: "underline" }, {name: "strikethrough"},
                { name: "justifyLeft" }, { name: "justifyCenter" }, { name: "justifyRight" }, {name: "justifyFull"},
                { name: "insertUnorderedList" }, { name: "insertOrderedList" }, { name: "indent" }, {name: "outdent"},
                { name: "createLink" }, { name: "createLink" }, 
                { name: "insertImage" }, 
                { name: "createTable" }, { name: "addRowAbove" }, { name: "addRowBelow" }, {name: "addColumnLeft"}, {name: "addColumnRight"}, {name: "deleteRow"}, {name: "deleteColumn"},
                { name: "viewHtml" }, { name: "cleanFormatting" }, { name: "fontSize" }, 
                { name: "fontName" }, { name: "formatting" }, { name: "foreColor" }, { name: "backColor" }, 
            ]
        }


        private selectedCss: string;
        changeCss(cssFile: string) {
            this.selectedCss = cssFile;
            var oldLink = $('head link[href^="/Content/styles/"');
            oldLink.attr('href', '/Content/styles/' + cssFile + '.css');

        }


        getData() {
            this.appConfig.request.CompanyManagement.GetBranding(this.$http, null).then(okResponse => {
                this.data = okResponse.data;
                this.uploadService.setFolderById(okResponse.data.BrandingFolderId).then(f => {
                    this.uploadService.setFolderName("BRANDING");
                });
                
            }, errResponse => {
                this.helperService.error(errResponse);
                this.data = {
                    Header: '',
                    Text: '',
                };
            });
        };

        submit() {
            var css = this.selectedCss ? this.selectedCss : this.data.ColorTheme;
            this.appConfig.request.CompanyManagement.SetBranding(this.$http, this.data.Header, this.data.Text, css)
                .then(okResponse => {
                    this.okMessage = true;
                    this.authService.init(true, true);
                }, errResponse => {
                    this.errMessage = true;
                    this.authService.init(true, true);

                });
        }

        setFileName (name:string) {
            this.uploadService.setFileName(name);
            if (name === 'logo') this.logoIsLoading = true;
            if (name === 'background') this.backgroundIsLoading = true;
            if (name === 'banner') this.bannerIsLoading = true;
        }

        private generateCallback() {
            const self = this;
            return (file: IAppFlowFile) => {
                var prefix = file.name.split('.')[0];
                this.authService.setTokenCookie();
                if (prefix === 'logo') {
                    $('#newLogo').attr('src', this.appConfig.request.Download.Logo(this.cid));
                    $('.brand-logo img').attr('src', this.appConfig.request.Download.Logo(this.cid));
                    self.logoIsLoading = false; //ToDo: Invalid use of this!
                }
                if (prefix === 'background') {
                    $('#newBackground').attr('src', this.appConfig.request.Download.Background(this.cid, false));
                    $('.brand-background').css('background-image', this.appConfig.request.Download.Background(this.cid, true));
                    self.backgroundIsLoading = false;
                }
                if (prefix === 'banner') {
                    $('#newBanner').attr('src', this.appConfig.request.Download.Banner(this.cid, false));
                    $('.brand-banner').css('background-image', this.appConfig.request.Download.Banner(this.cid, true));
                    self.bannerIsLoading = false;
                }

            }
        }

        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppBrandingComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<'
        };

        controller = AppBrandingController;
        templateUrl = "/AdminTemplate/BrandingTemplate";
    }

    angular.module("app").component("appBranding", new AppBrandingComponent());
}


