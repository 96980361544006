module App {
    export class AppLanguageCompareController implements ng.IComponentController {
        //binding

        //compute
        languages: string[] = ['en', 'de', 'it'];
        diff: any[];
        counter: number = 0;
        selected: any;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', '$q', '$scope', '$timeout', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService'];
        constructor(
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void { }
        // ========================================
        // PUBLIC
        // ========================================
        slaveLanguages():string {
            var parts:string[] = [];
            for (var i = 1; i < this.languages.length; i++) {
                parts.push(this.languages[i]);
            }
            return parts.join();
        }


        compareFile(name: string) {
            this.selected = name;
            var promises: ng.IHttpPromise<any>[] = [];
            angular.forEach(this.languages, l => {
                promises.push(this.$http.get("/Resources/" + name + "-" + l + ".json"));
            });
            this.$q.all(promises).then(okResults => {
                var content: any[] = [];
                angular.forEach(okResults, i => {
                    content.push(i.data);
                });
                this.compareObject(content);
            }, errors => {
                console.log(errors);
                this.helperService.alert("Error loading translation files. Please Check console output.", false);
            });
        }

        // ========================================
        // PRIVATE
        // ========================================
        private compareObject(data:any) {
            this.diff = [];
            this.counter = 0;

            var master = data[0];
            var slaves: any[] = [];
            var slaveNames: string[] = [];
            for (var i = 1; i < data.length; i++) {
                slaves.push(data[i]);
                slaveNames.push(this.languages[i]);
            }

            this.compareRecursive("", master, slaves, slaveNames);

        }

        private compareRecursive(namespace:string, master:any, slaves: any[], slaveNames: string[]) {
                //console.log("entering namespace " + namespace);
                for (var key in master) {

                    // Compare Subobjects Recursively
                    if (angular.isObject(master[key])) {
                        var newSlaves: any[] = [];
                        var newSlaveNames: string[] = [];

                        for (var slaveId = 0; slaveId < slaves.length; slaveId++) {
                            if (!slaves[slaveId][key]) {
                                this.diff.push({ namespace: namespace + "." + key, lang: slaveNames[slaveId], error: "object is missing" });
                            } else if (!angular.isObject(slaves[slaveId][key])) {
                                this.diff.push({ namespace: namespace + "." + key, lang: slaveNames[slaveId], error: "is not an object" });

                            } else {
                                newSlaves.push(slaves[slaveId][key]);
                                newSlaveNames.push(slaveNames[slaveId]);
                            }

                        }

                        this.compareRecursive(namespace + "." + key, master[key], newSlaves, newSlaveNames);


                    } else if (angular.isString(master[key])) {
                        //console.log("KEY:", key);
                        this.counter++;
                        for (var s = 0; s < slaves.length; s++) {
                            if (!slaves[s][key]) {
                                this.diff.push({ namespace: namespace + "." + key, lang: slaveNames[s], error: "string is missing" });
                            } else if (!angular.isString(slaves[s][key])) {
                                this.diff.push({ namespace: namespace + "." + key, lang: slaveNames[s], error: "is not a string" });
                            }
                        }
                    } else {
                        this.diff.push({ namespace: namespace + "." + key, lang: this.languages[0], error: "is either not an object or string" });
                    }
                }
            }


    }


    export class AppLanguageCompareComponent implements ng.IComponentOptions {
        controller = AppLanguageCompareController;
        templateUrl="/AdminTemplate/LanguageCompareTemplate";
    }

    angular.module("app").component("appLanguageCompare", new AppLanguageCompareComponent());
}
