module App {
    export class AppAccesslogController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        fileAccessType: ITextValue[];
        folderAccessType: ITextValue[];


        //compute
        gridObject: kendo.ui.Grid;
        gridOptions: kendo.ui.GridOptions;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['appConfig', 'authService', 'localeService'];
        constructor(
            private appConfig: AppConfig,
            private authService: AuthService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void {
            
        }
        // ========================================
        // CALLBACK
        // ========================================
        onOwnershipSelect(i: IOwnerShipViewModel) {
            if (i) this.gridOptions = this.getGridOptions(i.Key);
        }

        // ========================================
        // PRIVATE
        // ========================================
        private getGridOptions(baseId: number): kendo.ui.GridOptions {
            var that = this;
            return {
                // 1. Datasource
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.Eventlog.AccessUrl(baseId), this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 20,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "sort": { "field": "DateTime", "dir": "desc" },
                    "filter": [],
                    "error": (e: any)=> {
                        that.authService.kendoError(e, null);
                    },

                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "number" },
                                "DateTime": { "type": "date" },
                                "FileAccessType": { "type": "number" },
                                "FolderAccessType": { "type": "number" },
                                "User": { "type": "string" },
                                "Details": { "type": "string" },
                                "IpAddress": { "type": "string" },
                                "IsFile": { "type": "boolean" },
                                "FileOrFolderId": { "type": "number" },
                                "FileOrFolderName": { "type": "string" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    }

                },

                // 2. Columns
                "columns": [
                    {
                        "field": "DateTime",
                        "title": "{{'Datarooms.Accesslog.DateTime' | translate}}",
                        "format": "{0:g}",
                        "filterable": true
                    }, {
                        "field": "FileOrFolderName",
                        "title": "{{'Datarooms.Accesslog.Title' | translate}}",
                    }, {
                        "field": "IpAddress",
                        "title": "{{'Datarooms.Accesslog.IpAddress' | translate}}",
                        "hidden": true,
                    }, {
                        "field": "FolderAccessType",
                        "title": "{{'Datarooms.Accesslog.FolderAccessType' | translate}}",
                        "filterable": true,
                        "values": this.folderAccessType,
                    }, {
                        "field": "FileAccessType",
                        "title": "{{'Datarooms.Accesslog.FileAccessType' | translate}}",
                        "filterable": true,
                        "values": this.fileAccessType,
                    }, {
                        "field": "User",
                        "title": "{{'Datarooms.Accesslog.User' | translate}}",
                        "filterable": true
                    }, {
                        "field": "Details",
                        "title": "{{'Datarooms.Accesslog.Details' | translate}}",
                        "filterable": true,
                        "hidden": true,
                    }
                ],

                // 3. Features
                "pageable": true,
                "sortable": true,
                "scrollable": false,
                "filterable": {
                    operators:this.localeService.getKendoFilterTranslationObject()
                },

            }
        };
    }


    export class AppAccesslogComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            fileAccessType: '<',
            folderAccessType: '<',
        };

        controller = AppAccesslogController;
        templateUrl = "/UserTemplate/AccesslogTemplate";
    }

    angular.module("app").component("appAccesslog", new AppAccesslogComponent());
}


