module App {
    export class LeftMenuController implements ng.IComponentController {
        //binding
        //currentUser: IApplicationUserClientStatusViewModel;

        //compute
        date: Date;
        panel: kendo.ui.ResponsivePanel;
        currentUser: IApplicationUserClientStatusViewModel;
        brandingData: any;


        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$timeout', '$http', 'appConfig', 'authService', 'conversationService', 'coreDataService', 'localeService', 'helperService', 'fullscreenService', 'conflictService'];
        constructor(
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private conversationService: ConversationService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService,
            private fullscreenService: FullscreenService,
            private conflictService: ConflictService
        ) {
        }

        $onInit(): void { 
            this.getDate(); 
            this.authService.init(false, false).then(cu => {
                this.currentUser = cu;
                this.brandingData = this.authService.brandingData;
            });

        }
        // ========================================
        // PUBLIC
        // ========================================
        logout() {
            this.authService.logout();
        }


        // ========================================
        // PRIVATE
        // ========================================
        private getDate() {
            this.$timeout(() => {
                this.date = new Date();
                this.getDate();
            }, 1000);
        }

    }


    export class LeftMenuComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            //currentUser: '<'
        };

        controller = LeftMenuController;
        templateUrl='/GuestTemplate/LeftMenuTemplate';
    }

    angular.module("app").component("leftMenu", new LeftMenuComponent());
}

