module App {
    interface IAppTenantTrans {
        'Manager.Tenant.ExcelPrefix': string;
    }

    export class AppTenantController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        trans: IAppTenantTrans;
        language: ITextValue[];

        //compute
        gridObject: kendo.ui.Grid;
        tenantGridOptions: kendo.ui.GridOptions;

        private exportFlag = false;
        private dataItem: ITenantViewModel;
        private currentHighlight: any;
        private currentHighlightIsAlt = false;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            this.tenantGridOptions = this.getGridOptions();
        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        private highlightRow () {

            // remove existing old highlight
            if (this.currentHighlight) {
                this.currentHighlight.removeClass("bg-primary").removeClass("strong");
                if (this.currentHighlightIsAlt) this.currentHighlight.addClass("k-alt");
                this.currentHighlight = null;
            }

            if (this.dataItem) {
                var row = this.helperService.searchRowInGrid(this.gridObject, this.dataItem);
                if (row == null) return;
                this.currentHighlight = row;
                if (row.attr('class').indexOf('k-alt') > -1) {
                    this.currentHighlightIsAlt = true;
                    row.removeClass('k-alt');
                } else {
                    this.currentHighlightIsAlt = false;
                }
                row.addClass("bg-primary").addClass("strong");

            }
        }

        private onSelectItem(e:any) {
            this.dataItem = e.model;
            this.highlightRow();
        };


        getGridOptions(): kendo.ui.GridOptions {
            var that = this;
            return {
                // 1. DATASOURCE
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.TenantManagement.TenantUrl, this.authService.getBearerTokenObject()),
                        "update": this.appConfig.helper.http('put', this.appConfig.request.TenantManagement.TenantUrl, this.authService.getBearerTokenObject()),
                        "create": this.appConfig.helper.http('post', this.appConfig.request.TenantManagement.TenantUrl, this.authService.getBearerTokenObject()),
                        "destroy": this.appConfig.helper.http('delete', this.appConfig.request.TenantManagement.TenantUrl, this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 50,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "serverGrouping": true,
                    "serverAggregates": true,
                    "sort": [{ "field": "Name", "dir": "asc" }],
                    "filter": [],
                    "error": function(e:any) {
                        that.authService.kendoError(e, that.gridObject);
                    },
                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "string" },
                                "Name": { "type": "string" },
                                "IsEnabled": { "type": "boolean" },
                                "AdminUser": { "type": "string" },
                                "AdminEmail": { "type": "string" },
                                "IsAuthenticatorEnabled": { "type": "boolean" },
                                "LastLogon": { "type": "date" },
                                "LoginCount": { "type": "number" },
                                "Language": { "type": "number", "field": "Language" }
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    }

                },

                // 2. COLUMNS
                "columns": [
                    {
                        "field": "Name",
                        "title": "{{'Manager.Tenant.Name' | translate}}",
                    }, {
                        "field": "IsEnabled",
                        "title": "{{'Manager.Tenant.IsEnabled' | translate}}",
                        "template": '<input type="checkbox" id="IsEnabled" #= IsEnabled ? checked="checked" : "" # disabled="disabled" />',
                        //"editor": '<input class="check-box" data-val="true" data-val-required="The Aktiv field is required." id="IsEnabled" name="IsEnabled" type="checkbox" value="true" /><input name="IsEnabled" type="hidden" value="false" /><span class="field-validation-valid" data-valmsg-for="IsEnabled" data-valmsg-replace="true"></span>'
                    }, {
                        "field": "AdminUser",
                        "title": "{{'Manager.Tenant.AdminUser' | translate}}",
                    }, {
                        "field": "AdminEmail",
                        "title": "{{'Manager.Tenant.AdminEmail' | translate}}",
                    }, {
                        "field": "Language",
                        "title": "{{'Manager.Tenant.Language' | translate}}",
                        "values": this.language,
                    }, {
                        "field": "LastLogon",
                        "format": "{0:g}",
                        "hidden": true,
                    }, {
                        "field": "LoginCount",
                        "hidden": true,
                    }, {
                        "width": 200,
                        "command": [
                            { "name": "edit" },
                            { "name": "destroy" },
                        ]
                    }
                ],

                // 3. GRID FEATURES
                "toolbar": [
                    { "name": 'create', "buttonType": "ImageAndText", "text": "{{'Manager.Tenant.NewTenant' | translate}}" },
                    { "name": "excel", "buttonType": "ImageAndText", "text": "{{'Manager.Tenant.ExcelExport' | translate}}" },
                ],
                "sortable": true,
                "scrollable": false,
                "editable": {
                    "mode": "inline",
                    "update": true,
                    "destroy": true
                },
                "excel": { allPages: true, fileName: this.trans["Manager.Tenant.ExcelPrefix"] + ".xlsx", filterable: true },

                // 4. EVENTS
                "dataBound": (e:any)=> {
                    if (this.dataItem) this.highlightRow();
                },
                "save": (e:any)=> {
                    this.onSelectItem(e);
                },
                "excelExport": (e:any)=> {
                    if (!this.exportFlag) {
                        e.sender.showColumn("LastLogon");
                        e.sender.showColumn("LoginCount");
                        e.preventDefault();
                        this.exportFlag = true;
                        setTimeout(() => {
                            e.sender.saveAsExcel();
                        });
                    } else {
                        e.sender.hideColumn("LastLogon");
                        e.sender.hideColumn("LoginCount");
                        this.exportFlag = false;
                    }
                }


            }
        };
    }


    export class AppTenantComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            trans: '<',
            language: '<'
        };

        controller = AppTenantController;
        templateUrl ='/AdminTemplate/TenantTemplate';
    }

    angular.module("app").component("appTenant", new AppTenantComponent());
}

