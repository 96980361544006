module App {
    export class RightMenuController implements ng.IComponentController {
        //binding

        //compute
        domain = this.appConfig.helper.companyId();
        currentUser: IApplicationUserClientStatusViewModel;
        brandingData: any;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['appConfig', 'authService'];
        constructor(
            private appConfig: AppConfig,
            private authService: AuthService,
        ) {
        }

        $onInit(): void {
            this.authService.init(false, false).then(cu => {
                this.currentUser = cu;
                this.brandingData = this.authService.brandingData;
            });

        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class RightMenuComponent implements ng.IComponentOptions {
        controller = RightMenuController;
        templateUrl='/GuestTemplate/RightMenuTemplate';
    }

    angular.module("app").component("rightMenu", new RightMenuComponent());
}
