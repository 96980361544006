module App {
    export class FileActionController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        file: IFileViewModel;
        permissions: ICurrentPermissionViewModel;
        refresh: ()=>void;

        //compute
        downloadUrl = "";
        timewarpEnabled:boolean;
        view = {
            showDelete: false, //Used for CUT/DELTE Buttons
            showDownload: false, //Used for COPY/ZIP Button
            showUpload: false, //Used for PASTE Button
            showInformation: true,
            copyConfirm: false,
            cutConfirm: false,
            showCheckOut: false,
            showCheckIn: false,
        }
        copyConfirm: boolean;
        cutConfirm: boolean;
        checkOutConfirm: boolean;
        checkInConfirm: boolean;
        checkUndoConfirm: boolean;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$window', '$state', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService', 'filesystemService'];
        constructor(
            private $window: ng.IWindowService,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService,
            private filesystemService: FilesystemService
        ) {
        } 

        $onInit(): void {
            this.timewarpEnabled = this.filesystemService.getTimewarp() !== null;
        }

        $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj['file'] && onChangesObj['file'].currentValue) {
                this.timewarpEnabled = this.filesystemService.getTimewarp() !== null;
                this.downloadUrl = this.filesystemService.fileUrl(this.file.Id, null, null);
                if (this.permissions) this.setViewOptions();
                this.cutConfirm = false;
                this.copyConfirm = false;
                this.checkOutConfirm = false;
                this.checkInConfirm = false;
                this.checkUndoConfirm = false;
            }

            if (onChangesObj['permissions'] && onChangesObj['permissions'].currentValue) {
                this.setViewOptions();
            }

        }


        // ========================================
        // PUBLIC
        // ========================================
        close (duration:number = 300) {
            this.$timeout(() => { this.file = null; }, duration);
        }


        setViewOptions() {
            const p = this.permissions;
            this.view.showUpload = !this.timewarpEnabled && (this.currentUser.isAdmin || p.CanUpload);
            this.view.showDelete = !this.timewarpEnabled && (this.currentUser.isAdmin || p.CanDelete);
            this.view.showDownload = this.currentUser.isAdmin || p.CanDownload;

            if (p.CheckInRequired) {
                // This is a CheckIn Folder
                if (this.file && this.file.CheckOutDate) {
                    // file is checked out
                    if (this.file.CheckOutUserId !== this.currentUser.UserId) {
                        // ... by another user
                        this.view.showUpload = false;
                        this.view.showDelete = false;
                        this.view.showCheckIn = false;
                    } else {
                        // ... by myself
                        this.view.showDelete = false;
                        this.view.showCheckIn = true;
                    }
                    this.view.showCheckOut = false;
                } else {
                    // file is not checked out
                    this.view.showCheckOut = p.CanUpload;
                    this.view.showCheckIn = false;
                }


            } else {
                this.view.showCheckIn = false;
                this.view.showCheckOut = false;
            }

        }

        download() {
            if (!this.view.showDownload) return;
            this.authService.setTokenCookie();
            this.$window.location.href = this.downloadUrl;
            this.close();
        }

        info() {
            if (!this.view.showInformation) return;
            this.$state.go('fileInformation', { fileId: this.file.Id });
        }


        removeFile () {
            if (!this.view.showDelete) return;
            this.filesystemService.removeFile(this.file.Id, ()=> {
                this.refresh();
                this.file = null;
            });
        }

        renameFile() {
            if (!this.view.showDelete) return;
            this.filesystemService.renameFile(this.file.Id, this.file.Name, (data:string)=> {
                this.file.Name = data;
                this.file = null;
            });
        }

        copyToClipboard () {
            if (!this.view.showDownload) return;
            this.copyConfirm = true;
            this.filesystemService.copyFileToClipboard(this.file.Id, this.file.Name);
            this.close();

            if (this.timewarpEnabled) {
                this.helperService.alert("Datarooms.FileAction.RestoreDescription", true, { name: this.file.Name });
            }

        }

        cutToClipboard() {
            if (!this.view.showDelete) return;
            this.cutConfirm = true;
            this.filesystemService.cutFileToClipboard(this.file.Id, this.file.Name);
            this.close();
        }

        checkOut() {
            if (!this.view.showCheckOut) return;
            this.helperService.confirm("Datarooms.FileAction.CheckOut", "Datarooms.FileAction.CheckOutDescription", () => {
                this.appConfig.request.File.CheckOut(this.$http, this.file.Id).then(ok => {
                    this.checkOutConfirm = true;
                    this.file.CheckOutDate = ok.data.CheckOutDate;
                    this.file.CheckOutUserId = ok.data.CheckOutUserId;
                    this.file.CheckOutUserName = ok.data.CheckOutUserName;
                    this.close();
                }, err => this.helperService.error(err));
            }, null, true);
        }
        checkIn() {
            if (!this.view.showCheckIn) return;
            this.helperService.confirm("Datarooms.FileAction.CheckIn", "Datarooms.FileAction.CheckInDescription", () => {
                this.appConfig.request.File.CheckIn(this.$http, this.file.Id).then(ok => {
                    this.checkInConfirm = true;
                    this.file.CheckOutDate = ok.data.CheckOutDate;
                    this.file.CheckOutUserId = ok.data.CheckOutUserId;
                    this.file.CheckOutUserName = ok.data.CheckOutUserName;
                    this.close();
                }, err => this.helperService.error(err));
            }, null, true);
        }
        checkUndo() {
            if (!this.view.showCheckIn) return;
            this.helperService.confirm("Datarooms.FileAction.CheckUndo", "Datarooms.FileAction.CheckUndoDescription", () => {
                this.appConfig.request.File.CheckUndo(this.$http, this.file.Id).then(ok => {
                    this.checkUndoConfirm = true;
                    this.file.CheckOutDate = ok.data.CheckOutDate;
                    this.file.CheckOutUserId = ok.data.CheckOutUserId;
                    this.file.CheckOutUserName = ok.data.CheckOutUserName;
                    this.close();
                }, err => this.helperService.error(err));
            }, null, true);
        }
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class FileActionComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            file: '<',
            permissions: '<',
            refresh: '&',
            currentUser: '<',
        };

        controller = FileActionController;
        //templateUrl='';
        template = 
            '<div ng-show="$ctrl.file" class="fileAction">' +
                '<h3 class="header"><span translate>Datarooms.FileAction.Title</span> &quot;{{$ctrl.file.Name}}&quot; </h3>' +
                '<h3 class="close"><span class="glyphicon glyphicon-remove-circle pull-right clickable" ng-click="$ctrl.file=null"></span></h3>' +
                //'<h3 class="pull-right clickable"><span class="glyphicon glyphicon-remove-circle" ng-click="file=null"></span></h3>' +
                //'<h3>Aktionen für {{file.Name}}</h3>' +
                '<p ng-show="!$ctrl.currentUser.isFullLicense" translate>Datarooms.FileAction.LimitationLightLicense</p>' +
                '<div class="row" ng-hide="$ctrl.timewarpEnabled">' +
                    '<div class="col-xs-6">' +
                        '<a ng-disabled="!$ctrl.view.showDownload" class="btn btn-primary btn-block btn-sm " ng-click="$ctrl.download()" ><span class="glyphicon glyphicon-cloud-download"></span> <span translate>Datarooms.FileAction.Download</span></a>' +
                        '<a ng-disabled="!$ctrl.view.showDelete" class="btn btn-primary btn-block btn-sm " ng-click="$ctrl.removeFile()"><span class="glyphicon glyphicon-trash"></span> <span translate>Datarooms.FileAction.Delete</span></a>' +
                        '<a ng-show="$ctrl.currentUser.isFullLicense" class="btn btn-default btn-block btn-sm " ng-click="$ctrl.cutToClipboard()" ng-disabled="!$ctrl.view.showDelete"><span class="glyphicon glyphicon-scissors"></span> <span translate>Datarooms.FileAction.Cut</span><span class="glyphicon glyphicon-ok pull-right" ng-show="$ctrl.cutConfirm"></span></a>' +
                        '<a ng-show="$ctrl.currentUser.isFullLicense" class="btn btn-default btn-block btn-sm " ng-click="$ctrl.copyToClipboard()"><span class="glyphicon glyphicon-copy" ng-disabled="!$ctrl.view.showDownload"></span> <span translate>Datarooms.FileAction.Copy</span><span class="glyphicon glyphicon-ok pull-right" ng-show="$ctrl.copyConfirm"></span></a>' +
                        '<a ng-show="$ctrl.currentUser.isFullLicense" ng-disabled="!$ctrl.view.showDelete" class="btn btn-default btn-block btn-sm " ng-click="$ctrl.renameFile()"><span class="glyphicon glyphicon-pencil"></span> <span translate>Datarooms.FileAction.Rename</span></a>' +
                    '</div>' +
                    '<div class="col-xs-6">' +
                        '<a ng-show="$ctrl.currentUser.isFullLicense" ng-disabled="!$ctrl.view.showInformation" class="btn btn-default btn-block btn-sm " ng-click="$ctrl.info()"><span class="glyphicon glyphicon-info-sign"></span> <span translate>Datarooms.FileAction.Information</span></a>' +
                        '<a ng-show="$ctrl.view.showCheckOut" class="btn btn-default btn-block btn-sm " ng-click="$ctrl.checkOut()"><span class="glyphicon glyphicon-floppy-save"></span> <span translate>Datarooms.FileAction.CheckOut</span><span class="glyphicon glyphicon-ok pull-right" ng-show="$ctrl.checkOutConfirm"></span></a>' +
                        '<a ng-show="$ctrl.view.showCheckIn" class="btn btn-success btn-block btn-sm " ng-click="$ctrl.checkIn()"><span class="glyphicon glyphicon-floppy-open"></span> <span translate>Datarooms.FileAction.CheckIn</span><span class="glyphicon glyphicon-ok pull-right" ng-show="$ctrl.checkInConfirm"></span></a>' +
                        '<a ng-show="$ctrl.view.showCheckIn" class="btn btn-warning btn-block btn-sm " ng-click="$ctrl.checkUndo()"><span class="glyphicon glyphicon-floppy-remove"></span> <span translate>Datarooms.FileAction.CheckUndo</span><span class="glyphicon glyphicon-ok pull-right" ng-show="$ctrl.checkUndoConfirm"></span></a>' +
                    '</div>' +
                '</div>' +
                '<div class="row" ng-show="$ctrl.timewarpEnabled">' +
                    '<div class="col-xs-6">' +
                        '<a ng-show="$ctrl.currentUser.isFullLicense" class="btn btn-default btn-block btn-sm " ng-click="$ctrl.copyToClipboard()"><span class="glyphicon glyphicon-time" ></span> <span translate>Datarooms.FileAction.Restore</span><span class="glyphicon glyphicon-ok pull-right" ng-show="$ctrl.copyConfirm"></span></a>' +
                    '</div>' +
                    '<div class="col-xs-6">' +
                    '</div>' +
                '</div>' +
            '</div>' +
            '<div ng-class="{background: $ctrl.file}" ng-click="$ctrl.close(0)" ></div>' ;
    }

    angular.module("app").component("fileAction", new FileActionComponent());
}

