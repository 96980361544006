module App {
    export class ConversationService {
        static Factory($http: ng.IHttpService,
            $interval: ng.IIntervalService,
            appConfig: AppConfig,
            authService: AuthService) {
            return new ConversationService($http, $interval, appConfig, authService);
        }

        constructor(
            private $http: ng.IHttpService,
            private $interval: ng.IIntervalService,
            private appConfig: AppConfig,
            private authService: AuthService
        ) {
        }


        newCount = 0;
        conversations: IConversationViewModel[] = [];

        init(): void {
            this.$interval(() => {
                this.loadConversations();
            }, 60 * 1000);
        }

        loadConversations():void {
            if (this.authService.data.isAuthenticated) {
                this.appConfig.request.Message.GetConversations(this.$http).then((ok) => {
                    this.conversations = ok.data;
                    let newCount = 0;
                    ok.data.forEach(i => newCount += i.CountNew);
                    this.newCount = newCount;
                });
            }
        }



    }

    angular.module('app').factory('conversationService', ['$http', '$interval', 'appConfig', 'authService', ConversationService.Factory]);
}

