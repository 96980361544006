module App {
    export class AppConfig {
        request = {
            CoreData: {
                Enums($http: ng.IHttpService): ng.IHttpPromise<IEnumViewModel> {
                    return $http.get('api/coredata/enums');
                }
            },

            // All Actions for currently logged in users
            Login: {
                ChangePassword($http: ng.IHttpService, oldPassword: string, newPassword: string, confirmPassword: string): ng.IHttpPromise<any> {
                    return $http.post("api/login/changepassword", { OldPass: oldPassword, NewPass1: newPassword, NewPass2: confirmPassword });
                },
                GetCurrentUser($http:ng.IHttpService): ng.IHttpPromise<IApplicationUserServerStatusViewModel> {
                    return $http.get("api/login/currentuser");
                },

                ChallengeAuthenticator($http:ng.IHttpService): ng.IHttpPromise<IAuthenticatorViewModel> {
                    return $http.get("api/login/twofactor/authenticator");
                },
                EnableAuthenticator($http:ng.IHttpService, secretKey:string, code:string): ng.IHttpPromise<boolean> {
                    return $http.post("api/login/twofactor/authenticator", { SecretKey: secretKey, Code: code });
                },

                GetUnusedTokens($http:ng.IHttpService): ng.IHttpPromise<ITextValue[]> {
                    return $http.get("api/login/twofactor/token");
                },
                EnableToken($http:ng.IHttpService, serial: string, code: string): ng.IHttpPromise<boolean> {
                    return $http.post("api/login/twofactor/token", { SecretKey: serial, Code: code });
                },

                DisableTwoFactor($http:ng.IHttpService, code:string): ng.IHttpPromise<boolean> {
                    return $http.delete("api/login/twofactor?code=" + encodeURIComponent(code));
                },
                VerifyTwoFactor($http:ng.IHttpService, code:string): ng.IHttpPromise<boolean> {
                    return $http.post("api/login/twofactor?code=" + encodeURIComponent(code), null);
                },
                TokenLogin($http:ng.IHttpService, companyId:string, username:string, password:string): ng.IHttpPromise<IOauthToken> {
                    var data = "grant_type=password&username="+companyId + "#" + username + "&password=" + password;
                    return $http.post("/token", data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
                },
                Logout($http: ng.IHttpService) {
                    return $http.post("api/login/logout", {});
                }


            },

            // Actions for Role UserManager
            UserManagement: {
                UsersUrl: "api/usermanagement/users",
                GroupsUrl: "api/usermanagement/groups",
                List($http:ng.IHttpService): ng.IHttpPromise<IApplicationUserViewModel[]> {
                    return $http.get("api/usermanagement/users/list");
                },
                GetGroupsOfUser($http:ng.IHttpService, userId:string): ng.IHttpPromise<IIdAndName[]> {
                    return $http.get("api/usermanagement/membership/user/" + userId);
                },
                GetUsersOfGroup($http: ng.IHttpService, groupId: string): ng.IHttpPromise<IIdAndName[]> {
                    return $http.get("api/usermanagement/membership/group/" + groupId);
                },
                AddMembership($http:ng.IHttpService, userId:string, groupId:string): ng.IHttpPromise<boolean> {
                    return $http.post("api/usermanagement/membership/" + userId + "/" + groupId, null);
                },
                RemoveMembership($http: ng.IHttpService, userId: string, groupId: string): ng.IHttpPromise<boolean> {
                    return $http.delete("api/usermanagement/membership/" + userId + "/" + groupId);
                },

                ResetPassword($http: ng.IHttpService, id: string, newPass1: string, newpass2: string): ng.IHttpPromise<boolean> {
                    return $http.put("api/usermanagement/resetpassword", { Id: id, NewPass1: newPass1, NewPass2: newpass2 });
                },
                ResetTwoFactor($http: ng.IHttpService, userId:string): ng.IHttpPromise<boolean> {
                    return $http.put("api/usermanagement/resettwofactor/" + userId, null);
                },
                ChangeTwoFactor($http: ng.IHttpService, userId:string, authType: AuthenticatorType): ng.IHttpPromise<boolean> {
                    return $http.put("api/usermanagement/changetwofactor/" + userId + "/" + authType, null);
                },

            },

            // Actions for Role CompanyManager
            CompanyManagement: {
                SetMemoryLimit($http: ng.IHttpService, limit: string): ng.IHttpPromise<boolean> {
                    return $http.put("api/companymanagement/memory?limit=" + limit, null);
                },
                SetRetentionTime($http: ng.IHttpService, months:number): ng.IHttpPromise<boolean> {
                    return $http.put("api/companymanagement/retention?limit=" + months, null);
                },
                GetSettings($http:ng.IHttpService): ng.IHttpPromise<ITenantDetailViewModel> {
                    return $http.get("api/companymanagement/settings");
                },
                SetBranding($http:ng.IHttpService, brandingHeader:string, brandingText:string, colorTheme:string) {
                    return $http.put("api/companymanagement/branding", { Header: brandingHeader, Text: brandingText, ColorTheme: colorTheme });
                },
                GetBranding($http:ng.IHttpService, companyId:string): ng.IHttpPromise<IBrandingViewModel> {
                    return $http.get("api/companymanagement/branding?companyId=" + companyId);
                },
                GetTokensUrl: "api/companymanagement/tokens",

            },

            // Actions for TenantManager
            TenantManagement: {
                // tenant management
                TenantUrl: "api/tenantmanagement/tenants",
                Details($http: ng.IHttpService, tenantId: number): ng.IHttpPromise<ITenantDetailViewModel> {
                    return $http.get("api/tenantmanagement/tenants/" + tenantId);
                },
                GetTenantList($http:ng.IHttpService): ng.IHttpPromise<IIdAndName[]> {
                    return $http.get("api/tenantmanagement/tenants/list");
                },
                SetMemoryLimit($http: ng.IHttpService, tenantId: number, limit: string): ng.IHttpPromise<boolean> {
                    // limit is a string because of special treatment of decimal char
                    return $http.put("api/tenantmanagement/tenants/" + tenantId + "/memory?limit=" + limit, null);
                },
                SetLicenseLimit($http:ng.IHttpService, tenantId:number, fullLicenseLimit:string, lightLicenseLimit:string): ng.IHttpPromise<boolean> {
                    return $http.put("api/tenantmanagement/tenants/" + tenantId + "/license?fullLicenseLimit=" + fullLicenseLimit + "&lightLicenseLimit=" + lightLicenseLimit, null);
                },

                // tenant support
                RegeneratePassword($http: ng.IHttpService, tenantId:number, check1:string, check2:string, firstMail:boolean): ng.IHttpPromise<boolean> {
                    return $http.put("api/tenantmanagement/support/" + tenantId + "/regeneratepassword?check1=" + check1 + "&check2=" + check2 + "&isActivate=" + firstMail, null);
                },
                DisableTwoFactor($http: ng.IHttpService, tenantId: number): ng.IHttpPromise<boolean> {
                    return $http.put("api/tenantmanagement/support/" + tenantId + "/resettwofactor", null);
                },
                EnableAdminUser($http: ng.IHttpService, tenantId: number): ng.IHttpPromise<boolean> {
                    return $http.put("api/tenantmanagement/support/" + tenantId + "/enableadmin", null);
                },

                // token management
                TokenUrl: "api/tenantmanagement/tokens",

                // db maintenance
                MissingInStorageUrl(tenantId: number) {
                     return "api/tenantmanagement/" + tenantId + "/database/missinginstorage";
                },
                MissingInDatabaseUrl(tenantId: number) {
                     return "api/tenantmanagement/" + tenantId + "/database/missingindatabase";
                },
                FixDatabase($http: ng.IHttpService, tenantId: number, blobId: string): ng.IHttpPromise<boolean> {
                    return $http.delete("api/tenantmanagement/" + tenantId + "/database/fixdatabase/" + blobId);
                },
                FixStorage($http: ng.IHttpService, tenantId: number, name: string): ng.IHttpPromise<boolean> {
                    return $http.delete("api/tenantmanagement/"+tenantId+"/database/fixstorage?name=" + encodeURIComponent(name));
                },

                Cleanup($http:ng.IHttpService, tenantId:number): ng.IHttpPromise<ICleanupViewModel> {
                    return $http.delete("api/tenantmanagement/database/cleanup/" + tenantId + "/true");
                },
                ThumbStats($http:ng.IHttpService): ng.IHttpPromise<IThumbStatsViewModel[]> {
                    return $http.get("api/tenantmanagement/thumbs");
                },
                CreateThumb($http:ng.IHttpService, tenantId:number): ng.IHttpPromise<number> {
                    return $http.post("api/tenantmanagement/thumbs/" + tenantId, null);
                },

            },
            Restore: {
                TrashUrl(baseFolderId: number) {
                    return "/api/restore/trash/" + (baseFolderId || "root");
                },

                RestoreFolderFromTrash($http: ng.IHttpService, baseFolderId:number|null, folderId: number): ng.IHttpPromise<boolean> {
                    return $http.put("api/restore/trash/folder/" + folderId + "?baseFolderId=" + baseFolderId, null);
                },
                RestoreFileFromTrash($http: ng.IHttpService, fileId:number): ng.IHttpPromise<boolean> {
                    return $http.put("api/restore/trash/file/" + fileId, null);
                },


                GetFolder($http: ng.IHttpService, folderId: number, timewarp: Date): ng.IHttpPromise<IFolderControlViewModel> { 
                    return $http.get("api/restore/timewarp/folder/" + (folderId || "root") + "?timewarp=" + timewarp.toISOString());
                },
                CopyFolder($http: ng.IHttpService, folderId: number, targetFolderId: number, timewarp: Date): ng.IHttpPromise<IFolderViewModel> {
                    return $http.post("api/restore/timewarp/folder/" + folderId + "/copyto/" + targetFolderId + "?timewarp=" + timewarp.toISOString(), null);
                },

                RevertFileFromVersion($http: ng.IHttpService, fileId: number, timewarp: Date): ng.IHttpPromise<boolean> { 
                    //return $http.put("api/restore/timewarp/" + targetTime + "/file/" + fileId + "/revert");
                    return $http.put("api/restore/timewarp/file/" + fileId + "/revert?timewarp=" + timewarp.toISOString(), null);
                },
                GetFileVersions($http:ng.IHttpService, fileId:number): ng.IHttpPromise<IFileViewModel[]> {
                    return $http.get("api/restore/timewarp/file/" + fileId);
                },
                CopyFile($http: ng.IHttpService, fileId: number, targetFolderId: number, timewarp: Date): ng.IHttpPromise<boolean> { 
                    return $http.post("api/restore/timewarp/file/" + fileId + "/copyto/" + targetFolderId + "?timewarp=" + timewarp.toISOString(), null);
                },

            },

            Folder: {
                // navigation
                //GetRoot($http:ng.IHttpService): ng.IHttpPromise<IFolderViewModel> {
                //    return $http.get("api/folder/root");
                //},
                GetShares($http: ng.IHttpService): ng.IHttpPromise<IFolderViewModel[]> {
                    return $http.get("api/folder/private");
                },
                GetPublicShares($http: ng.IHttpService, companyId: string): ng.IHttpPromise<IFolderViewModel[]> {
                    return $http.get("api/folder/public?companyId=" + companyId);
                },
                GetFavorites($http:ng.IHttpService): ng.IHttpPromise<IFavoriteViewModel[]> {
                    return $http.get("api/folder/fav");
                },
                AddToFavorites($http: ng.IHttpService, folderId: number): ng.IHttpPromise<boolean> {
                    return $http.post("api/folder/fav/" + folderId, null);
                },
                RemoveFromFavorites($http: ng.IHttpService, folderId:number): ng.IHttpPromise<boolean> {
                    return $http.delete("api/folder/fav/" + folderId);
                },

                GetRelativeFolder($http:ng.IHttpService, folderId:number, relativePath:string): ng.IHttpPromise<number> {
                    return $http.post("api/folder/relative/" + folderId, "'"+relativePath+"'");
                },


                // operations
                FolderExists($http: ng.IHttpService, parentFolderId: number, folderName: string): ng.IHttpPromise<boolean> {
                    return $http.get("api/folder/" + parentFolderId + "/folderexist?folderName=" + encodeURIComponent(folderName)); 
                },
                FileExists($http:ng.IHttpService, parentFolderId:number, fileName:string) {
                    return $http.get("api/folder/" + parentFolderId + "/fileexist?fileName=" + encodeURIComponent(fileName));
                },
                Details($http:ng.IHttpService, folderId:number): ng.IHttpPromise<IFolderControlViewModel> {
                    return $http.get("api/folder/" + (folderId || "root"));
                },
                PublicDetails($http: ng.IHttpService, folderId: number, shareId: string, pin: string): ng.IHttpPromise<IFolderControlViewModel> {
                    return $http.get("api/folder/" + shareId + "/" + (folderId || "root") + "?pin=" + encodeURIComponent(pin));
                },
                Create($http: ng.IHttpService, parentFolderId: number, name: string): ng.IHttpPromise<IFolderViewModel> {
                    return $http.post("api/folder/" + parentFolderId, "'"+name+"'");
                },
                Rename($http: ng.IHttpService, folderId: number, name: string): ng.IHttpPromise<IFolderViewModel> {
                    return $http.put("api/folder/" + folderId, "'"+name+"'");
                },
                Delete($http: ng.IHttpService, folderId:number): ng.IHttpPromise<boolean> {
                    return $http.delete("api/folder/" + folderId);
                },
                Copy($http: ng.IHttpService, folderId: number, targetFolderId: number): ng.IHttpPromise<IFolderViewModel> {
                    return $http.post("api/folder/" + folderId + "/to/" + targetFolderId, null);
                },
                Move($http: ng.IHttpService, folderId: number, targetFolderId: number): ng.IHttpPromise<IFolderViewModel> {
                    return $http.put("api/folder/" + folderId + "/to/" + targetFolderId, null);
                },


                // permissions
                GetPermissions($http: ng.IHttpService, folderId: number): ng.IHttpPromise<ISharePermissionViewModel> {
                    return $http.get("api/permissions/folder/" + folderId + "/specific");
                },
                GetInheritedPermissions($http: ng.IHttpService, folderId: number): ng.IHttpPromise<ISharePermissionViewModel> {
                    return $http.get("api/permissions/folder/" + folderId + "/inherited");
                },
                SetBreakInheritance($http: ng.IHttpService, folderId: number, value: boolean): ng.IHttpPromise<{}> {
                    return $http.put("api/permissions/folder/" + folderId + "/inherited?value=" + value.toString(), null);
                },
                GetMyPermissions($http:ng.IHttpService, folderId:number): ng.IHttpPromise<ICurrentPermissionViewModel> {
                    return $http.get("api/permissions/folder/" + (folderId || "root") + "/my");
                },
                SetPermissionLevel($http:ng.IHttpService, folderId:number, level:number, pin:string): ng.IHttpPromise<number> {
                    return $http.put("api/permissions/folder/" + folderId + "?level=" + level + "&pin=" + pin, null);
                },
                SetCheckInRequired($http: ng.IHttpService, folderId: number, required: boolean): ng.IHttpPromise<{}> {
                    return $http.put("api/permissions/folder/" + folderId + "/checkIn?required=" + required.toString(), null);
                },


                SetUserPermission($http: ng.IHttpService, folderId: number, userId: string, p: IPermissionDefinitionViewModel): ng.IHttpPromise<boolean> {
                    return $http.put("api/permissions/folder/" + folderId + "/user/" + userId, p);
                },
                RemoveUserPermission($http: ng.IHttpService, folderId:number, userId:string): ng.IHttpPromise<boolean> {
                    return $http.delete("api/permissions/folder/" + folderId + "/user/" + userId);
                },
                SetGroupPermission($http: ng.IHttpService, folderId: number, groupId: string, p: IPermissionDefinitionViewModel): ng.IHttpPromise<boolean> {
                    return $http.put("api/permissions/folder/" + folderId + "/group/" + groupId, p);
                },
                RemoveGroupPermission($http: ng.IHttpService, folderId:number, groupId:string): ng.IHttpPromise<boolean> {
                    return $http.delete("api/permissions/folder/" + folderId + "/group/" + groupId);
                },


                // others
                DriveStatus($http:ng.IHttpService): ng.IHttpPromise<ITenantDriveStatus> {
                    return $http.get("api/folder/drive");
                },
                GetFolderDesign($http:ng.IHttpService, folderId:number): ng.IHttpPromise<IFolderDesignViewModel> {
                    return $http.get("api/folder/" + folderId + "/design");
                },
                FileIsUploading($http:ng.IHttpService, folderId:number, fileName:string): ng.IHttpPromise<string> {
                    return $http.get("api/folder/" + folderId + "/isuploading?fileName=" + encodeURIComponent(fileName));
                },
                SetFolderDesign($http:ng.IHttpService, folderId:number, vm:IFolderDesignViewModel): ng.IHttpPromise<boolean> {
                    return $http.put("api/folder/" + folderId + "/design", vm);
                },
                RemoveFolderDesign($http: ng.IHttpService, folderId:number): ng.IHttpPromise<boolean> {
                    return $http.delete("api/folder/" + folderId + "/design");
                },
                FolderImagesUrl: "api/folder/design/images",


            },
            NoAdmin: {
                GetOwnership($http: ng.IHttpService, folderId: number): ng.IHttpPromise<INoAdminViewModel> {
                    return $http.get("api/noadmin/" + folderId + "/getownership");
                },
                GiveFolderOwnership($http: ng.IHttpService, folderId: number, userId: string, code: string): ng.IHttpPromise<{}> {
                    return $http.put("api/noadmin/" + folderId + "/giveownership/" + userId + "?code=" + code, null);
                },
                TakeFolderOwnershipRequest($http: ng.IHttpService, folderId: number): ng.IHttpPromise<string> {
                    return $http.get("api/noadmin/" + folderId + "/requestownership");
                },
                TakeOwnershipCommit($http: ng.IHttpService, folderId: number, code: string): ng.IHttpPromise<{}> {
                    return $http.put("api/noadmin/" + folderId + "/commitownership?code=" + code, null);
                },
                RecoverOwnership($http: ng.IHttpService, folderId: number, key: string, code: string): ng.IHttpPromise<string> {
                    return $http.put("api/noadmin/" + folderId + "/recoverownership?code=" + code, "'" + key + "'");
                },
                RemoveOwnership($http: ng.IHttpService, folderId: number, key: string, code: string): ng.IHttpPromise<{}> {
                    return $http.put("api/noadmin/" + folderId + "/removeownership?code=" + code, "'" + key + "'");
                },

            },
            File: {
                Details($http:ng.IHttpService, fileId:number): ng.IHttpPromise<IFileViewModel> {
                    return $http.get("api/file/" + fileId);
                },
                Delete($http:ng.IHttpService, fileId:number): ng.IHttpPromise<boolean> {
                    return $http.delete("api/file/" + fileId);
                },
                Rename($http: ng.IHttpService, fileId: number, newName: string): ng.IHttpPromise<boolean> {
                    return $http.put("api/file/" + fileId, "'"+ newName + "'");
                },
                Copy($http: ng.IHttpService, fileId: number, targetFolderId: number): ng.IHttpPromise<boolean> {
                    return $http.post("api/file/" + fileId + "/to/" + targetFolderId, null);
                },
                Move($http: ng.IHttpService, fileId: number, targetFolderId: number): ng.IHttpPromise<boolean> {
                    return $http.put("api/file/" + fileId + "/to/" + targetFolderId, null);
                },
                GenerateThumb($http: ng.IHttpService, fileId: number): ng.IHttpPromise<boolean> {
                    return $http.post("api/file/" + fileId + "/thumb", null);
                },
                CheckOut($http: ng.IHttpService, fileId: number): ng.IHttpPromise<IFileViewModel> {
                    return $http.post("api/file/" + fileId + "/checkout", null);
                },
                CheckIn($http: ng.IHttpService, fileId: number): ng.IHttpPromise<IFileViewModel> {
                    return $http.post("api/file/" + fileId + "/checkin", null);
                },
                CheckUndo($http: ng.IHttpService, fileId: number): ng.IHttpPromise<IFileViewModel> {
                    return $http.post("api/file/" + fileId + "/checkundo", null);
                },
                CheckOutList($http: ng.IHttpService): ng.IHttpPromise<IFileViewModel[]> {
                    return $http.get("api/file/checkout");
                },
            },
            Note: {
                Url: "api/note",
            },
            Eventlog: {
                SystemUrl: "api/eventlog/system",
                CompanyUrl: "api/eventlog/company",
                AccessUrl(ownershipId: number) { return "api/eventlog/access/" + (ownershipId || "root"); },
                FileAccessUrl(fileId:number) { return "api/eventlog/access/file/" + fileId; },
                FolderAccessUrl(folderId:number) { return "api/eventlog/access/folder/" + folderId; },
            },
            Message: {
                GetConversations($http: ng.IHttpService): ng.IHttpPromise<IConversationViewModel[]> {
                    return $http.get("api/messenger");
                },
                GetConversation($http:ng.IHttpService, userId:string): ng.IHttpPromise<IMessageViewModel[]> {
                    return $http.get("api/messenger/" + userId);
                },
                SendMessage($http: ng.IHttpService, text: string, to: string): ng.IHttpPromise<IMessageViewModel> {
                    return $http.post("api/messenger/" + to, "'"+text+"'");
                },
                DeleteMessage($http: ng.IHttpService, messageId: number): ng.IHttpPromise<boolean> {
                    return $http.delete("api/messenger/" + messageId);
                },
            },
            Search: {
                Search($http:ng.IHttpService, q:string, onlyMine:boolean, sortNew:boolean, page:number): ng.IHttpPromise<ISearchResultViewModel> {
                    return $http.get("api/search?onlyMine=" + onlyMine + "&sortNew=" + sortNew + "&page=" + page + "&q=" + encodeURIComponent(q));
                },

            },
            Download: {
                // MVC Controller!
                ZipFolder(folderId:number) { return "/Download/ZipFolder/" + folderId }, // Requires Auth
                FolderReport(folderId: number) { return "/Download/FolderReport/" + folderId }, // Requires Auth
                FolderHash(folderId: number) { return "/Download/FolderHash/" + folderId }, // Requires Auth
                FolderImage(name: string, isTenantImage: boolean, key: string) {return "/Download/FolderImage/?isTenantImage=" + isTenantImage + "&name=" + encodeURIComponent(name) + "&key=" + key;},
                File(fileId: number) { return "/Download/File/" + fileId; }, // Requires Auth
                FileVersion(fileId: number, version: string ) { return "/Download/File/" + fileId + "?version=" + version; }, // Requires Auth
                FileCheckOut(fileId: number) { return "/Download/FileCheckOut/" + fileId; }, // Requires Auth
                Thumb(fileId: number) { return "/Download/Thumb/" + fileId; }, // Requires Auth
                ThumbTimewarp(fileId: number, timewarp: string) { return "/Download/ThumbTimewarp/" + fileId + "?timewarp=" + timewarp; }, // Requires Auth
                Timewarp(fileId: number, timewarp: string) { return "/Download/Timewarp/" + fileId + "?timewarp=" + timewarp; }, // Requires Auth
                Public(shareId:string, fileId:number, pin:string) { return "/Download/Public/" + shareId + "/" + fileId + "?pin=" + encodeURIComponent(pin); },
                PublicThumb(shareId:string, fileId:number, pin:string) { return "/Download/PublicThumb?fileId=" + fileId + "&shareId=" + shareId + "&pin=" + encodeURIComponent(pin); },
                Logo(companyId: string) {// Requires Auth
                    const salt = new Date().getTime();
                    return "/Download/Logo?a=" + salt + "&companyId=" + companyId;
                },
                Background(companyId: string, withUrlString: boolean) {// Requires Auth
                    let salt = new Date().getTime();
                    if (withUrlString) {
                        return "url(/Download/Background?a=" + salt + "&companyId=" + companyId + ")";
                    } else {
                        return "/Download/Background?a=" + salt + "&companyId=" + companyId;
                    }
                },
                Banner(companyId: string, withUrlString: boolean) {// Requires Auth
                    let salt = new Date().getTime();
                    if (withUrlString) {
                        return "url(/Download/Banner?a=" + salt + "&companyId=" + companyId + ")";
                    } else {
                        return "/Download/Banner?a=" + salt + "&companyId=" + companyId;
                    }
                },
            },
            Upload: {
                // MVC Controller!
                UploadCleanup($http: ng.IHttpService, folderId: number, fileName: string): ng.IHttpPromise<boolean> {
                    return $http.post("/Upload/UploadCleanup/", { folderId: folderId, fileName: fileName });
                },
                FlowUploadUrl: "/Upload/FlowUpload/",
                GetCurrentUploadsUrl: "/Upload/GetCurrentUploads/",
                CancelCurrentUploadUrl: "/Upload/CancelCurrentUpload",

            },

        };

        helper = {
            companyId() {

                //STRATEGY A: Search for domain parameter
                var url = window.location.search.substring(1);
                //remove Hashbang
                var hashPos = url.indexOf("#");
                if (hashPos !== -1) url = url.substring(0, hashPos);

                //remove Amp Parameter Chain
                var ampPos = url.indexOf('&');
                if (ampPos !== -1) url = url.substring(0, ampPos);

                //domain Param
                var paramPos = url.indexOf("id=");
                if (paramPos !== -1) return url.substring(3);


                //STRATEGY B: Search for public folder key
                var urlB = window.location.hash;
                var publicPos = urlB.indexOf("#/Public/");
                if (publicPos !== -1) {
                    var keyString = urlB.substring(publicPos + 9);
                    return keyString;
                }

                return "";

            },

            http(method: string, url: string, bearerTokenObject: any) {
                    var ret = {
                        url: url,
                        type: method.toUpperCase(),
                        //data: data,
                        headers: bearerTokenObject
                    }
                    return ret;
            }
        }



    }

    angular.module("app").value("appConfig", new AppConfig());
    
}