module App {
    export class AppFolderDesignerController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        trans: {
            'Core.Buttons.Ok': string,
            'Core.Buttons.Cancel': string,
        };
        branding: ng.IHttpPromiseCallbackArg<IBrandingViewModel>;
        folderId: number;

        //compute
        textOptions: kendo.ui.EditorOptions;
        imageSource1: kendo.data.DataSource;
        imageSource2: kendo.data.DataSource;
        colorOptions: kendo.ui.ColorPickerOptions;
        imageSelector1Options: kendo.ui.ListViewOptions;
        imageSelector2Options: kendo.ui.ListViewOptions;

        flowObject: flowjs.IFlow;
        dataRoom: IFolderViewModel;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', '$sce', '$scope', '$timeout', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService', 'uploadService'];
        constructor(
            private $http: ng.IHttpService,
            private $sce: ng.ISCEService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
            private uploadService: UploadService,
        ) {
        }

        $onInit(): void {
            this.flowObject = this.uploadService.flow;
            this.getFolder(this.folderId);
            this.uploadService.setSuccessCallback(this.generateRefreshCallback());
            this.uploadService.setFolderById(this.branding.data.DesignFolderId);

            // KENDO CONFIG
            this.colorOptions = {
                buttons: true,
                preview: true,
                //input: true,
                messages: {
                    apply: this.trans["Core.Buttons.Ok"],
                    cancel: this.trans["Core.Buttons.Cancel"]
                }
            }


            this.textOptions = {
                tools: [
                    { name: "bold" }, { name: "italic" }, { name: "underline" }, {name: "strikethrough"},
                    { name: "justifyLeft" }, { name: "justifyCenter" }, { name: "justifyRight" }, {name: "justifyFull"},
                    { name: "insertUnorderedList" }, { name: "insertOrderedList" }, { name: "indent" }, {name: "outdent"},
                    { name: "createLink" }, { name: "createLink" }, 
                    //{ name: "insertImage" }, 
                    { name: "createTable" }, { name: "addRowAbove" }, { name: "addRowBelow" }, {name: "addColumnLeft"}, {name: "addColumnRight"}, {name: "deleteRow"}, {name: "deleteColumn"},
                    { name: "viewHtml" }, { name: "cleanFormatting" }, { name: "fontSize" }, 
                    //{ name: "fontName" }, { name: "formatting" }, { name: "foreColor" }, { name: "backColor" }, 
                ]
            }

            this.imageSource1 = this.getImageSource1();
            this.imageSource2 = this.getImageSource2();
            this.imageSelector1Options = this.getImageSelector1Options();
            this.imageSelector2Options = this.getImageSelector2Options();
        }



        private getImageSource1(): kendo.data.DataSource {
            var that = this;
            return new kendo.data.DataSource({
                "type": 'webapi',
                "transport": {
                    "read": this.appConfig.helper.http('get', this.appConfig.request.Folder.FolderImagesUrl, this.authService.getBearerTokenObject())
                },
                "serverPaging": true,
                "pageSize": 30,
                "serverSorting": true,
                "serverFiltering": true,
                "sort": { "field": "Name", "dir": "asc" },
                "filter": [{ field: "IsTenant", operator: "eq", value: false }],
                "error": function(e: any) {
                    that.authService.kendoError(e, null);
                },
                "schema": {
                    model: {
                        "id": "Id",
                        "fields": {
                            "Id": { "type": "number" },
                            "Name": { "type": "string" },
                            "IsTenant": { "type": "boolean" },
                        }
                    },
                    "data": "Data",
                    "total": "Total",
                    "errors": "Errors"
                }
            });
        }

        
        private getImageSource2(): kendo.data.DataSource {
            var that = this;
            return new kendo.data.DataSource({
                "type": 'webapi',
                "transport": {
                    "read": this.appConfig.helper.http('get', this.appConfig.request.Folder.FolderImagesUrl, this.authService.getBearerTokenObject())
                },
                "serverPaging": true,
                "pageSize": 30,
                "serverSorting": true,
                "serverFiltering": true,
                "sort": { "field": "Name", "dir": "asc" },
                "filter": [{ field: "IsTenant", operator: "eq", value: true }],
                "error": function(e: any) {
                    that.authService.kendoError(e, null);
                },
                "schema": {
                    model: {
                        "id": "Id",
                        "fields": {
                            "Id": { "type": "number" },
                            "Name": { "type": "string" },
                            "IsTenant": { "type": "boolean" },
                        }
                    },
                    "data": "Data",
                    "total": "Total",
                    "errors": "Errors"
                }
            });
        } 

        

        private getImageSelector1Options(): kendo.ui.ListViewOptions {
            var that = this;
            return {
                dataSource: this.imageSource1, 
                selectable: true,
                change: function (e) {
                    var data = that.imageSource1.view();
                    var selected = $.map(e.sender.select(), item => data[$(item).index()]);
                    var dataItem = selected[0];
                    that.dataRoom.Design.ImageName = dataItem.Name;
                    that.dataRoom.Design.IsTenantImage = dataItem.IsTenant;

                    var style = that.imageUrl(dataItem);
                    $('.dataRoom .roomImage').css(style);
                    $('.roomInfo .roomImage').css(style);
                },
            }
        }

        

        private getImageSelector2Options(): kendo.ui.ListViewOptions {
            var that = this;
            return {
                dataSource: this.imageSource2,
                selectable: true,
                change: function(e) {
                    var data = that.imageSource2.view();
                    var selected = $.map(e.sender.select(), item => data[$(item).index()]);
                    var dataItem = selected[0];
                    that.dataRoom.Design.ImageName = dataItem.Name;
                    that.dataRoom.Design.IsTenantImage = dataItem.IsTenant;

                    var style = that.imageUrl(dataItem);
                    $('.dataRoom .roomImage').css(style);
                    $('.roomInfo .roomImage').css(style);
                },
            }
        }

        // ========================================
        // PUBLIC
        // ========================================
        getFolder(folderId: number) {
            this.filesystemService.getFolder(folderId).then(data => {
                this.dataRoom = data.CurrentFolder;
                this.dataRoom.Design.Text2 = this.$sce.trustAsHtml(this.dataRoom.Design.Text);
                if (!this.dataRoom.Design.Color1) this.dataRoom.Design.Color1 = "#888888";
                if (!this.dataRoom.Design.Color2) this.dataRoom.Design.Color2 = "#ffffff";
            });

        };

        setFolderDesign() {
            this.appConfig.request.Folder.SetFolderDesign(this.$http, this.folderId, this.dataRoom.Design).then(okResponse => {
                this.getFolder(this.folderId);
                this.helperService.alert('Branding.OkMessage', true);
            }, e=> this.helperService.error(e));
        }

        removeFolderDesign() {
            this.appConfig.request.Folder.RemoveFolderDesign(this.$http, this.folderId)
                .then(okResponse => {
                    this.getFolder(this.folderId);
                    this.helperService.alert('Branding.RemoveMessage', true);

                }, e => this.helperService.error(e));
        }

        imageUrl(dataItem: any) {
            this.authService.setTokenCookie();
            var url = this.appConfig.request.Download.FolderImage(dataItem.Name, dataItem.IsTenant, null);
            return {
                'background-image': "url('" + url + "')"
            }
        }

        generateRefreshCallback() {
            const self = this;
            return (file: IAppFlowFile) => {
                self.imageSource2.read();
            }

        }


        removeImage() {
            this.dataRoom.Design.ImageName = null;
            var style = this.imageUrl({ Name: "", IsTenant: false });
            $('.dataRoom .roomImage').css(style);
            $('.roomInfo .roomImage').css(style);
        }
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppFolderDesignerComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            trans: '<',
            branding: '<',
            folderId: '<',
        };

        controller = AppFolderDesignerController;
        templateUrl = "/AdminTemplate/FolderDesignerTemplate";
    }

    angular.module("app").component("appFolderDesigner", new AppFolderDesignerComponent());
}
