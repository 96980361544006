module App {
    function ByteReadableFilter(): Function {
        return function (input:any) {
            if (input === null || input === undefined || !isFinite(input)) return input;

            if (input < 1024) return input.toFixed(1) + " B";

            input = input / 1024;
            if (input < 1024) return input.toFixed(1) + " kB";

            input = input / 1024;
            if (input < 1024) return input.toFixed(1) + " MB";

            input = input / 1024;
            return input.toFixed(1) + " GB";

        };

    }
    angular.module("app").filter("byteReadable", ByteReadableFilter);
}