module App {
    export class AppNoteController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        trans: {[key:string]:string};
        //compute
        gridObject: kendo.ui.Grid;
        gridOptions: kendo.ui.GridOptions;
        noteType: ITextValue[];

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$q', '$translate', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];

        constructor(
            private $q: ng.IQService,
            private $translate: angular.translate.ITranslateService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            this.gridOptions = this.getGridOptions();
        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        private textEditorInitialize(container:JQuery, options:any) {
            $('<textarea name="' + options.field + '" style="width: 100%;height:' + container.height() + 'px" />')
                .appendTo(container);
        };

        private getGridOptions(): kendo.ui.GridOptions {
            var that = this;
            return {
                //1. DataSource
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.Note.Url, this.authService.getBearerTokenObject()),
                        "update": this.appConfig.helper.http('put', this.appConfig.request.Note.Url, this.authService.getBearerTokenObject()),
                        "create": this.appConfig.helper.http('post', this.appConfig.request.Note.Url, this.authService.getBearerTokenObject()),
                        "destroy": this.appConfig.helper.http('delete', this. appConfig.request.Note.Url, this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 20,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "serverGrouping": true,
                    "serverAggregates": true,
                    "sort": { "field": "Date", "dir": "desc" },
                    "filter": [],
                    "error": function(e:any) {
                        that.authService.kendoError(e, that.gridObject);
                    },
                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "number" },
                                "Date": { "type": "date", editable: false },
                                "Content": { "type": "string" },
                                "Type": { "type": "number" },
                                "ContentDate": { "type": "date", defaultValue: null as any, nullable: true },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    }

                },
                //2. Columns
                "columns": [
                    {
                        "field": "Date",
                        "title": "{{'Note.Date' | translate}}",
                        "format": "{0:g}",
                        "filterable": true,
                        "width": 150,
                    }, {
                        "field": "Type",
                        "title": "{{'Note.Type' | translate}}",
                        "filterable": true,
                        "values": this.noteType,
                    }, {
                        "field": "Content",
                        "title": "{{'Note.Content' | translate}}",
                        "filterable": true,
                        "editor": this.textEditorInitialize,
                    }, {
                        "field": "ContentDate",
                        "title": "{{'Note.ContentDate' | translate}}",
                        "format": "{0:d}",
                        "filterable": true,
                        "width": 100,
                    }, {
                        "width": 100,
                        "command": [
                            { "name": "edit" },
                            { "name": "destroy" },
                        ]
                    }
                ],

                // 3. Features
                "editable": {
                    "mode": "inline",
                    "update": true,
                    "destroy": true
                },
                "toolbar": [
                    { "name": 'create', "buttonType": "ImageAndText", "text": "{{'Note.NewNote' | translate}}" },
                    { name: "excel", "buttonType": "ImageAndText", "text": "{{'Note.ExcelExport' | translate}}" }
                ],
                "pageable": true,
                "sortable": true,
                "scrollable": false,
                "filterable": {
                    operators: this.localeService.getKendoFilterTranslationObject()
                },
                "excel": { allPages: true, fileName: this.trans['Manager.Tenant.ExcelPrefix'] + ".xlsx", filterable: true },
            }
        };
    }


    export class AppNoteComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            trans: '<',
            noteType: '<'
        };

        controller:any = AppNoteController;
        templateUrl="/UserTemplate/NoteTemplate";
    }

    angular.module("app").component("appNote", new AppNoteComponent());
}


