module App {
    export class FullscreenService {
        public static Factory($window: ng.IWindowService,
            $rootScope: ng.IRootScopeService,
            $q: ng.IQService,
            authService: AuthService) {
            return new FullscreenService($window, $rootScope, $q, authService);
        }

        constructor(
            private $window: ng.IWindowService,
            private $rootScope: ng.IRootScopeService,
            private $q: ng.IQService,
            private authService: AuthService) {

            this.registerService();
        }

        // Callback
        private onChange: (isFullscreenEnabled: boolean) => void;
        registerOnChange(callback: (isFullScreenEnabled: boolean) => void) {
            this.onChange=callback;
        }

        // compare Value
        private isFullscreen: boolean;

        // Data
        currentUser: IApplicationUserClientStatusViewModel;


        // PUBLIC

        // PRIVATE
        private getData() {
            this.$q.all([
                this.authService.init(false, false),
            ]).then(okResponses => {
                this.currentUser = okResponses[0];
                if (this.onChange) this.onChange(true);
            });
        }

        private registerService() {
            // call for first init
            this.getIsFullscreen();

            // register change event
            angular.element(this.$window).bind('resize', () => {
                this.getIsFullscreen();
                this.$rootScope.$digest();
            });

        }

        private getIsFullscreen() {
            var res = $("#content-fullscreen").css('display') !== "none";
            if (res !== this.isFullscreen) {
                this.isFullscreen = res;
                if (res) {
                    this.getData();
                } else {
                    if (this.onChange) this.onChange(false);
                }
            }
        }


    }

    angular.module('app').factory('fullscreenService', ['$window', '$rootScope', '$q', 'authService', FullscreenService.Factory]);
}

