module App {
    export class PermissionDisplayController implements ng.IComponentController {
        //binding
        p: IPermissionDefinitionViewModel;
        inherited: boolean;
        folderId: number;
        canEdit: boolean;
        onUpdate: Function;

        //compute
        denyAllMsg: string;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', '$translate', '$timeout', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService'];
        constructor(
            private $http: ng.IHttpService,
            private $translate: ng.translate.ITranslateService,
            private $timeout: ng.ITimeoutService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void {
            this.$translate('Datarooms.FolderSecurity.DenyAllWarning').then(t => this.denyAllMsg = t);

        }
        $onChanges(onChangesObj: ng.IOnChangesObject): void { }

        // ========================================
        // PUBLIC
        // ========================================
        setPermission() {
            if (!this.canEdit) return;

            const p = this.p.IsUser
                ? this.appConfig.request.Folder.SetUserPermission(this.$http, this.folderId, this.p.UserOrGroupId, this.p)
                : this.appConfig.request.Folder.SetGroupPermission(this.$http, this.folderId, this.p.UserOrGroupId, this.p);

            p.then(ok => {
                this.$translate('Datarooms.FolderSecurity.PermissionChangedMsg', { name: this.p.UserOrGroupName })
                    .then(msg => { this.onUpdate({msg: msg}); });

            }, e => this.helperService.error(e));
        }

        removePermission() {
            if (!this.canEdit) return;

            const question = this.p.IsUser ? 'Datarooms.FolderSecurity.RemoveUserPermissionTextMsg' : 'Datarooms.FolderSecurity.RemoveGroupPermissionTextMsg';
            const name = this.p.UserOrGroupName;
            this.helperService.confirm('Datarooms.FolderSecurity.RemovePermissionTitleMsg', question, () => {
                const p = this.p.IsUser
                    ? this.appConfig.request.Folder.RemoveUserPermission(this.$http, this.folderId, this.p.UserOrGroupId)
                    : this.appConfig.request.Folder.RemoveGroupPermission(this.$http, this.folderId, this.p.UserOrGroupId);

                p.then(ok => {
                    this.$translate('Datarooms.FolderSecurity.PermissionRemovedMsg', { name: name })
                        .then(msg => { this.onUpdate({ msg: msg }); });
                }, e => this.helperService.error(e));

            }, null, true);


        }

        // ========================================
        // PRIVATE
        // ========================================

    }


    export class PermissionDisplayComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            p: '<',
            inherited: '<',
            folderId: '<',
            canEdit: '<',
            onUpdate: '&',
        };

        controller = PermissionDisplayController;
        template = `
            <div class="row">
                <div class="col-md-3">
                    <p ng-class="{strong: !$ctrl.p.IsUser}">{{$ctrl.p.UserOrGroupName}}</p>
                    <span ng-show="!$ctrl.p.IsUser">(<span translate>Datarooms.FolderSecurity.Group</span>)</span>
                    <span ng-show="$ctrl.p.IsUser">(<span translate>Datarooms.FolderSecurity.User</span>)</span>
                    <p ng-show="!$ctrl.p.IsFullLicense" class="text-info" translate>Datarooms.FolderSecurity.LightLicense</p>
                </div>
                <div class="col-md-3">
                    <div><input type="checkbox" checked disabled /> <span translate>Datarooms.FolderSecurity.CanDownload</span> </div>
                    <div><input type="checkbox" ng-model="$ctrl.p.CanUpload" ng-disabled="$ctrl.inherited || !$ctrl.canEdit" ng-click="$ctrl.setPermission()" /> <span translate>Datarooms.FolderSecurity.CanUpload</span> </div>
                    <div ng-show="$ctrl.p.IsFullLicense"><input type="checkbox" ng-model="$ctrl.p.CanCreateFolder" ng-disabled="$ctrl.inherited || !$ctrl.canEdit" ng-click="$ctrl.setPermission()" /> <span translate>Datarooms.FolderSecurity.CanCreateFolder</span></div>
                    <div ng-show="$ctrl.p.IsFullLicense"><input type="checkbox" ng-model="$ctrl.p.CanDelete" ng-disabled="$ctrl.inherited || !$ctrl.canEdit" ng-click="$ctrl.setPermission()" /> <span translate>Datarooms.FolderSecurity.CanDelete</span></div>
                    <div ng-show="!$ctrl.p.IsFullLicense"><input type="checkbox" ng-model="$ctrl.p.CanDelete" ng-disabled="$ctrl.inherited || !$ctrl.canEdit" ng-click="$ctrl.setPermission()" /> <span translate>Datarooms.FolderSecurity.CanDeleteFile</span></div>
                </div>
                <div class="col-md-3">
                    <div ng-show="$ctrl.p.IsFullLicense"><input type="checkbox" ng-model="$ctrl.p.CanReadLogs" ng-disabled="$ctrl.inherited || !$ctrl.canEdit" ng-click="$ctrl.setPermission()" /> <span translate>Datarooms.FolderSecurity.CanReadLogs</span></div>
                    <div ng-show="$ctrl.p.IsFullLicense"><input type="checkbox" ng-model="$ctrl.p.CanManagePermissions" ng-disabled="$ctrl.inherited || !$ctrl.canEdit" ng-click="$ctrl.setPermission()" /> <span translate>Datarooms.FolderSecurity.CanManagePermissions</span></div>
                    <div ng-show="$ctrl.p.IsFullLicense"><input type="checkbox" ng-model="$ctrl.p.CanManageOptions" ng-disabled="$ctrl.inherited || !$ctrl.canEdit" ng-click="$ctrl.setPermission()" /> <span translate>Datarooms.FolderSecurity.CanManageOptions</span></div>
                    <div ng-show="$ctrl.p.IsFullLicense">
                        <input type="checkbox" ng-model="$ctrl.p.DenyAllAccess" ng-disabled="$ctrl.inherited || !$ctrl.canEdit" ng-click="$ctrl.setPermission()" /> <span translate class="text-danger">Datarooms.FolderSecurity.DenyAllAccess</span>
                        <span kendo-tooltip k-content="$ctrl.denyAllMsg" k-rebind="$ctrl.denyAllMsg" ><span class="text-info glyphicon glyphicon-info-sign"></span></span>
                    </div>
                </div>
                <div class="col-md-3">
                    <p class="text-danger strong" ng-show="$ctrl.inherited" translate>Datarooms.FolderSecurity.Inherited</p>
                    <span ng-show="!$ctrl.inherited && $ctrl.canEdit" ng-click="$ctrl.removePermission()" class="clickable"><span class="glyphicon glyphicon-remove"></span></span>
                </div>
            </div>
            <hr />
        `;
    }

    angular.module("app").component("permissionDisplay", new PermissionDisplayComponent());
}