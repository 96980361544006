module App {
    interface IOwnershipSelectCallbackObject {
        i: IOwnerShipViewModel
    }
    export class OwnershipController implements ng.IComponentController {
        //binding
        ownerships: IOwnerShipViewModel[];
        onSelect: (cbo:IOwnershipSelectCallbackObject)=>void;

        //compute
        selected: IOwnerShipViewModel;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        //static $inject: string[] = ['$scope', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        //constructor(
        //    private $scope: ng.IScope,
        //    private $timeout: ng.ITimeoutService,
        //    private $http: ng.IHttpService,
        //    private appConfig: AppConfig,
        //    private authService: AuthService,
        //    private coreDataService: CoreDataService,
        //    private localeService: LocaleService,
        //    private helperService: HelperService
        //) {
        //}

        $onInit(): void { }

        $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj['ownerships'] && onChangesObj['ownerships'].currentValue) {
                this.select(null);
            }
        }

        // ========================================
        // PUBLIC
        // ========================================
        select(id: number):void {
            if (!this.ownerships) return null;
            this.selected = null;

            if (id) {
                angular.forEach(this.ownerships, i => {
                    if (i.Key === id) {
                        this.selected = i;
                        return;
                    }
                });
            } else {
                if (this.ownerships.length) this.selected = this.ownerships[0];
            }

            if (this.selected) this.onSelect({i: this.selected});

        }

        // ========================================
        // PRIVATE
        // ========================================

    }


    export class OwnershipComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            ownerships: '<',
            //selected: '=',
            onSelect: '&',
        };

        controller = OwnershipController;
        //templateUrl='';
        template = 
            '<h2 translate>Datarooms.Ownership.Header</h2><p translate>Datarooms.Ownership.Description</p><p translate>Datarooms.Ownership.DescriptionAdmin</p><p translate>Datarooms.Ownership.DescriptionNoAdmin</p>' +
            '<p ng-show="$ctrl.ownerships.length===0" translate>Datarooms.Ownership.NoAreas</p>' +
            '<p ng-show="$ctrl.ownerships.length===1" translate translate-value-name="{{$ctrl.ownerships[0].Value}}">Datarooms.Ownership.Single</p>' +
            '<p ng-show="$ctrl.ownerships.length > 1" translate>Datarooms.Ownership.Multiple</p>' +
            '<span ng-show="$ctrl.ownerships.length > 1" ng-repeat="o in $ctrl.ownerships" class="btn" ng-class="{\'btn-default\': o.Key!==$ctrl.selected.Key, \'btn-primary\': o.Key===$ctrl.selected.Key}" ng-click="$ctrl.select(o.Key)">{{o.Value}}</span>'
        ;
    }

    angular.module("app").component("ownership", new OwnershipComponent());
}
