module App {
    export class AppUploadController implements ng.IComponentController {
        //binding
        folderId: number;

        //compute
        view = {
            tab: 0
        }
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['uploadService', 'uploadPrepareService', 'conflictService'];
        constructor(
            private uploadService: UploadService,
            private uploadPrepareService: UploadPrepareService,
            private conflictService: ConflictService
        ) {
        }

        $onInit(): void {
            if (this.folderId) {
                this.uploadService.setFolderById(this.folderId);
            }

        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppUploadComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            folderId: '<',
        };
        controller = AppUploadController;
        templateUrl = "/UserTemplate/UploadTemplate";
    }

    angular.module("app").component("appUpload", new AppUploadComponent());
}


