module App {
    export class AppFolderInformationController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        folderAccessType: ITextValue[];
        folderId: number;

        //compute
        myPermissions: ICurrentPermissionViewModel;
        data: IFolderControlViewModel;
        grid: kendo.ui.Grid;
        gridOptions: kendo.ui.GridOptions;

        reportUrl = this.appConfig.request.Download.FolderReport(this.folderId);
        hashUrl = this.appConfig.request.Download.FolderHash(this.folderId);
        zipUrl = this.appConfig.request.Download.ZipFolder(this.folderId);

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'localeService', 'helperService', 'navigationService'];
        constructor(
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private localeService: LocaleService,
            private helperService: HelperService,
            private navigationService: NavigationService
        ) {
        }

        $onInit(): void {
            if (this.folderId) {
                this.getFolder(this.folderId);
                this.getMyPermissions(this.folderId);
            } else {
                this.helperService.alert("Core.Backend.ExDataValidation", true);
            }

        }
        // ========================================
        // PUBLIC
        // ========================================
        getMyPermissions(folderId: number) {
            this.appConfig.request.Folder.GetMyPermissions(this.$http, folderId).then(okResponse => {
                this.myPermissions = okResponse.data;
                if (this.myPermissions.CanReadLogs) this.gridOptions = this.getGridOptions();
            }, e=> this.helperService.error(e));

        }

        getFolder(folderId: number) {
            this.filesystemService.getFolder(folderId).then(data => {
                this.data = data;
                this.getMyPermissions(folderId);
            });

        };

        // ========================================
        // PRIVATE
        // ========================================
        
        private getGridOptions(): kendo.ui.GridOptions {
            var that = this;
            return {
                // 1. DATASOURCE
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.Eventlog.FolderAccessUrl(this.folderId), this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 20,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "sort": { "field": "DateTime", "dir": "desc" },
                    "filter": [],
                    "error": function(e:any) {
                        that.authService.kendoError(e, that.grid);
                    },
                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "number" },
                                "DateTime": { "type": "date" },
                                "FolderAccessType": { "type": "string" },
                                "User": { "type": "string" },
                                "Details": { "type": "string" },
                                "IpAddress": { "type": "string" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    },

                },
                // 2.COLUMNS
                "columns": [
                    {
                        "field": "DateTime",
                        "title": "{{'Datarooms.FolderInformation.DateTime' | translate}} ",
                        "format": "{0:g}",
                        "filterable": true
                    }, {
                        "field": "IpAddress",
                        "title": "{{'Datarooms.FolderInformation.IpAddress' | translate}} ",
                    }, {
                        "field": "FolderAccessType",
                        "title": "{{'Datarooms.FolderInformation.FolderAccessType' | translate}} ",
                        "filterable": true,
                        "values": this.folderAccessType,
                    }, {
                        "field": "User",
                        "title": "{{'Datarooms.FolderInformation.User' | translate}} ",
                        "filterable": true
                    }, {
                        "field": "Details",
                        "title": "{{'Datarooms.FolderInformation.Details' | translate}} ",
                        "filterable": true
                    }
                ],
                //3. FEATURES
                "pageable": true,
                "sortable": true,
                "scrollable": false,
                "filterable": {
                    operators: this.localeService.getKendoFilterTranslationObject()

                },
            }
        };

    }


    export class AppFolderInformationComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            folderAccessType: '<',
            folderId: '<',
        };

        controller = AppFolderInformationController;
        templateUrl="/UserTemplate/FolderInformationTemplate";
    }

    angular.module("app").component("appFolderInformation", new AppFolderInformationComponent());
}

