module App {
    export class AppSettingsController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;

        //compute

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['authService'];
        constructor(
            private authService: AuthService,
        ) {
        }

        $onInit(): void { }
        // ========================================
        // PUBLIC
        // ========================================
        logout(): void {
            this.authService.logout();
        }
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppSettingsComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<'
        };

        controller = AppSettingsController;
        templateUrl="/UserTemplate/SettingsTemplate";
    }

    angular.module("app").component("appSettings", new AppSettingsComponent());
}


