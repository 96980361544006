module App {
    interface IAppTokenManagerTrans {
        'Manager.Token.ExcelPrefix': string;
        'Manager.Token.MobileApp': string;
        'Manager.Token.SecurityToken': string;
    }

    export class AppTokenManagerController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        trans: IAppTokenManagerTrans;
        tenants: ng.IHttpPromiseCallbackArg<IIdAndName[]>;

        //compute
        gridOptions: kendo.ui.GridOptions;
        gridObject: kendo.ui.Grid;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', 'appConfig', 'authService', 'localeService', 'helperService'];

        constructor(
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            this.gridOptions = this.getGridOptions();
        }

        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        private passwordEditor(container:JQuery, options:any) {
            $('<input type="password" required data-bind="value:' + options.field + '" class="k-input k-textbox"/>').appendTo(container);
        };

        private tenantEditor (container:JQuery, options:any) {
            $('<input name="' + options.field + '" data-text-field="text" data-value-field="value" data-bind="value:' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    dataSource: this.tenants.data,
                    valuePrimitive: true,
                    dataTextField: 'Name',
                    dataValueField: 'Id',
                    //filter: 'contains',
                });

            $('<span class="k-invalid-msg" data-for="' + options.field + '"></span>').appendTo(container);

        };

        private getGridOptions(): kendo.ui.GridOptions {
            var that = this;
            return {
                //1. DataSource
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.TenantManagement.TokenUrl, this.authService.getBearerTokenObject()),
                        "update": this.appConfig.helper.http('put', this.appConfig.request.TenantManagement.TokenUrl, this.authService.getBearerTokenObject()),
                        "create": this.appConfig.helper.http('post', this.appConfig.request.TenantManagement.TokenUrl, this.authService.getBearerTokenObject()),
                        "destroy": this.appConfig.helper.http('delete', this.appConfig.request.TenantManagement.TokenUrl, this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 50,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "serverGrouping": true,
                    "serverAggregates": true,
                    "filter": [],
                    "error": function(e:any) {
                        that.authService.kendoError(e, that.gridObject);
                    },
                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "number" },
                                "Type": { "type": "number", defaultValue: 2 },
                                "Serial": { "type": "string" },
                                "Key": { "type": "string" },
                                "TenantId": { "type": "number", "field": "TenantId", nullable: true },
                                "TenantName": { "type": "string" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    }

                },
                //2. Columns
                "columns": [
                    {
                        "field": "Serial",
                        "title": "{{'Manager.Token.Serial' | translate}}",
                        "filterable": true,
                        "width": 150,
                    }, {
                        "field": "Type",
                        "title": "{{'Manager.Token.Type' | translate}}",
                        "filterable": true,
                        "values": [
                            { value: 1, text:this.trans["Manager.Token.MobileApp"] },
                            { value: 2, text: this.trans["Manager.Token.SecurityToken"] },
                        ]
                    }, {
                        "field": "Key",
                        "title": "{{'Manager.Token.Key' | translate}}",
                        "filterable": true,
                        "format": "****************",
                        "editor": this.passwordEditor,
                    }, {
                        "field": "TenantId",
                        "title": "{{'Manager.Token.Tenant' | translate}}",
                        "values": this.tenants.data,
                        "editor": this.tenantEditor,
                        "filterable": true,
                        "width": 150,
                    }, {
                        "width": 250,
                        "command": [
                            { "name": "edit" },
                            { "name": "destroy" },
                        ]
                    }
                ],

                // 3. Features
                "editable": {
                    "mode": "inline",
                    confirmation: true
                },
                "toolbar": [
                    { "name": 'create', "buttonType": "ImageAndText", "text": "{{'Manager.Token.NewToken' | translate}}" },
                    { name: "excel", "buttonType": "ImageAndText", "text": "{{'Manager.Token.ExcelExport' | translate}}" }
                ],
                "pageable": true,
                "sortable": true,
                "scrollable": false,
                "filterable": {
                    mode: "menu",
                    operators: this.localeService.getKendoFilterTranslationObject()
                },
                "excel": { allPages: true, fileName: this.trans["Manager.Token.ExcelPrefix"] + ".xlsx", filterable: true },

            }
        }
    }


    export class AppTokenManagerComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            trans: '<',
            tenants: '<',
        };

        controller = AppTokenManagerController;
        templateUrl = "/AdminTemplate/TokenTemplate";
    }

    angular.module("app").component("appTokenManager", new AppTokenManagerComponent());
}

