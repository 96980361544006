module App {
    AspDate.$inject = ["$filter"];

    function AspDate($filter: ng.IFilterService): Function {
        return function(input:any, format:string) {
            if (!input) {

            } else if (angular.isDate(input)) {

            } else if (input.indexOf("/Date(") === 0) {
                input = moment(input);
                if (format === "fromNow") return input.fromNow();
                input = input.toDate();
            } else if (moment(input, moment.ISO_8601).isValid()) {
                input = moment(input, moment.ISO_8601);
                if (format === "fromNow") return input.fromNow();
                input = input.toDate();
            }

            return $filter('date')(input, format);
        };
    }

    angular.module("app").filter("aspDate", AspDate);
}