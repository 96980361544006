module App {
    interface IAppUsersTrans {
        'Tenant.Users.UserTab.ConfirmDeleteUser': string;
        'Tenant.Users.UserTab.NewUser': string,
        'Tenant.Users.UserTab.FullLicense': string,
        'Tenant.Users.UserTab.LightLicense': string,
        'Tenant.Users.UserTab.SmartPhoneAndToken': string,
        'Tenant.Users.UserTab.NoTwoFactor': string,
        'Tenant.Users.UserTab.SmartPhoneOnly': string,
        'Tenant.Users.UserTab.TokenOnly': string,

        'Tenant.Users.UserTab.ResetPasswordButton': string,
        'Tenant.Users.UserTab.ChangeTwoFactorMethod': string,
    }

    export class AppUsersController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        trans: IAppUsersTrans;
        language: ITextValue[];

        //compute
        userGrid: kendo.ui.Grid;
        userGridOptions: kendo.ui.GridOptions;
        partnerGrid: kendo.ui.Grid;
        partnerGridOptions: kendo.ui.GridOptions;
        groupGrid: kendo.ui.Grid;
        groupGridOptions: kendo.ui.GridOptions;

        resetPasswordWindowOptions: kendo.ui.WindowOptions;
        twoFactorTypeWindowOptions: kendo.ui.WindowOptions;

        selectedUser: IApplicationUserViewModel;
        pass: {
            new1: "",
            new2: "",
        }

        ResetPasswordWindow: kendo.ui.Window;
        TwoFactorTypeWindow: kendo.ui.Window;
        settings: ITenantDetailViewModel;

        userLicense: kendo.dataviz.ui.LinearGauge;
        userLicenseOptions: kendo.dataviz.ui.LinearGaugeOptions;
        partnerLicense: kendo.dataviz.ui.LinearGauge;
        partnerLicenseOptions: kendo.dataviz.ui.LinearGaugeOptions;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', '$scope', '$timeout', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService'];
        constructor(
            private $http: ng.IHttpService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void {
            this.userGridOptions = this.getUserGridOptions(true);
            this.partnerGridOptions = this.getUserGridOptions(false);
            this.groupGridOptions = this.getGroupGridOptions();
            this.resetPasswordWindowOptions = this.getResetPasswordWindowOptions();
            this.twoFactorTypeWindowOptions = this.getTwoFactorTypeWindowOptions();
            this.getSettings();

        }

        // ========================================
        // KENDO
        // ========================================
        private getResetPasswordWindowOptions(): kendo.ui.WindowOptions {
            return {
                "title": this.trans["Tenant.Users.UserTab.ResetPasswordButton"],
                "visible": false,
                "modal": true,
                "draggable": true,
                "width": 300
            }
        }

        private getTwoFactorTypeWindowOptions(): kendo.ui.WindowOptions {
            return {
                "title": this.trans["Tenant.Users.UserTab.ChangeTwoFactorMethod"],
                "visible": false,
                "modal": true,
                "draggable": true,
                "width": 300
            }
        }

        // ========================================
        // PUBLIC
        // ========================================
        submitPasswordChange() {
            this.appConfig.request.UserManagement.ResetPassword(this.$http, this.selectedUser.Id, this.pass.new1, this.pass.new2).then(okResponse => {
                this.closePasswordWindow();
                this.helperService.alert('Tenant.Users.UserTab.ResetPasswordSuccess', true);
            }, errResponse => {
                this.closePasswordWindow();
                this.helperService.error(errResponse);
            });

        }

        closePasswordWindow() {
            this.ResetPasswordWindow.center().close();
            this.pass = {
                new1: "",
                new2: ""
            };
        }

        closeTwoFactorTypeWindow() {
            this.TwoFactorTypeWindow.center().close();
        }

        authenticatorTypeToString(int: number) {
            if (int === undefined) return "?";
            switch (int) {
            case -1:
                return this.trans["Tenant.Users.UserTab.SmartPhoneAndToken"];
            case 0:
                    return this.trans["Tenant.Users.UserTab.NoTwoFactor"];
            case 1:
                    return this.trans["Tenant.Users.UserTab.SmartPhoneOnly"];
            case 2:
                    return this.trans["Tenant.Users.UserTab.TokenOnly"];
            default:
                console.error("Invalid authenticator Type " + int);
                return "";
            }
        }


        openPasswordWindow(dataItem:IApplicationUserViewModel) {
            this.selectedUser = dataItem;
            this.ResetPasswordWindow.center().open();
        };

        openTwoFactorTypeWindow(dataItem: IApplicationUserViewModel) {
            console.debug(dataItem as any);
            this.selectedUser = dataItem;
            this.TwoFactorTypeWindow.center().open();
        };

        disableTwoFactor(dataItem: IApplicationUserViewModel) {
            this.closeTwoFactorTypeWindow();
            if (dataItem.IsAuthenticatorEnabled) {
                this.helperService.confirm("Tenant.Users.UserTab.ResetTwoFactorTitle", "Tenant.Users.UserTab.ResetTwoFactorConfirm", ()=> {
                    this.appConfig.request.UserManagement.ResetTwoFactor(this.$http, dataItem.Id).then(okResult => {
                        if (okResult.data) {
                            dataItem.IsAuthenticatorEnabled = false;
                        } else {
                            this.helperService.alert('Tenant.Users.UserTab.ResetTwoFactorFail', true);
                        }
                        //$scope.userGrid.dataSource.read();

                    }, errResult => {
                        this.helperService.error(errResult);
                        //this.helperService.alert('Tenant.Users.UserTab.ResetTwoFactorFail', true);
                        this.userGrid.dataSource.read();
                    });
                }, null, true);
            } else {
                this.helperService.alert('Tenant.Users.UserTab.ResetTwoFactorInvalid', true);
            }
        }

        changeTwoFactor(dataItem: IApplicationUserViewModel, type: AuthenticatorType) {
            if (dataItem.IsAuthenticatorEnabled) {
                this.helperService.alert('Tenant.Users.UserTab.ChangeTwoFactorInvalid', true);
                return;
            }
            this.appConfig.request.UserManagement.ChangeTwoFactor(this.$http, dataItem.Id, type).then(okResult => {
                this.closeTwoFactorTypeWindow();
                if (okResult.data) {
                    dataItem.AuthenticatorType = type;
                } else {
                    this.helperService.alert('Tenant.Users.UserTab.ChangeTwoFactorFail', true);
                }

            }, errResult => {
                this.closeTwoFactorTypeWindow();
                this.helperService.error(errResult);
            });
        };


        // ========================================
        // PRIVATE
        // ========================================
        private getSettings() {
            this.appConfig.request.CompanyManagement.GetSettings(this.$http).then(s=> {
                this.settings = s.data;

                this.userLicenseOptions = this.getLicenseOptions(this.settings.FullLicenseLimit);
                this.partnerLicenseOptions = this.getLicenseOptions(this.settings.LightLicenseLimit);

            });
        }

        private getLicenseOptions(max:number): kendo.dataviz.ui.LinearGaugeOptions {
            return {
                scale: {
                    min: 0,
                    max: max,
                    vertical: false,
                    ranges: [
                        { from: 0, to: max * 0.3, color: this.helperService.getBrandingColor('info', false) },
                        { from: max * 0.3, to: max * 0.7, color: this.helperService.getBrandingColor('success', false) },
                        { from: max * 0.7, to: max * 0.9, color: this.helperService.getBrandingColor('warning', false) },
                        { from: max * 0.9, to: max, color: this.helperService.getBrandingColor('danger', false) },
                    ],
                    labels: {
                        font: "10px"
                    },
                }
            }
        }

        private currentHighlight: JQuery;
        private currentHighlightIsAlt = false;
        private highlightRow(gridObject:kendo.ui.Grid, dataItem: IApplicationUserViewModel) {
            // remove existing old highlight
            if (this.currentHighlight) {
                this.currentHighlight.removeClass("bg-primary").removeClass("strong");
                if (this.currentHighlightIsAlt) this.currentHighlight.addClass("k-alt");
                this.currentHighlight = null;
            }

            if (dataItem) {
                var row = this.helperService.searchRowInGrid(gridObject, dataItem);
                if (row == null) return;
                this.currentHighlight = row;
                if (row.attr('class').indexOf('k-alt') > -1) {
                    this.currentHighlightIsAlt = true;
                    row.removeClass('k-alt');
                } else {
                    this.currentHighlightIsAlt = false;
                }
                row.addClass("bg-primary").addClass("strong");

            }
        }

        private onSelectItem(e: kendo.ui.GridSaveEvent, grid: kendo.ui.Grid) {
            this.selectedUser = e.model as any;
            this.highlightRow(grid, this.selectedUser);
        };


        private getUserGridOptions(isUserLicense: boolean): kendo.ui.GridOptions {
            var that = this;
            return {
                // 1. Datasource
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.UserManagement.UsersUrl, this.authService.getBearerTokenObject()),
                        "update": this.appConfig.helper.http('put', this.appConfig.request.UserManagement.UsersUrl, this.authService.getBearerTokenObject()),
                        "create": this.appConfig.helper.http('post', this.appConfig.request.UserManagement.UsersUrl, this.authService.getBearerTokenObject()),
                        "destroy": this.appConfig.helper.http('delete', this.appConfig.request.UserManagement.UsersUrl, this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 30,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "serverGrouping": false,
                    "serverAggregates": true,
                    "sort": [{ "field": "UserName", "dir": "asc" }],
                    "filter": [{ "field": "IsInternal", "operator": "eq", "value": isUserLicense }],
                    "error": function (e:any) {
                        const grid = isUserLicense ? that.userGrid : that.partnerGrid;
                        that.authService.kendoError(e, grid);
                    },
                    "schema": {
                        "model": {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "string" },
                                "FirstName": { "type": "string" },
                                "LastName": { "type": "string" },
                                "IsEnabled": { "type": "boolean" },
                                "LastLogon": { "editable": false, "type": "date", "defaultValue": null as any },
                                "FullName": { "type": "string" },
                                "UserName": { "type": "string" },
                                "Email": { "type": "string" },
                                "IsAuthenticatorEnabled": { "type": "boolean" },
                                "IsInternal": { "type": "boolean", "defaultValue": isUserLicense, "editable": false },
                                "AuthenticatorType": { "type": "number" },
                                "TokenSerial": { "type": "string" },
                                "Language": { "type": "number", "field": "Language" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    },

                },

                //2. Columns
                "columns": [
                    {
                        "title": "{{'Tenant.Users.UserTab.FirstName' | translate}}",
                        "field": "FirstName",
                    }, {
                        "title": "{{'Tenant.Users.UserTab.LastName' | translate}}",
                        "field": "LastName",
                    }, {
                        "title": "{{'Tenant.Users.UserTab.UserName' | translate}}",
                        "field": "UserName",
                    }, {
                        "title": "{{'Tenant.Users.UserTab.Email' | translate}}",
                        "field": "Email",
                    }, {
                        "title": "{{'Tenant.Users.UserTab.Language' | translate}}",
                        "field": "Language",
                        "values": this.language,
                    }, {
                        "title": "{{'Tenant.Users.UserTab.IsEnabled' | translate}}",
                        "field": "IsEnabled",
                        "template": '<input type="checkbox" id="IsEnabled" #= IsEnabled ? checked="checked" : "" # disabled="disabled" />',
                    }, {
                        "width": 200,
                        "command": [
                            { "name": "edit" },
                            { "name": "destroy" },
                        ]
                    }
                ],

                //3. Features
                "sortable": true,
                "scrollable": false,
                "pageable": true,
                "editable": {
                    "confirmation": this.trans["Tenant.Users.UserTab.ConfirmDeleteUser"],
                    "mode": "inline",
                    "update": true,
                    "destroy": true
                },
                "toolbar": [{ "name": 'create', "buttonType": "ImageAndText", "text": this.trans["Tenant.Users.UserTab.NewUser"] }],

                //4. Events
                "dataBound": function (e) {
                    var grid = isUserLicense ? that.userGrid : that.partnerGrid;
                    if (that.selectedUser) that.highlightRow(grid, that.selectedUser);
                },
                "save": function (e) {
                    var grid = isUserLicense ? that.userGrid : that.partnerGrid;
                    that.onSelectItem(e, grid);
                    that.groupGrid.dataSource.read();
                },
            }
        };

        private getGroupGridOptions(): kendo.ui.GridOptions {
            var that = this;
            return {
                //1. Datasource
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.UserManagement.GroupsUrl, this.authService.getBearerTokenObject()),
                        "update": this.appConfig.helper.http('put', this.appConfig.request.UserManagement.GroupsUrl, this.authService.getBearerTokenObject()),
                        "create": this.appConfig.helper.http('post', this.appConfig.request.UserManagement.GroupsUrl, this.authService.getBearerTokenObject()),
                        "destroy": this.appConfig.helper.http('delete', this.appConfig.request.UserManagement.GroupsUrl, this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 50,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "serverGrouping": true,
                    "serverAggregates": true,
                    "sort": [{ "field": "Name", "dir": "asc" }],
                    "error": function (e:any) {
                        that.authService.kendoError(e, that.groupGrid);
                    },
                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "string" },
                                "Name": { "type": "string" },
                                "IsInternal": { "type": "boolean", "defaultValue": true },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",
                    }

                },

                //2. Columns
                "columns": [
                    {
                        "field": "Name",
                        "title": "{{'Tenant.Users.GroupTab.GroupName' | translate}}",
                    }, {
                        "field": "IsInternal",
                        "title": "{{'Tenant.Users.GroupTab.IsInternal' | translate}}",
                        "template": "#= IsInternal ? '" + that.trans["Tenant.Users.UserTab.FullLicense"] + "' : '" + that.trans["Tenant.Users.UserTab.LightLicense"] + "' #",
                        "editor": function (container: JQuery, options: any) {
                            var data = [
                                { text: that.trans["Tenant.Users.UserTab.FullLicense"], value: true },
                                { text: that.trans["Tenant.Users.UserTab.LightLicense"], value: false },
                            ];
                            $('<input name="' + options.field + '" data-text-field="text" data-value-field="value" data-bind="value:' + options.field + '"/>')
                                .appendTo(container)
                                .kendoDropDownList({
                                    dataSource: data,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    index: options.model.IsInternal ? 0 : 1
                                });

                        }
                        ,
                    }, {
                        "width": 200,
                        "command": [
                            { "name": "edit" },
                            { "name": "destroy" }
                        ]
                    }
                ],

                //3. Features
                "sortable": true,
                "scrollable": false,
                "editable": {
                    "confirmation": "Sind Sie sicher, dass Sie diese Gruppe löschen wollen? Die enthaltenen Benutzer werden nicht gelöscht.", //ToDo: translation
                    "confirmDelete": "Löschen",
                    "cancelDelete": "Abbrechen",
                    "mode": "inline",
                    "update": true,
                    "destroy": true
                },
                "toolbar": [{ "name": 'create', "buttonType": "ImageAndText", "text": "Neue Gruppe erstellen" }], //ToDo: translation


                //4. Events
                edit: function (e:any) {
                    if (e.model.isNew() === false) {
                        $("input[name=IsInternal]").data("kendoDropDownList").enable(false);
                    }
                },
                save: function () {
                    that.userGrid.dataSource.read();
                    that.partnerGrid.dataSource.read();
                }

            }
        };


    }


    export class AppUsersComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            trans: '<',
            language: '<',
        };

        controller = AppUsersController;
        templateUrl = "/AdminTemplate/UsersTemplate";
    }

    angular.module("app").component("appUsers", new AppUsersComponent());
}

