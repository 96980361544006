module App {
    export class CoreDataService {
        public static Factory($http: ng.IHttpService,
            $q: ng.IQService,
            $filter: ng.IFilterService,
            $translate: angular.translate.ITranslateService,
            appConfig: AppConfig) {
            return new CoreDataService($http, $q, $filter, $translate, appConfig);
        }

        constructor(
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private $filter: ng.IFilterService,
            private $translate: angular.translate.ITranslateService,
            private appConfig: AppConfig) {

        }

        //PROPS
        private enums: { [enumName: string]: { [enumValue: string]: string } } = {};


        // PUBLIC
        // 1. load enum description object from server,
        // 2. store it in cache
        // 3. Generate a string[] of Translation Keys
        // 4. Generate a literal object whose key is the enum key and value is the translation key
        enumToList(entity: string): ng.IPromise<ITextValue[]> {
            var list: ITextValue[] = [];

            return this.loadEnums()
                .then(enums => this.$translate(this.enumTextList(entity)))
                .then(trans => {
                    angular.forEach(this.enums[entity], (val, key) => {
                        list.push({ value: parseInt(key), text: trans[this.transKey(entity, val)] });
                    });
                    return list;
                // acc to docs, if error occurs it is also a trannslate object
                }, trans => {
                    angular.forEach(this.enums[entity], (val, key) => {
                        list.push({ value: key, text: trans[this.transKey(entity, val)] });
                    });
                    return list;
                });

        }


        customEditor(opt: ICustomEditorOptions) {
            var req = opt.isrequired ? ' required ' : ' ';
            return (container:any, options:any) => {
                $('<input id="' + options.field + '" name="' + options.field + '" data-bind="value:' + options.field + '" ' + req + '/>')
                    .appendTo(container)
                    .kendoDropDownList({
                        autoBind: true,
                        dataSource: opt.data,
                        dataTextField: 'text',
                        dataValueField: opt.withoutValue ? 'text' : 'value',
                        filter: "contains",
                        valuePrimitive: true,
                        optionLabel: opt.optionlabel,
                        cascadeFrom: opt.cascade,
                        cascadeFromField: opt.cascade
                    });

                $('<span class="k-invalid-msg" data-for="' + options.field + '"></span>').appendTo(container);

            };
        }


        // PRIVATE
        private loadEnums(): ng.IPromise<IEnumViewModel> {
            var deferred = this.$q.defer<IEnumViewModel>();
            if (Object.keys(this.enums).length) {
                deferred.resolve(this.enums);
            } else {
                this.appConfig.request.CoreData.Enums(this.$http).then(okResponse => {
                    this.enums = okResponse.data || {};
                    deferred.resolve(this.enums);
                }, errResponse => {
                    console.error(errResponse);
                    deferred.reject(errResponse);
                });
            }
            return deferred.promise;
        }


        private enumTextList(entity:string): string[] {
            var ret: string[] = [];
            angular.forEach(this.enums[entity], val => {
                    ret.push(this.transKey(entity, val));
                });
            return ret;
        }


        private transKey(entity:string, val:string): string {
            return 'Core.Enum.' + entity + '.' + val;
        }

    }

    angular.module("app").service("coreDataService", ['$http', '$q', '$filter', '$translate', 'appConfig', CoreDataService.Factory]);
}