module App {
    export class AppConversationController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        userId: string;

        //compute
        newText: "";
        conversation: IMessageViewModel[];
        intervalPromise: ng.IPromise<any>;
        private numCount = 0;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$interval', '$scope', '$http', 'appConfig', 'helperService', 'conversationService'];
        constructor(
            private $interval: ng.IIntervalService,
            private $scope: ng.IScope,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private helperService: HelperService,
            private conversationService: ConversationService,
        ) {
        }

        $onInit(): void {
            if (this.userId) {
                this.getConversation();
                this.intervalPromise = this.$interval((i:any)=> this.getConversation(), 10000);
            } else {
                this.helperService.alert("Conversation invalid. Unknown user.", false);
            }

            this.$scope.$on('$destroy', () => {
                this.$interval.cancel(this.intervalPromise);
            });


        }
        // ========================================
        // PUBLIC
        // ========================================
        getConversation() {
            this.appConfig.request.Message.GetConversation(this.$http, this.userId).then(okResponse => {
                this.numCount++;
                this.conversation = okResponse.data;
                if (this.numCount ===1) this.conversationService.loadConversations();
            }, errResponse => {
                console.error(errResponse);
            });
        }

        sendMessage () {
            if (!this.newText) return;
            var newObject:IMessageViewModel = {
                Text: this.newText,
                IsMe: true,
            }

            this.conversation.push(newObject);

            this.appConfig.request.Message.SendMessage(this.$http, this.newText, this.userId).then(okResponse => {
                this.newText = "";
                newObject.Id = okResponse.data.Id;
                newObject.SendDate = okResponse.data.SendDate;

            }, errResponse => {
                console.error(errResponse);
            });
        }

        deleteMessage(message: IMessageViewModel) {
            const index = this.conversation.indexOf(message);
            this.conversation.splice(index, 1);
            this.appConfig.request.Message.DeleteMessage(this.$http, message.Id).then(okResponse => {

            }, errResponse => {
                console.error(errResponse);
            });
        }
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppConversationComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            userId: '<',
        };

        controller = AppConversationController;
        templateUrl="/UserTemplate/ConversationTemplate";
    }

    angular.module("app").component("appConversation", new AppConversationComponent());
}

