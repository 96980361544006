module App {
    export class AppPublicController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        shareId: string;
        folderId: number;
        fileId: string | null;

        //compute
        myPermissions = {};
        data: IFolderControlViewModel;
        file: IFileViewModel;
        view = {
            showUpNav: false,
            showAllNav: false,
            showPinInput: false,
            pinFailed: false,
            newPin: "",
        }

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$http', '$sce', 'appConfig', 'authService', '$window', 'filesystemService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $http: ng.IHttpService,
            private $sce: ng.ISCEService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private $window: ng.IWindowService,
            private filesystemService: FilesystemService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            this.getFolder(this.shareId, this.folderId);
        }

        // ========================================
        // PUBLIC
        // ========================================
        setViewOptions() {
            this.view.showUpNav = this.data && this.data.Breadcrumb && this.data.Breadcrumb.length > 1 && !this.data.IsRootFolder;
            this.view.showAllNav = false;
        }


        getMyPermissions(folderId: number) {
            this.appConfig.request.Folder.GetMyPermissions(this.$http, folderId).then(okResponse => {
                this.myPermissions = okResponse.data;
            }, e => this.helperService.error(e));

        }

        getFolder(shareId: string, folderId: number) {
            this.filesystemService.getPublic(shareId, folderId, this.currentUser.publicPin, data => {
                this.view.showPinInput = false;
                this.view.pinFailed = false;
                if (this.fileId) {
                    const f = data.ContainedFiles.filter(i => i.Id.toString() === this.fileId);
                    data.ContainedFolders = [];
                    data.ContainedFiles = f;
                    this.file = f.length? f[0] : null;
                    this.data = data;
                } else {
                    this.data = data;
                }
                this.data.CurrentFolder.Design.Text2 = this.$sce.trustAsHtml(data.CurrentFolder.Design.Text);


                if (this.currentUser.isAuthenticated) this.getMyPermissions(data.CurrentFolder.Id);
                this.setViewOptions();


            }, (data:any) => {
                this.data = null;
                console.error(data);
                if (data.Exception === "PinRequiredException") {
                    this.view.showPinInput = true;
                    if (this.view.newPin.length) this.view.pinFailed = true;
                    this.view.newPin = "";
                } else {
                    this.helperService.error(data);
                    this.$window.history.back();
                }
            });

        };


        refresh() {
            this.currentUser.publicPin = this.view.newPin;
            this.getFolder(this.shareId, this.folderId);
        }


        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppPublicComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            shareId: '<',
            folderId: '<',
            fileId: '<',
        };

        controller = AppPublicController;
        templateUrl="/GuestTemplate/PublicTemplate";
    }

    angular.module("app").component("appPublic", new AppPublicComponent());
}

