module App {
    export class AppSystemsettingsController implements ng.IComponentController {
        //binding

        //compute
        data: ITenantDetailViewModel;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', '$scope', '$timeout', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService'];
        constructor(
            private $http: ng.IHttpService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void {
            this.getData();
        }
        // ========================================
        // PUBLIC
        // ========================================
        getData() {
            this.appConfig.request.CompanyManagement.GetSettings(this.$http).then(okResponse => {
                this.data = okResponse.data;
                this.data.Max = 0;
                if (isFinite(this.data.ManagementLimit)) this.data.Max = Math.max(this.data.Max, this.data.ManagementLimit);
                if (isFinite(this.data.DefaultLimit)) this.data.Max = Math.max(this.data.Max, this.data.DefaultLimit);
            }, e=> this.helperService.error(e));

        };


        setQuota() {
            var defaultInput = Math.round(this.data.TenantLimit / 1024 / 1024 / 1024 * 100) / 100;
            this.helperService.prompt('Tenant.Systemsettings.SetGbMsg', defaultInput.toString(), data => {
                //JavaScript Parser needs Dot
                data = data.replace(",", ".");
                let floatData = parseFloat(data);

                if (!isFinite(floatData)) {
                    this.helperService.alert('Tenant.Systemsettings.NotNumberErr', true);
                    return;
                }

                if (floatData > this.data.Max / 1024 / 1024 / 1024) {
                    this.helperService.alert('Tenant.Systemsettings.OverLimitErr', true);
                    return;
                }

                //.net Parser needs Comma
                this.appConfig.request.CompanyManagement.SetMemoryLimit(this.$http, data.replace(".", ",")).then(okResponse => {
                    this.getData();
                }, (errResponse:any) => {
                    this.helperService.error(errResponse);
                    this.getData();
                });

            }, true);
        };


        setRetention() {
            this.helperService.prompt('Tenant.Systemsettings.SetQuarantineErr', this.data.RetentionTimeInMonths.toString(), data => {
                if (!parseInt(data)) {
                    this.helperService.alert('Tenant.Systemsettings.NoIntegerErr', true);
                    return;
                }

                if (parseInt(data) < 0) {
                    this.helperService.alert('Tenant.Systemsettings.NegativeErr', true);
                    return;
                }

                this.appConfig.request.CompanyManagement.SetRetentionTime(this.$http, parseInt(data)).then(okResponse => {
                    this.getData();
                }, errResponse => {
                    this.helperService.error(errResponse);
                    this.getData();
                });
            }, true);
        };

        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppSystemsettingsComponent implements ng.IComponentOptions {

        controller = AppSystemsettingsController;
        templateUrl = "/AdminTemplate/SystemsettingsTemplate";
    }

    angular.module("app").component("appSystemsettings", new AppSystemsettingsComponent());
}


