module App {
    export class AppDatabaseController implements ng.IComponentController {
        //binding
        tenantId: number;

        //compute
        missingGrid: kendo.ui.Grid;
        missingGridOptions: kendo.ui.GridOptions;
        orphanGrid: kendo.ui.Grid;
        orphanGridOptions: kendo.ui.GridOptions;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', '$scope', '$timeout', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService'];
        constructor(
            private $http: ng.IHttpService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void {
            this.orphanGridOptions = this.getOrphanGridOptions();
            this.missingGridOptions = this.getMissingGridOptions();
        }
        // ========================================
        // PUBLIC
        // ========================================




        // ORPHAN FILES
        // ============

        // ========================================
        // PRIVATE
        // ========================================
        private getOrphanGridOptions(): kendo.ui.GridOptions {
            var that = this;
            return {
                // 1. Datasource
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.TenantManagement.MissingInDatabaseUrl(this.tenantId), this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 20,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "sort": { "field": "Name", "dir": "asc" },
                    "filter": [],
                    "error": function(e:any) {
                        that.authService.kendoError(e, that.orphanGrid);
                    },
                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "number" },
                                "Name": { "type": "string" },
                                "SizeBytes": { "type": "number" },
                                "Date": { "type": "date" },
                                "UserName": { "type": "string" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors"

                    }

                },

                // 2. Columns
                "columns": [
                    {
                        "field": "Name",
                        "title": "{{'Datarooms.Database.Name' | translate}}",
                        "filterable": true
                    }, {
                        "field": "Date",
                        "title": "{{'Datarooms.Database.Date' | translate}}",
                        "format": "{0:g}",
                        "filterable": true
                    }, {
                        "field": "SizeBytes",
                        "title": "{{'Datarooms.Database.SizeBytes' | translate}}",
                        "filterable": true
                    }, {
                        "width": 200,
                        "command": [
                            {
                                "name": "remove",
                                "text": "{{'Datarooms.Database.Remove' | translate}}",
                                "click": function(e:any) {
                                    e.preventDefault();
                                    let dataItem = that.orphanGrid.dataItem(that.orphanGrid.select());
                                    //let dataItem = this.dataItem($(e.currentTarget).closest("tr"));
                                    that.helperService.confirm('Datarooms.Database.ConfirmHeader', "Datarooms.Database.ConfirmText", ()=> {
                                        that.appConfig.request.TenantManagement.FixStorage(that.$http, that.tenantId, dataItem.get("Name")).then(okResponse => {
                                            that.orphanGrid.dataSource.read();
                                        }, er=> that.helperService.error(er));

                                    }, err=> that.helperService.error(err), true);
                                }
                            }
                        ]
                    }
                ],

                // 3. Features
                "pageable": true,
                "sortable": true,
                "scrollable": false,
                "filterable": {
                    operators: this.localeService.getKendoFilterTranslationObject()

                },

            }
        };


        private getMissingGridOptions(): kendo.ui.GridOptions {
            const that = this;
            return {
                // 1. Datasource
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.TenantManagement.MissingInStorageUrl(this.tenantId), this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 20,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "sort": { "field": "Name", "dir": "asc" },
                    "filter": [],
                    "error": function(e: any) {
                        that.authService.kendoError(e, that.missingGrid);
                    },

                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "number" },
                                "Name": { "type": "string" },
                                "SizeBytes": { "type": "number" },
                                "Date": { "type": "date" },
                                "UserName": { "type": "string" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors"

                    }

                },

                // 2. Columns
                "columns": [
                    {
                        "field": "Name",
                        "title": "{{'Datarooms.Database.Name' | translate}}",
                        "filterable": true
                    }, {
                        "field": "Date",
                        "title": "{{'Datarooms.Database.Date' | translate}}",
                        "format": "{0:g}",
                        "filterable": true
                    }, {
                        "field": "SizeBytes",
                        "title": "{{'Datarooms.Database.SizeBytes' | translate}}",
                        "filterable": true
                    }, {
                        "width": 200,
                        "command": [
                            {
                                "name": "remove",
                                "text": "{{'Datarooms.Database.Remove' | translate}}",
                                "click": function(e: Event) {
                                    e.preventDefault();
                                    let dataItem = that.missingGrid.dataItem(that.missingGrid.select());
                                     //let dataItem = this.dataItem($(e.currentTarget).closest("tr"));
                                    that.helperService.confirm('Datarooms.Database.ConfirmHeader', "Datarooms.Database.ConfirmText", () => {
                                        that.appConfig.request.TenantManagement.FixDatabase(that.$http, that.tenantId, dataItem.get("UserName")).then(okResponse => {
                                            that.missingGrid.dataSource.read();
                                        }, er=> that.helperService.error(er));
                                    }, err => that.helperService.error(err), true);
                                }
                            }
                        ]
                    }
                ],

                // 3. Features
                "pageable": true,
                "sortable": true,
                "scrollable": false,
                "filterable": {
                    operators: this.localeService.getKendoFilterTranslationObject()

                },

            }
        };
    }


    export class AppDatabaseComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            tenantId: '<'
        };

        controller = AppDatabaseController;
        templateUrl = "/AdminTemplate/DatabaseTemplate";
    }

    angular.module("app").component("appDatabase", new AppDatabaseComponent());
}


