module App {
    export interface IAppFlowFile extends flowjs.IFlowFile {
        overwrite: boolean | null;
        folderId: number;
        baseFolderId: number;
        errormessage: string;
        isFinished: boolean;
        folderName: string;
        currentProgress: number;
        relativePath: string;
        name: string;
        file: File;
        cancel(): void;
    }

    export interface IUploadPrepareData {
        files: IAppFlowFile[];
        currentFile: IAppFlowFile | null;
    }

    export interface IUploadPrepareConfig {
        successCallback: Function;
        supportDirectory: boolean;
    }

    export class UploadPrepareService {
        public static Factory($http: ng.IHttpService,
            $q: ng.IQService,
            $translate: angular.translate.ITranslateService,
            appConfig: AppConfig,
            conflictService: ConflictService,
            authService: AuthService) {
            return new UploadPrepareService($http, $q, $translate, appConfig, conflictService, authService);
        }

        constructor(
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private $translate: angular.translate.ITranslateService,
            private appConfig: AppConfig,
            private conflictService: ConflictService,
            private authService: AuthService) {
            $translate([
                "Datarooms.UploadPrepare.OtherUser",
                "Datarooms.UploadPrepare.FileExist",
            ]).then((t:any) => {
                this.trans.OtherUser = t["Datarooms.UploadPrepare.OtherUser"];
                this.trans.FileExist = t["Datarooms.UploadPrepare.FileExist"];
            });
        }

        // PROPS

        Data: IUploadPrepareData = { files: [], currentFile: null };

        Config: IUploadPrepareConfig = { successCallback: null, supportDirectory: false };

        private trans = { OtherUser: "", FileExist:""};
        private currentUser: IApplicationUserClientStatusViewModel;

        // PUBLIC
        add(newFile: IAppFlowFile) {
            this.Data.files.push(newFile);
            this.tryRun();
        };

        // PRIVATE


        // OTHERS


        // promise
        private subFolderCheck(file: IAppFlowFile): ng.IPromise<IAppFlowFile> {
            var deferred = this.$q.defer<IAppFlowFile>();

            if (isFinite(file.folderId)) {
                deferred.resolve(file);
            } else {
                if (this.Config.supportDirectory) {
                    var onlyFolder = file.relativePath.replace(file.file.name, "").trim();
                    if (onlyFolder.length > 0) {
                        this.appConfig.request.Folder.GetRelativeFolder(this.$http, file.baseFolderId, onlyFolder)
                            .then(okResponse => {
                                    //overwrite target folder
                                    file.folderId = okResponse.data ? okResponse.data : 0;
                                    deferred.resolve(file);
                                },
                                errResponse => {
                                    console.error(errResponse);
                                    deferred.reject("Query for relative subfolder has failed.");
                                });

                    } else {
                        file.folderId = file.baseFolderId;
                        deferred.resolve(file);
                    }
                } else {
                    file.folderId = file.baseFolderId;
                    deferred.resolve(file);
                }
            }
            return deferred.promise;
        }

        fileIsUploading(file: IAppFlowFile): ng.IPromise<IAppFlowFile> {
            var deferred = this.$q.defer<IAppFlowFile>();
            if (file.overwrite) {
                deferred.resolve(file);
            } else {
                this.appConfig.request.Folder.FileIsUploading(this.$http, file.folderId, file.name)
                    .then(okResponse=> {
                        if (okResponse.data && okResponse.data !== this.currentUser.username) {
                            // hand over to conflict service
                            file.errormessage = this.trans.OtherUser;
                            this.conflictService.add(file, ConflictType.otherUser, () => {
                                file.errormessage = "";
                                file.overwrite = true;
                                this.add(file);
                            });
                            this.Data.currentFile = null;
                            deferred.reject("New Conflict: Upload of another user in progress.");
                        } else {
                            deferred.resolve(file);
                        }
                    });
            }
            return deferred.promise;
        };

        private fileExists(file: IAppFlowFile): ng.IPromise<IAppFlowFile> {
            var deferred = this.$q.defer<IAppFlowFile>();
            if (file.overwrite || file.overwrite === false) {
                deferred.resolve(file);
            } else {
                this.appConfig.request.Folder.FileExists(this.$http, file.folderId, file.name)
                    .then(okResponse => {
                            if (okResponse.data) {
                                // hand over to conflice service
                                file.errormessage = this.trans.FileExist;
                                this.conflictService.add(file, ConflictType.fileExists, ()=> {
                                    file.errormessage = "";
                                    file.overwrite = true;
                                    this.add(file);
                                });
                                this.Data.currentFile = null;
                                deferred.reject("New Conflict: File already exists.");
                            } else {
                                file.overwrite = false;
                                deferred.resolve(file);
                            }

                        },
                        errRespose => {
                            console.error(errRespose);
                            deferred.reject();
                        });
            }
            return deferred.promise;
        }


        private processFile() {
            var file = this.Data.currentFile;
            if (file === null) this.$q.reject("No file to process");

            return this.subFolderCheck(file as IAppFlowFile)
                //has the folder id got to be modified because of subfolder uploads?
                .then(fileWithModifiedFolderId => this.fileIsUploading(fileWithModifiedFolderId))

                // is there already a running upload?
                .then(fileWithValidatedUser => this.fileExists(fileWithValidatedUser))

                // is there an old existing file?
                .then(fileToUpload => {
                    this.Data.currentFile = null;
                    if (angular.isFunction(this.Config.successCallback)) this.Config.successCallback(fileToUpload);
                    this.tryRun();
                })
                .catch((error:any) => {
                    // error files should not be lost, therefore back in the queue at the end
                    if (this.Data.currentFile) {this.conflictService.add(this.Data.currentFile, ConflictType.otherError, () => {
                        file.errormessage = "";
                        // file.overwrite = true;
                        this.add(file);
                    });}
                    this.Data.currentFile = null;
                    console.error(error);
                    this.tryRun();
                });

        }

        // manage array data.files and launch processing
        private tryRun() {
            if (this.Data.files.length === 0) {return;}
            if (this.Data.currentFile !== null) {return;}
            this.Data.currentFile = this.Data.files.shift();
            this.authService.init(false, false).then((cu:IApplicationUserClientStatusViewModel) => {
                this.currentUser = cu;
                this.processFile();
            });
        }




    }

    angular.module('app').factory('uploadPrepareService', ['$http', '$q', '$translate', 'appConfig', 'conflictService',
        'authService', UploadPrepareService.Factory]);
}