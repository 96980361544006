module App {
    export class PasswordWindowController implements ng.IComponentController {
        //binding
        tenant: ITenantViewModel;
        visible: number;
        pass = {
            new1: "",
            new2: ""
        }

        //compute
        ResetPasswordWindow: kendo.ui.Window;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void { }

        $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj['visible'] && onChangesObj['visible'].currentValue===1) this.openPasswordWindow();
        }

        // ========================================
        // PUBLIC
        // ========================================
        openPasswordWindow() {
            this.ResetPasswordWindow.center().open();
        };


        submitPasswordChange() {
            this.helperService.alert("Direct Password set is not supported any more", false, null);
            //this.appConfig.request.TenantManagement.ResetPassword(this.$http, this.tenant.Id, this.pass.new1, this.pass.new2).then(okResponse=> {
            //    this.closePasswordWindow();
            //    this.helperService.alert('Manager.PasswordWindow.Success', true);
            //}, errResponse => {
            //    this.helperService.error(errResponse);
            //    this.closePasswordWindow();
            //});

        }

        closePasswordWindow() {
            this.ResetPasswordWindow.center().close();
            this.pass.new1 = "";
            this.pass.new2 = "";
            this.visible = 0;
        }

      

        // ========================================
        // PRIVATE
        // ========================================

    }


    export class PasswordWindowComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            tenant: '=',
            visible: '=',
        };

        controller = PasswordWindowController;
        //templateUrl='';
        template = 
            '<div kendo-window="$ctrl.ResetPasswordWindow" k-title="\'Passwort neu setzen\'" k-visible="false" k-modal="true" k-draggable="true" k-width="300">' +
                '<dl>' +
                '<dt><label translate>Manager.PasswordWindow.Tenant</label></dt>' +
                '<dd>{{$ctrl.tenant.Name}}</dd>' +
                '<dt><label translate>Manager.PasswordWindow.AdminUser</label></dt>' +
                '<dd>{{$ctrl.tenant.AdminUser}}</dd>' +
                '</dl>' +

                '<dl>' +
                '<dt><label for="NewPass1" translate>Manager.PasswordWindow.NewPassword</label></dt>' +
                '<dd><input type="password" class="k-textbox" ng-model="$ctrl.pass.new1" /></dd>' +
                '</dl>' +

                '<dl>' +
                '<dt><label for="NewPass2" translate>Manager.PasswordWindow.ConfirmPassword</label></dt>' +
                '<dd><input type="password" class="k-textbox" ng-model="$ctrl.pass.new2"/></dd>' +
                '</dl>' +
                '<button class="btn btn-primary" ng-click="$ctrl.submitPasswordChange()" translate>Core.Buttons.Ok</button>' +
                '<button class="btn btn-default" ng-click="$ctrl.closePasswordWindow()" translate>Core.Buttons.Cancel</button>' +

                '</div>'
        ;
    }

    angular.module("app").component("passwordWindow", new PasswordWindowComponent());
}
