module App {
    export class FullscreenManagerController implements ng.IComponentController {
        isReady = false;

        static $inject: string[] = ['authService', 'fullscreenService'];
        constructor(
            private authService: AuthService,
            private fullscreenService: FullscreenService, ) { }

        $onInit(): void {
            this.fullscreenService.registerOnChange(isFullscreenEnabled => {
                this.isReady = isFullscreenEnabled;
            });
        }

    }


    export class FullscreenManagerComponent implements ng.IComponentOptions {
        controller = FullscreenManagerController;
        template = `
            <div ng-if="$ctrl.authService.data.isAuthenticated">
                <app-search ng-if="$ctrl.isReady" current-user="$ctrl.fullscreenService.currentUser"></app-search>
            </div>
        `;

    }

    angular.module("app").component("fullscreenManager", new FullscreenManagerComponent());
}