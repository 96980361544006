module App {
    export class TenantDetailsController implements ng.IComponentController {
        //binding
        tenant: ITenantViewModel;

        //compute
        data: ITenantDetailViewModel;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            this.getData();
        }
        // ========================================
        // PUBLIC
        // ========================================
        getData() {
            this.appConfig.request.TenantManagement.Details(this.$http, this.tenant.Id).then(okResponse => {
                this.data = okResponse.data;
            }, e=> this.helperService.error(e));

        };

        activateAndHail () {
            if (this.tenant.IsEnabled) { return; }
            if (!this.tenant.AdminEmail) { return; }

            this.helperService.prompt("Manager.Tenant.ConfirmPrompt", "", result => {
                    if (result && result === "12345678") {
                        this.appConfig.request.TenantManagement
                            .RegeneratePassword(this.$http, this.tenant.Id, "12345678", "12345678", true)
                            .then(() => {
                                    this.helperService.alert("Manager.Tenant.ActivateSuccess", true);
                                    this.getData();
                                },
                                () => {
                                    this.helperService.alert("Manager.Tenant.ConfirmFail", true);
                                });
                    } else {
                        this.helperService.alert("Manager.Tenant.ConfirmInvalid", true);
                    }
                }, true);
        }

        randomPass() {
            if (!this.tenant.AdminEmail) { return; }
            this.helperService.prompt("Manager.Tenant.ConfirmPrompt", "", result => {
                    if (result && result === "12345678") {
                        this.appConfig.request.TenantManagement
                            .RegeneratePassword(this.$http, this.tenant.Id, "12345678", "12345678", false)
                            .then(() => {
                                    this.helperService.alert("Manager.Tenant.ResetSuccess", true);
                                    this.getData();
                                },
                                () => {
                                    this.helperService.alert("Manager.Tenant.ConfirmFail", true);
                                });
                    } else {
                        this.helperService.alert("Manager.Tenant.ConfirmInvalid", true);
                    }
                }, true);

        }

        disableTwoFactor() {
            if (this.tenant.IsAuthenticatorEnabled) {
                this.helperService.confirm("Manager.Tenant.ConfirmTitle", "Manager.Tenant.ResetTwoFactorPrompt", () => {
                    this.appConfig.request.TenantManagement.DisableTwoFactor(this.$http, this.tenant.Id).then(okResult => {
                        if (okResult.data) {
                            this.helperService.alert("Manager.Tenant.ResetTwoFactorSuccess", true);
                            this.tenant.IsAuthenticatorEnabled = false;
                        } else {
                            this.helperService.alert("Manager.Tenant.ResetTwoFactorFail", true);
                        }

                    }, errResult => {
                        this.helperService.alert("Manager.Tenant.ResetTwoFactorFail", true);
                    });
                    this.getData();
                }, null, true);
            } else {
                this.helperService.alert("Manager.Tenant.ResetTwoFactorInvalid", true);
            }
        }


        setQuota () {
            const defaultValue = isFinite(this.data.ManagementLimit) ? (Math.round(this.data.ManagementLimit / 1024 / 1024 / 1024 * 100) / 100).toString() : "";

            this.helperService.prompt("Manager.Tenant.MemoryLimitPrompt", defaultValue, data => {
                //JavaScript Parser needs Dot
                data = data.replace(",", ".");

                if (!isFinite(parseFloat(data))) {
                    this.helperService.alert("Manager.Tenant.MemoryLimitInvalid", true);
                    return;
                }

                this.appConfig.request.TenantManagement.SetMemoryLimit(this.$http, this.tenant.Id, data.replace(".", ",")).then(() => {
                    this.getData();
                }, () => {
                    this.getData();
                });

            }, true);

        };

        enableAdminUser () {
            if (this.tenant.AdminIsEnabled) {
                this.helperService.alert("Manager.Tenant.ReEnableInvalid", true);
                return;
            }
            this.helperService.confirm("Manager.Tenant.ConfirmTitle", "Manager.Tenant.ConfirmTitle", () => {
                this.appConfig.request.TenantManagement.EnableAdminUser(this.$http, this.tenant.Id).then(okResponse => {
                    this.tenant.AdminIsEnabled = true;
                    this.getData();
                }, () => {
                    this.getData();
                });
            }, null, true);
        }

        setLicenseLimit() {
            this.helperService.prompt("Manager.Tenant.FullLicensePrompt", this.data.FullLicenseLimit.toString(), fullCount => {
                if (!isFinite(parseInt(fullCount))) {
                    this.helperService.alert("Manager.Tenant.LicenseInvalid", true);
                    return;
                }

                this.helperService.prompt("Manager.Tenant.LightLicensePrompt", this.data.LightLicenseLimit.toString(), lightCount => {
                    if (!isFinite(parseInt(lightCount))) {
                        this.helperService.alert("Manager.Tenant.LicenseInvalid", true);
                        return;
                    }
                    this.appConfig.request.TenantManagement.SetLicenseLimit(this.$http, this.tenant.Id, fullCount, lightCount).then(() => {
                        this.getData();
                    }, errResponse => {
                        this.helperService.error(errResponse);
                        this.getData();
                    });

                }, true);

            }, true);
        }

        cleanup() {
            this.helperService.confirm("Manager.Tenant.ConfirmTitle", "Manager.Tenant.CleanupConfirm", () => {
                this.appConfig.request.TenantManagement.Cleanup(this.$http, this.tenant.Id).then(okResponse => {
                    this.getData();
                }, errResponse => {
                    this.getData();
                });
            }, null, true);
        }
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class TenantDetailsComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            tenant: '<'
        };

        controller = TenantDetailsController;
        template=
            '<div class="row">' +
                '<div class="col-md-5">' +
                    '<h4 translate>Manager.Tenant.License</h4>'+
                    '<p translate translate-values="{id: $ctrl.data.CompanyId}">Manager.Tenant.CompanyId</p>' +
                    '<p translate translate-values="{count: $ctrl.data.FullLicenseCount, limit: $ctrl.data.FullLicenseLimit, active:$ctrl.data.ActiveUsers}">Manager.Tenant.UserLicenses</p>' +
                    '<p translate translate-values="{count: $ctrl.data.LightLicenseCount, limit: $ctrl.data.LightLicenseLimit}">Manager.Tenant.LightLicenses</p>' +
                    '<p class="btn btn-default" ng-click="$ctrl.setLicenseLimit()" translate>Manager.Tenant.SetLicenses</p>' +

                    '<p><span translate>Manager.Tenant.DefaultMemory</span> {{$ctrl.data.DefaultLimit | byteReadable}}</p>' +
                    '<p><span translate>Manager.Tenant.ManagerMemory</span> <strong>{{$ctrl.data.ManagementLimit || "-" | byteReadable}}</strong></p>' +
                    '<p><span translate>Manager.Tenant.AdminMemory</span> {{$ctrl.data.TenantLimit || "-" | byteReadable}}</p>' +
                    '<p class="btn btn-default" ng-click="$ctrl.setQuota()" translate>Manager.Tenant.SetMemory</p>' +
                '</div>' +
                '<div class="col-md-5">' +
                    '<h4 translate>Manager.Tenant.Usage</h4>' +
                    '<p><span translate>Manager.Tenant.LastLogin</span> {{$ctrl.tenant.LastLogon | aspDate: "short"}}</p>' +
                    '<p><span translate>Manager.Tenant.LoginCount</span> {{$ctrl.data.LoginCount}}</p>' +
                    '<p><span translate>Manager.Tenant.MemoryUsage</span> {{$ctrl.data.InUse | byteReadable}} ({{$ctrl.data.Percent | percent:1}})</p>' +
                    '<p><span translate>Manager.Tenant.Quarantine</span> {{$ctrl.data.RetentionTimeInMonths}} Monate</p>' +
                    '<p class="btn btn-default" ng-click="$ctrl.cleanup()" translate>Manager.Tenant.Cleanup</p>' +


                '</div>' +
            '</div>' +
            '<hr/>' +
            '<p><strong translate>Manager.Tenant.AdminSupport</strong></p>'+
            '<span class="btn btn-success" ng-click="$ctrl.activateAndHail()" ng-disabled="$ctrl.tenant.IsEnabled || !$ctrl.tenant.AdminEmail.length" translate>Manager.Tenant.ActivateUser</span>' +
            '<span class="btn btn-warning" ng-click="$ctrl.randomPass()" ng-disabled="!$ctrl.tenant.AdminEmail.length" translate>Manager.Tenant.SendRandomPass</span>' +

            '<span class="btn btn-default" ng-click="$ctrl.disableTwoFactor()" ng-disabled="!$ctrl.tenant.IsAuthenticatorEnabled" translate>Manager.Tenant.ResetTwoFactor</span>' +
            '<span class="btn btn-default" ng-click="$ctrl.enableAdminUser()" ng-disabled="$ctrl.tenant.AdminIsEnabled" translate>Manager.Tenant.ReEnableUser</span>' +
            '<a class="btn btn-default" ui-sref="database({tenantId: $ctrl.tenant.Id})" translate>Manager.Tenant.CheckDb</a>'
        ;

    }

    angular.module("app").component("tenantDetails", new TenantDetailsComponent());
}

