module App {
    export class AppFolderSecurityController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        folder: IFolderControlViewModel;
        inheritedPermissions: ISharePermissionViewModel;
        myPermissions: ICurrentPermissionViewModel;

        //compute
        allPermissions: ISharePermissionViewModel;
        permissionMessage: string;
        callback: Function;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = [
            '$scope', '$state', '$timeout', '$translate', '$http', '$q', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService', 'filesystemService'
        ];

        constructor(
            private $scope: ng.IScope,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private $translate: angular.translate.ITranslateService,
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService,
            private filesystemService: FilesystemService
        ) {
        }

        $onInit(): void {
            if (this.folder.IsRootFolder) {
                this.helperService.alert('Datarooms.FolderSecurity.NoDriveEditMsg', true);
                this.$state.go('');
            } else {
                this.getPermissions(this.folder.CurrentFolder.Id);
                this.callback = this.createCallback();
            }
        }

        // ========================================
        // PUBLIC
        // ========================================
        addPermission(auth: IPermissionDefinitionViewModel) {
            if (!auth.IsFullLicense && this.allPermissions.Level === 4) return;

            const promise = auth.IsUser
                ? this.appConfig.request.Folder.SetUserPermission(this.$http, this.folder.CurrentFolder.Id, auth.UserOrGroupId, auth)
                : this.appConfig.request.Folder.SetGroupPermission(this.$http, this.folder.CurrentFolder.Id, auth.UserOrGroupId, auth);

            promise.then(ok => {
                this.getPermissions(this.folder.CurrentFolder.Id);
                this.$translate('Datarooms.FolderSecurity.PermissionAddedMsg', { name: name })
                    .then(msg => { this.setPermissionMesage(msg); });
                
            }, e => this.helperService.error(e));
        }

        breakInheritance(value: boolean) {
            this.appConfig.request.Folder.SetBreakInheritance(this.$http, this.folder.CurrentFolder.Id, value).then(ok => {
                this.getPermissions(this.folder.CurrentFolder.Id);
                this.getInheritedPermissions(this.folder.CurrentFolder.Id);
                if (!value) this.helperService.alert("Datarooms.FolderSecurity.RebuildInheritanceInfo", true);
                if (value) this.helperService.alert("Datarooms.FolderSecurity.BreakInheritanceInfo", true);

            }, e => this.helperService.error(e));
        }

        // ========================================
        // PRIVATE
        // ========================================
        private getPermissions(folderId: number) {
            this.appConfig.request.Folder.GetPermissions(this.$http, folderId).then(okResponse => {
                this.allPermissions = okResponse.data;

            }, e => this.helperService.error(e));
        }

        private getInheritedPermissions(folderId: number) {
            this.appConfig.request.Folder.GetInheritedPermissions(this.$http, folderId).then(okResponse => {
                this.inheritedPermissions = okResponse.data;

            }, e => this.helperService.error(e));
        }

        private createCallback() {
            const self = this;
            return (msg: string) => {
                self.setPermissionMesage(msg);
                self.getPermissions(self.folder.CurrentFolder.Id);
            }
        }


        private setPermissionMesage(msg: string) {
            this.permissionMessage = msg;
            this.$timeout(() => this.permissionMessage = "", 3000);
        }
    }


    export class AppFolderSecurityComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            folder: '<',
            inheritedPermissions: '<',
            myPermissions: '<',
        };

        controller:any = AppFolderSecurityController;
        templateUrl="/UserTemplate/FolderSecurityTemplate";
    }

    angular.module("app").component("appFolderSecurity", new AppFolderSecurityComponent());
}


