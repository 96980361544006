module App {
    export class AppPasswordController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;

        //compute
        view: "select" | "disable" | "deny" | "regsw" | "reghw" = "select"; // disable | select (-1) | deny (0) | regsw (1) | reghw (2)
        oldPassword: string;
        newPassword1: string;
        newPassword2: string;
        serial: ITextValue;
        code: string;
        verifySw: IAuthenticatorViewModel;
        verifyHw: ITextValue[];

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            if (this.currentUser.isAuthenticatorEnabled) {
                this.view = "disable";
            } else {
                this.view = "select";
            }

        }
        // ========================================
        // PUBLIC
        // ========================================
        setView(view: string) {
            if (view === "regsw") this.enableAuthenticator();
            if (view === "reghw") this.enableToken();

        }

        submit() {
            this.appConfig.request.Login.ChangePassword(this.$http, this.oldPassword, this.newPassword1, this.newPassword2).then(okResponse => {
                this.helperService.alert('Core.Password.PasswordSuccessMsg', true);
            }, e => this.helperService.error(e));

            this.oldPassword = "";
            this.newPassword1 = "";
            this.newPassword2 = "";
        }

        disableAuthenticator() {
            this.authService.disableAuthenticator(() => {
                this.authService.init(true, true);
                this.view = "select";
            }, () => {
                this.authService.init(true, true);
            });
        }



        activateAuthenticator() {
            this.authService.activateAuthenticator(this.verifySw.SecretKey, this.code, () => {
                this.verifySw = null;
                this.code = null;
                this.authService.init(true, false);
                this.view = "disable";
            }, () => {
                this.helperService.alert('Core.Password.TwoFactorEnableFailed', true);
            });

        }

        activateToken() {
            this.authService.activateToken(this.serial.text, this.code, () => {
                this.verifyHw = null;
                this.code = null;
                this.authService.init(true, false);
                this.view = "disable";
            }, () => {
                this.helperService.alert('Core.Password.TwoFactorEnableFailed', true);
            });
        }

        // ========================================
        // PRIVATE
        // ========================================
        private enableAuthenticator() {
            this.authService.enableAuthenticator(data => {
                this.verifySw = data;
            });
            $('#codesw').focus();
        }

        private enableToken () {
            this.authService.enableToken(data => {
                this.verifyHw = data;
            });
            $('#codehw').focus();
        }

    }


    export class AppPasswordComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<'
        };

        controller = AppPasswordController;
        templateUrl="/UserTemplate/PasswordTemplate";
    }

    angular.module("app").component("appPassword", new AppPasswordComponent());
}
