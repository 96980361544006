module App {
    export class DirectLinkController implements ng.IComponentController {
        //binding
        fileId: number;
        folderId: number;
        publicKey: string;
        url: string;

        //compute
        prefix: string;
        link: string;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$location', 'appConfig', 'authService'];
        constructor(
            private $location: ng.ILocationService,
            private appConfig: AppConfig,
            private authService: AuthService,
        ) {
        }

        $onInit(): void {

        }

        $onChanges(onChangesObject: ng.IOnChangesObject) {
            let port = "";
            if (this.$location.protocol() === "http" && this.$location.port() !== 80) port = ":" + this.$location.port();
            if (this.$location.protocol() === "https" && this.$location.port() !== 443) port = ":" + this.$location.port();
            this.prefix = this.$location.protocol() + "://" + this.$location.host() + port;

            if (this.url) {
                this.link = this.url;
            } else if (this.publicKey) {
                if (this.fileId) {
                    this.link = this.prefix + this.appConfig.request.Download.Public(this.publicKey, this.fileId, null);
                } else if (this.folderId) {
                    this.link = this.prefix + "/Guest/" + this.publicKey + "/" + this.folderId;
                } else {
                    this.link = this.prefix + "/Guest/" + this.publicKey;
                }
            } else {
                if (this.fileId) {
                    this.link = this.prefix + this.appConfig.request.Download.File(this.fileId);
                } else if (this.folderId) {
                    this.link = this.prefix + "/V1#/Folder/" + this.folderId;
                } else {
                    this.link = this.prefix + "/Share";
                }
            }
            
        }
        // ========================================
        // PUBLIC
        // ========================================

        // ========================================
        // PRIVATE
        // ========================================

    }


    export class DirectLinkComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            fileId: '<',
            folderId: '<',
            publicKey: '<',
            url: '<'
        };
//                    <--!<span class="glyphicon glyphicon-flash"></span>-->

        controller = DirectLinkController;
        template = `
            <span class="k-textbox k-space-right" style="width: 100%;" >
                <input select-on-click type="text" readonly value="{{$ctrl.link}}" />
                <a href="{{$ctrl.link}}" target="_blank">
                    <span class="k-icon" ng-class="{'k-i-hyperlink': !$ctrl.publicKey, 'k-i-hyperlink-globe': $ctrl.publicKey}"></span>
                </a>
            </span>
        `;
    }

    angular.module("app").component("directLink", new DirectLinkComponent());
}