module App {
    export class AssignMembershipController implements ng.IComponentController {
        //binding
        userId: string;
        groupId: string;
        isInternal: boolean;


        //compute
        memberships: IIdAndName[];
        members: IIdAndName[];
        groupOptions: kendo.ui.DropDownListOptions;
        userOptions: kendo.ui.DropDownListOptions;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$translate', '$http', 'appConfig', 'authService', 'helperService'];
        constructor(
            private $translate: angular.translate.ITranslateService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            this.update();
            if (this.userId) this.groupOptions = this.getMembership();
            if (this.groupId) this.userOptions = this.getMembers();
        }
        // ========================================
        // PUBLIC
        // ========================================
        update () {
            if (this.userId) {
                this.appConfig.request.UserManagement.GetGroupsOfUser(this.$http, this.userId).then(okResponse => {
                    this.memberships = okResponse.data;
                }, e=> this.helperService.error(e));

            }

            if (this.groupId) {
                this.appConfig.request.UserManagement.GetUsersOfGroup(this.$http, this.groupId).then(okResponse => {
                    this.members = okResponse.data;
                }, e=> this.helperService.error(e));
            }
        }

        removeMembership (groupId:string, userId:string) {
            this.helperService.confirm("Datarooms.AssignMembership.RemoveConfirmTitle", "Datarooms.AssignMembership.RemoveConfirmText", result=> {
                if (result) {
                    this.appConfig.request.UserManagement.RemoveMembership(this.$http, userId, groupId).then(()=> {
                        this.update();
                    }, e=> this.helperService.error(e));

                }
            }, null, true);

        };

        addMembership (groupId:string, userId:string) {
            this.appConfig.request.UserManagement.AddMembership(this.$http, userId, groupId).then(okResponse => {
                this.update();
            }, e=> this.helperService.error(e));

        };

        isInGroup(userId: string): boolean {
            if (!this.members) return false;
            var items = this.members.filter(i => i.Id === userId);
            return items.length > 0;
        }

        hasUser(groupId: string): boolean {
            if (!this.memberships) return false;
            var items = this.memberships.filter(i => i.Id === groupId);
            return items.length > 0;
        }

        // ========================================
        // PRIVATE
        // ========================================
        private getMembership(): kendo.ui.DropDownListOptions {
            return {
                dataSource: {
                    "type": 'webapi',
                    transport: {
                        read: this.appConfig.helper.http('get', this.appConfig.request.UserManagement.GroupsUrl, this.authService.getBearerTokenObject()),
                    },
                    "filter": { field: "IsInternal", operator: "eq", value: this.isInternal },

                    "schema": {
                        "model": {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "string" },
                                "Name": { "type": "string" },
                                "IsInternal": { "type": "boolean", "defaultValue": true },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",
                    }

                },
                dataTextField: "Name",
                dataValueField: "Id",
                filter: 'contains',
                template: '<span class="glyphicon glyphicon-unchecked text-info" ng-show="!$ctrl.hasUser(dataItem.Id)"></span><span class="glyphicon glyphicon-check text-success" ng-show="$ctrl.hasUser(dataItem.Id)"></span> {{dataItem.Name}}'
            }
        }
        private getMembers(): kendo.ui.DropDownListOptions {
            return {
                dataSource: {
                    "type": 'webapi',
                    transport: {
                        read: this.appConfig.helper.http('get', this.appConfig.request.UserManagement.UsersUrl, this.authService.getBearerTokenObject()),
                    },
                    "filter": { field: "IsInternal", operator: "eq", value: this.isInternal },
                    "schema": {
                        "model": {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "string" },
                                "FirstName": { "type": "string" },
                                "LastName": { "type": "string" },
                                "IsEnabled": { "type": "boolean" },
                                "LastLogon": { "editable": false, "type": "date", "defaultValue": null as any },
                                "FullName": { "type": "string" },
                                "UserName": { "type": "string" },
                                "Email": { "type": "string" },
                                "IsAuthenticatorEnabled": { "type": "boolean" },
                                "IsInternal": { "type": "boolean", "defaultValue": true },
                                "AuthenticatorType": { "type": "number" },
                                "TokenSerial": { "type": "string" },
                                "Language": { "type": "number", "field": "Language" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",
                    }

                },
                dataTextField: "UserName",
                dataValueField: "Id",
                filter: 'contains',
                template: '<span class="glyphicon glyphicon-unchecked text-info" ng-show="!$ctrl.isInGroup(dataItem.Id)"></span><span class="glyphicon glyphicon-check text-success" ng-show="$ctrl.isInGroup(dataItem.Id)"></span> {{dataItem.UserName}}'
            }
        }
    }


    export class AssignMembershipComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            userId: '@',
            groupId: '@',
            isInternal: '<',
        };

        controller:any = AssignMembershipController;
        template =
            // USER TAB
            '<div ng-show="$ctrl.userId">' +
                '<table>' +
                '<tr>' +
                    '<th translate>Datarooms.AssignMembership.MemberOf</th>' +
                    '<th translate>Datarooms.AssignMembership.Action</th>' +
                '</tr>' +
                '<tr ng-repeat="m in $ctrl.memberships">' +
                    '<td>{{m.Name}}</td>' +
                    '<td><a ng-click="$ctrl.removeMembership(m.Id, $ctrl.userId)"><span class="glyphicon glyphicon-remove clickable" ></span></a></td>' +
                '</tr>' +
                '</table><hr/>' +
                '<p><span translate>Datarooms.AssignMembership.NewMembership</span> <select kendo-drop-down-list="$ctrl.newMembership" k-options="$ctrl.groupOptions"></select>' +
                '<a class="btn btn-primary" ng-click="$ctrl.addMembership($ctrl.newMembership.dataItem().Id, $ctrl.userId)">OK</a></p>' +
            '</div>' +

            // GROUP TAB
            '<div ng-show="$ctrl.groupId">' +
                '<table>' +
                '<tr>' +
                    '<th translate>Datarooms.AssignMembership.Members</th>' +
                    '<th translate>Datarooms.AssignMembership.Action</th>' +
                '</tr>' +
                '<tr ng-repeat="m in $ctrl.members">' +
                    '<td>{{m.Name}}</td>' +
                    '<td><a ng-click="$ctrl.removeMembership($ctrl.groupId, m.Id)"><span class="glyphicon glyphicon-remove clickable" ></span></a></td>' +
                '</tr>' +
                '</table><hr/>' +
                '<p><span translate>Datarooms.AssignMembership.NewMember</span> <select kendo-drop-down-list="$ctrl.newMember" k-options="$ctrl.userOptions"></select>' +
                '<a class="btn btn-primary" ng-click="$ctrl.addMembership($ctrl.groupId, $ctrl.newMember.dataItem().Id)">OK</a></p>' +
            '</div>'
        ;
    }

    angular.module("app").component("assignMembership", new AssignMembershipComponent());
}
