module App {
    export class AppCurrentUploadController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        trans: {'Datarooms.CurrentUpload.Confirm': ""}

        //compute
        gridOptions: kendo.ui.GridOptions;
        gridObject: kendo.ui.Grid;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', '$scope', '$timeout', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService'];
        constructor(
            private $http: ng.IHttpService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void {
            this.gridOptions = this.getGridOptions();
        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        private getGridOptions(): kendo.ui.GridOptions {
            var that = this;
            return {
                // 1. Datasource
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.Upload.GetCurrentUploadsUrl, this.authService.getBearerTokenObject()),
                        "destroy": this.appConfig.helper.http('post', this.appConfig.request.Upload.CancelCurrentUploadUrl, this.authService.getBearerTokenObject())
                    },
                    "serverPaging": true,
                    "pageSize": 20,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "filter": [],
                    "error": function (e:any) {
                        that.authService.kendoError(e, that.gridObject);
                    },

                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "number" },
                                "UserName": { "type": "string" },
                                "Name": { "type": "string" },
                                "ActionDate": { "type": "date" },
                                "Date": { "type": "date" },
                                "SizeBytes": { "type": "number" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    }

                },

                // 2. Columns
                "columns": [
                    {
                        "field": "Name",
                        "title": "{{'Datarooms.CurrentUpload.Name' | translate}}",
                        "filterable": true
                    }, {
                        "field": "UserName",
                        "title": "{{'Datarooms.CurrentUpload.UserName' | translate}}",
                        "filterable": true
                    }, {
                        "field": "ActionDate",
                        "title": "{{'Datarooms.CurrentUpload.ActionDate' | translate}}",
                        "format": "{0:g}",
                        "filterable": true
                    }, {
                        "field": "Date",
                        "title": "{{'Datarooms.CurrentUpload.Date' | translate}}",
                        "format": "{0:g}",
                        "filterable": true
                    }, {
                        "field": "SizeBytes",
                        "title": "{{'Datarooms.CurrentUpload.SizeBytes' | translate}}",
                        "filterable": true
                    }, {
                        "width": 200,
                        "command": [
                            { "name": "destroy" },
                        ]
                    }
                ],

                // 3. Features
                "pageable": true,
                "sortable": true,
                "scrollable": false,
                "editable": {
                    "confirmation": this.trans["Datarooms.CurrentUpload.Confirm"],
                    "mode": "inline",
                    "destroy": true
                },
                "filterable": true,

                // 4. Events

            }
        };

    }


    export class AppCurrentUploadComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            trans: '<',
        };

        controller = AppCurrentUploadController;
        templateUrl = "/AdminTemplate/CurrentUploadTemplate";
    }

    angular.module("app").component("appCurrentUpload", new AppCurrentUploadComponent());
}
