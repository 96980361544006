module App {
    export interface IAppFolderView {
        showUpNav: boolean;
        showShareNav: boolean;
        showRootNav: boolean;

        showBreadcrumb: boolean;
        showSharesBreadcrumb: boolean;

        activeFolder: IFolderViewModel;
        activeFile: IFileViewModel;

        showTimewarp: boolean;
    }


    export class AppFolderController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        folderId: number;
        error: string;
        timewarp: string;

        //compute
        data: IFolderControlViewModel;
        myPermissions: ICurrentPermissionViewModel;
        view: IAppFolderView = {
            showUpNav: false,
            showShareNav: false,
            showRootNav: false,

            showBreadcrumb: false,
            showSharesBreadcrumb: false,

            activeFolder: null,
            activeFile: null,

            showTimewarp: this.filesystemService.getTimewarp() !== null,
        }
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$sce', '$state', '$timeout', '$http', '$location', '$window', 'appConfig', 'authService',
            'coreDataService', 'localeService', 'helperService', 'filesystemService', 'uploadService'];
        constructor(
            private $scope: ng.IScope,
            private $sce: ng.ISCEService,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private $location: ng.ILocationService,
            private $window: ng.IWindowService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService,
            private filesystemService: FilesystemService,
            private uploadService: UploadService,
        ) {
        }

        $onInit(): void {

            // set timewarp from url parameter on initial loading
            if (this.timewarp) {
                const dt = new Date(this.timewarp);
                this.filesystemService.setTimewarp(dt);
            // set timewarp to url when returning with active timewarp
            } else if (this.filesystemService.getTimewarp()) {
                this.$location.search({ timewarp: this.filesystemService.getTimewarp().toISOString() });
            }

            // show timewarp widget
            if (this.filesystemService.getTimewarp()) {this.view.showTimewarp = true;}

            this.getFolder(this.folderId);
            this.uploadService.setSuccessCallback(this.onRefreshFolder);

            $('.btn-title').tooltip();

            if (this.error) {this.helperService.alert(this.error, true);}



        }

        // ========================================
        // CALLBACK
        // ========================================
        onSelectFolder(folder: IFolderViewModel):void {
            if (folder === this.view.activeFolder) {
                this.view.activeFolder = null;
            } else {
                this.view.activeFolder = folder;
            }
            this.view.activeFile = null;

        }

        onSelectFile(file: IFileViewModel):void {
            if (file === this.view.activeFile) {
                this.view.activeFile = null;
            } else {
                this.view.activeFile = file;
            }
            this.view.activeFolder = null;

        }

        onRefreshFolder():void {
            if (this.data && this.data.CurrentFolder) {
                this.getFolder(this.data.CurrentFolder.Id);
            }
        }


        // ========================================
        // PUBLIC
        // ========================================
        getMyPermissions(folderId: number):void {
            if (this.filesystemService.getTimewarp()) {
                this.myPermissions = {
                    CanAdmin: false,
                    CanCreateFolder: false,
                    CanDelete: false,
                    CanDownload: true,
                    CanUpload: false,
                    CanReadLogs: false,
                    CanManagePermissions: false,
                    CanManageOptions: false,
                    DenyAllAccess: false,
                    BreakPermissionInheritance: false,
                    CheckInRequired: false,
                    TwoFactorRequired: true,
                    OwnerId: this.data.CurrentFolder.OwnerId,
                    OwnerName: this.data.CurrentFolder.OwnerName,
                    OwnershipIsPending: false
                }
            } else {
                this.appConfig.request.Folder.GetMyPermissions(this.$http, folderId).then(okResponse => {
                    this.myPermissions = okResponse.data;
                }, e => this.helperService.error(e));
            }


        }

        setViewOptions() {
            this.view.showUpNav = this.data.Breadcrumb && this.data.Breadcrumb.length > 1 && !this.data.IsRootFolder;
            this.view.showShareNav = !this.currentUser.isAdmin && this.data.Breadcrumb && this.data.Breadcrumb.length <= 1 && !this.data.IsRootFolder;
            this.view.showRootNav = false; //currentUser.isAdmin && data.Breadcrumb && data.Breadcrumb.length === 1 && !data.IsRootFolder;
            this.view.showBreadcrumb = this.data.Breadcrumb && this.data.Breadcrumb.length > 0 && !this.data.IsRootFolder;
            this.view.showSharesBreadcrumb = !this.currentUser.isAdmin && !this.data.IsRootFolder;
        }

        getFolder(folderId: number) {
            this.filesystemService.getFolder(folderId).then(data => {
                if (!data) return;
                this.data = data;
                this.data.CurrentFolder.Design.Text2 = this.$sce.trustAsHtml(data.CurrentFolder.Design.Text);
                this.getMyPermissions(folderId);
                this.setViewOptions();

                $('.btn-title').on('drop', this.getDndListener());
            });

        };



        onTimewarpChange() {
            if (this.filesystemService.getTimewarp()) {
                this.$location.search({ timewarp: this.filesystemService.getTimewarp().toISOString() });
            } else {
                this.$location.search({ timewarp: null });
            }
            this.getFolder(this.folderId);
        }

        // ========================================
        // PRIVATE
        // ========================================
        private getDndListener() {
            const self = this;
            return (e: any) => {
                e.stopPropagation();
                e.preventDefault();
                self.uploadService.setFolder(self.data.CurrentFolder);

                //used from flow.js
                var flow = self.uploadService.flow as any;
                var dataTransfer = (event as any).dataTransfer;

                if (dataTransfer.items && dataTransfer.items[0] &&
                    dataTransfer.items[0].webkitGetAsEntry) {
                    flow.webkitReadDataTransfer(event);
                } else {
                    flow.addFiles(dataTransfer.files, event);
                }
                
            }
        }

    }


    export class AppFolderComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            folderId: '<',
            error: '<',
            timewarp: '<'
        };

        controller = AppFolderController;
        templateUrl="/UserTemplate/FolderTemplate";
    }

    angular.module("app").component("appFolder", new AppFolderComponent());
}