module App {
    export class AppLoginController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        redirect: string;

        //compute
        hideId: boolean;
        companyid: string;
        error: string;
        error2: string;
        errorParams: any;
        username: string;
        password: string;
        isRedirected: boolean;
        isAuthenticating: boolean;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$location', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService', 'navigationService'];
        constructor(
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService,
            private navigationService: NavigationService,
        ) {
        }

        $onInit(): void {
            const domain = this.appConfig.helper.companyId();
            if (domain.length === 6) {
                this.hideId = true;
                this.companyid = domain;
                $('#username').focus();
            } else {
                $('#companyid').focus();
            }

            this.isRedirected = this.navigationService.isRedirected();

        }
        // ========================================
        // PUBLIC
        // ========================================
        login() {
            this.error = null;
            this.isAuthenticating = true;
            this.authService.login(this.companyid, this.username, this.password)
                .then(okResponse => {
                    if (this.redirect) {
                        this.navigationService.redirectToUrl(this.redirect);
                    }

                    this.navigationService.navigateBack();

                    //this.$location.path(this.returnUrl);

                    // location does only work when then returns a result!
                    return okResponse;

                }, (errResponse:IApplicationException) => {
                    console.log(errResponse);
                    if (errResponse.Exception === "RedirectAppException") {
                        this.navigationService.redirectToUrl(errResponse.TranslateParams["target"]);
                    }

                    this.isAuthenticating = false;
                    this.error = errResponse.Translate;
                    this.errorParams = errResponse.TranslateParams;
                    this.error2 = errResponse.Translate2 || "";
                    this.password = "";
                    $('#password').focus();
                    //return errResponse;

                });
        }

        logout() {
            this.authService.logout();
        }
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppLoginComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            redirect: '<'
        };

        controller = AppLoginController;
        templateUrl = "/GuestTemplate/LoginTemplate";
        
    }

    angular.module("app").component("appLogin", new AppLoginComponent());
}



