module App {
    interface IAppDownloadFileTrans {
        'Datarooms.DownloadFile.NotFound': string;
        'Datarooms.DownloadFile.Logon': string;
    }

    export class AppDownloadFileController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        trans: IAppDownloadFileTrans;
        fileId: number;
        error: string;

        //compute
        messages: string[] = [];
        file: IFileViewModel;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$location', '$http', '$translate', 'appConfig', 'helperService'];
        constructor(
            private $location: ng.ILocationService,
            private $http: ng.IHttpService,
            private $translate: ng.translate.ITranslateService,
            private appConfig: AppConfig,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            if (this.error) {
                if (this.error.indexOf('.') > -1) {
                    this.$translate(this.error).then(ok => {
                        this.messages.push(ok);
                    });
                } else {
                    this.messages.push(this.error);
                }
            }
            this.getData(this.fileId);

        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        private getData(fileId: number) {
            if (!fileId) {
                this.messages.push(this.trans["Datarooms.DownloadFile.NotFound"]);
                return;
            }
            this.appConfig.request.File.Details(this.$http, fileId).then(okResponse => {
                this.file = okResponse.data;
                //this.messages.push(this.trans["Datarooms.DownloadFile.Logon"]);
            }, e=> this.helperService.error(e));
        }
    }


    export class AppDownloadFileComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            trans: '<',
            fileId: '<',
            error: '<',
        };

        controller = AppDownloadFileController;
        templateUrl="/UserTemplate/DownloadFileTemplate";
    }

    angular.module("app").component("appDownloadFile", new AppDownloadFileComponent());
}
