module App {
    export class AppHomeController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;

        //compute
        domain = this.appConfig.helper.companyId();
        brandingData: any;
        publicShares: IFolderViewModel[];
        guestShares: IFolderViewModel[];
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$filter', '$http', '$scope', '$timeout', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService'];
        constructor(
            private $filter: ng.IFilterService,
            private $http: ng.IHttpService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void {
            this.brandingData = this.authService.brandingData;
            this.getPublicShares();

        }
        // ========================================
        // PUBLIC
        // ========================================
        getPublicShares() {
            this.filesystemService.getPublicShares(this.appConfig.helper.companyId(), data => {
                this.publicShares = this.$filter('filter')(data, { 'PublicPin': false });
                this.guestShares = this.$filter('filter')(data, { 'PublicPin': true });
            }, ()=> {
                this.publicShares = [];
                this.guestShares = [];

            });
        };

        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppHomeComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<'
        };

        controller = AppHomeController;
        templateUrl = "/GuestTemplate/HomeTemplate";

    }

    angular.module("app").component("appHome", new AppHomeComponent());
}

