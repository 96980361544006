module App {
    export class AppConversationsController implements ng.IComponentController {
        //binding

        //compute
        //conversations: IConversationViewModel[];
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', 'appConfig', 'conversationService'];
        constructor(
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private conversationService: ConversationService,
        ) {
        }

        $onInit(): void {
            this.conversationService.loadConversations();
        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        //private getConversations () {
        //    this.appConfig.request.Message.GetConversations(this.$http).then(okResponse => {
        //        this.conversations = okResponse.data;
        //    }, errResponse => {
        //        console.error(errResponse);
        //    });
        //}

    }


    export class AppConversationsComponent implements ng.IComponentOptions {
        controller = AppConversationsController;
        templateUrl="/UserTemplate/ConversationsTemplate";
    }

    angular.module("app").component("appConversations", new AppConversationsComponent());
}


