module App {
    interface IRoomWidgetSelectFolderCallbackObject {
        folder: IFolderViewModel
    }

    export class RoomWidgetController implements ng.IComponentController {
        //binding
        folder: IFolderViewModel;
        selectFolder: (cbo: IRoomWidgetSelectFolderCallbackObject)=>void;
        isPublic: boolean;
        listView: boolean;

        //compute
        currentUser: IApplicationUserClientStatusViewModel;
        navUrl: string;
        imageUrl: string;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$sce', '$location', '$element', 'appConfig', 'authService'];
        constructor(
            private $sce: ng.ISCEService,
            private $location: ng.ILocationService,
            private $element: ng.IRootElementService,
            private appConfig: AppConfig,
            private authService: AuthService,
        ) {
        }

        $onInit(): void {
            this.authService.init(false, false).then(cu => {
                this.currentUser = cu;
                if (this.folder) this.setNavOptions();
            });
        }

        $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj['folder'] && onChangesObj['folder'].currentValue && onChangesObj['folder'].currentValue.Id) {
                if (this.currentUser) this.setNavOptions();
                this.folder.Design.Text2 = this.$sce.trustAsHtml(this.folder.Design.Text);
            }
        }
        // ========================================
        // PUBLIC
        // ========================================
        setNavOptions() {
            if (this.isPublic && !this.currentUser.isAuthenticated) {
                this.navUrl = "#/Public/" + this.folder.PublicKey;
            } else {
                this.navUrl = "#/Folder/" + this.folder.Id;
            }

            if (this.folder.Id) {
                let imageUrl = this.appConfig.request.Download.FolderImage(this.folder.Design.ImageName, this.folder.Design.IsTenantImage, this.folder.PublicKey);
                this.imageUrl = `url('${imageUrl}')`;
            }
        }

        navigate() {
            this.$location.url(this.navUrl.replace('#', ''));
        }
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class RoomWidgetComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            folder: '<',
            selectFolder: '&',
            isPublic: '<',
            listView: '='
        };

        controller:any = RoomWidgetController;
        template = '<div ng-class="{dataRoomListItem: $ctrl.listView===true, dataRoom: !$ctrl.listView}" class="clickable" ng-show="$ctrl.folder" ng-click="$ctrl.navigate()" ng-style="{\'background-color\': $ctrl.folder.Design.Color2, \'border-color\': $ctrl.folder.Design.Color1}">' +
                        '<a ng-href="{{$ctrl.navUrl}}"><div class="roomImage" ng-style="{\'background-image\': $ctrl.imageUrl}"></div></a>' +
                        '<p class="roomTitle" ng-style="{\'color\': $ctrl.folder.Design.Color1}">{{$ctrl.folder.Name}}</p>' +
                        '<p class="roomDescription hidden-xs hidden-sm hidden-md" ng-bind-html="$ctrl.folder.Design.Text2"></p>' +
                        '<div class="roomIcons hidden-xs hidden-sm hidden-md">' +
                        '</div>' +
                    '</div>' 
        ;
    }

    angular.module("app").component("roomWidget", new RoomWidgetComponent());
}

