module App {
    export class SecurityOptionsController implements ng.IComponentController {
        //binding
        inheritedPermissions: ISharePermissionViewModel;
        localPermissions: ISharePermissionViewModel;
        canEdit: boolean;
        onUpdate: Function;

        //compute
        secureButton: kendo.mobile.ui.Switch;
        secureButtonEnabled: boolean;
        secureButtonChecked: boolean;
        secureButtonStatus: string;

        publicButton: kendo.mobile.ui.Switch;
        publicButtonEnabled: boolean;
        publicButtonChecked: boolean;
        publicButtonStatus: string;

        checkInButton: kendo.mobile.ui.Switch;
        checkInButtonEnabled: boolean;
        checkInButtonChecked: boolean;
        checkInButtonStatus: string;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', '$scope', '$timeout', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService'];
        constructor(
            private $http: ng.IHttpService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void { }
        $onChanges(onChangesObj: ng.IOnChangesObject): void {
            this.getButtonStates();
        }

        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        private getButtonStates() {
            if (!this.inheritedPermissions) return;
            if (!this.localPermissions) return;

            this.secureButtonEnabled = this.getSecureButtonEnabled();
            this.secureButtonChecked = this.getSecureButtonChecked();
            this.secureButtonStatus = this.getSecureButtonStatus();

            this.publicButtonEnabled = this.getPublicButtonEnabled();
            this.publicButtonChecked = this.getPublicButtonChecked();
            this.publicButtonStatus = this.getPublicButtonStatus();

            this.checkInButtonEnabled = this.getCheckInButtonEnabled();
            this.checkInButtonChecked = this.getCheckInButtonChecked();
            this.checkInButtonStatus = this.getCheckInButtonStatus();
        }

        // SECURE
        // ================
        private getSecureButtonEnabled(): boolean {
            if (!this.canEdit) return false;

            if (this.inheritedPermissions.Level === 1) return false;
            if (this.inheritedPermissions.Level === 2) return false;
            if (this.inheritedPermissions.Level === 4) return false;
            if (this.localPermissions.Level === 1) return false;
            if (this.localPermissions.Level === 2) return false;
            return true;
        }

        private getSecureButtonChecked(): boolean {
            if (this.inheritedPermissions.Level === 4) return true;
            if (this.localPermissions.Level === 4) return true;
            return false;
        }

        private getSecureButtonStatus(): string {
            if (!this.canEdit) return "Datarooms.SecurityOptions.NoEdit";

            if (this.inheritedPermissions.Level === 1 || this.inheritedPermissions.Level === 2) return "Datarooms.SecurityOptions.SecureFolderNotPossible";
            if (this.localPermissions.Level === 1 || this.localPermissions.Level === 2) return "Datarooms.SecurityOptions.SecureFolderNotPossible";
            if (this.inheritedPermissions.Level === 4) return "Datarooms.SecurityOptions.SecureFolderInherited";
            return "Datarooms.SecurityOptions.SecureFolderSelect";
        }

        private secureButtonEvent(kendoEvent: any) {
            const level = kendoEvent.checked ? 4: 0;

            this.appConfig.request.Folder.SetPermissionLevel(this.$http, this.localPermissions.FolderId, level, null).then(ok => {
                this.onUpdate({ folderId: this.localPermissions.FolderId });
                this.getButtonStates();
            }, e => {
                this.secureButton.check(!kendoEvent.checked);
                this.helperService.error(e);
            });

        }

        // PUBLIC
        // ==============
        private getPublicButtonEnabled(): boolean {
            if (!this.canEdit) return false;

            if (this.inheritedPermissions.Level === 4) return false;
            if (this.localPermissions.Level === 4) return false;
            return true;
        }

        private getPublicButtonChecked(): boolean {
            if (this.localPermissions.Level === 1) return true;
            if (this.localPermissions.Level === 2) return true;
            return false;
        }

        private getPublicButtonStatus(): string {
            if (!this.canEdit) return "Datarooms.SecurityOptions.NoEdit";

            if (!this.publicButtonEnabled) return "Datarooms.SecurityOptions.PublicFolderNotPossible";
            if (this.localPermissions.Level === 1) return "Datarooms.SecurityOptions.PublicLink";
            if (this.localPermissions.Level === 2) return "Datarooms.SecurityOptions.PublicLink";
            return "Datarooms.SecurityOptions.PublicFolderSelectOff";
        }

        private publicButtonEvent(kendoEvent: any) {
            const level = kendoEvent.checked ? 1 : 0;

            if (!kendoEvent.checked) {
                this.helperService.confirm('Datarooms.SecurityOptions.ConfirmPublicTitle', 'Datarooms.SecurityOptions.ConfirmPublicText',
                    () => {
                        this.appConfig.request.Folder.SetPermissionLevel(this.$http, this.localPermissions.FolderId, level, null).then(ok => {
                            this.onUpdate({ folderId: this.localPermissions.FolderId });
                        }, e => {
                            this.publicButton.check(!kendoEvent.checked);
                            this.helperService.error(e);
                        });
                    }, () => {
                        this.publicButton.check(!kendoEvent.checked);
                    }, true);

            } else {
                this.appConfig.request.Folder.SetPermissionLevel(this.$http, this.localPermissions.FolderId, level, null).then(ok => {
                    this.onUpdate({ folderId: this.localPermissions.FolderId });
                }, e => {
                    this.publicButton.check(!kendoEvent.checked);
                    this.helperService.error(e);
                });
            }


        }

        private setPin() {
            if (!this.canEdit) return;

            this.helperService.prompt('Datarooms.SecurityOptions.PinQuestion', this.localPermissions.Pin, pin => {
                let level = 0;
                if (!pin || pin === "") {
                    level = 1;
                } else {
                    if (!/^[A-Za-z0-9öäüÖÄÜ!§$%&/()=?*+-_.:,;#@€]{4,16}$/.test(pin)) {
                        this.helperService.alert('Datarooms.SecurityOptions.PinCharacters', true);
                        return;
                    }
                    level = 2;
                }


                this.appConfig.request.Folder.SetPermissionLevel(this.$http, this.localPermissions.FolderId, level, pin).then(ok => {
                    this.onUpdate({ folderId: this.localPermissions.FolderId });
                }, err => this.helperService.error(err));
            }, true);

        }

        // CHECK IN
        // ==============
        private getCheckInButtonEnabled(): boolean {
            if (!this.canEdit) return false;

            if (this.inheritedPermissions.CheckInRequired) return false;
            return true;
        }

        private getCheckInButtonChecked(): boolean {
            if (this.inheritedPermissions.CheckInRequired) return true;
            if (this.localPermissions.CheckInRequired) return true;
            return false;
        }

        private getCheckInButtonStatus(): string {
            if (!this.canEdit) return "Datarooms.SecurityOptions.NoEdit";

            if (!this.checkInButtonEnabled) return "Datarooms.SecurityOptions.CheckInNotPossible";
            if (this.localPermissions.CheckInRequired) return "Datarooms.SecurityOptions.CheckInOn";
            return "Datarooms.SecurityOptions.CheckInOff";
        }

        private checkInButtonEvent(kendoEvent: any) {
            this.appConfig.request.Folder.SetCheckInRequired(this.$http, this.localPermissions.FolderId, kendoEvent.checked).then(ok => {
                this.onUpdate({ folderId: this.localPermissions.FolderId });
            }, e => {
                this.checkInButton.check(!kendoEvent.checked);
                this.helperService.error(e);
            });
        }
    }


    export class SecurityOptionsComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            inheritedPermissions: '<',
            localPermissions: '<',
            canEdit: '<',
            onUpdate: '&',
        };

        controller = SecurityOptionsController;
        templateUrl = "/UserTemplate/SecurityOptionsTemplate";
    }

    angular.module("app").component("securityOptions", new SecurityOptionsComponent());
}