module App {
    export class TokenCookieDirective implements ng.IDirective {
        restrict = 'A';

        static Factory(): ng.IDirectiveFactory {
            const directive = (authService: AuthService) => new TokenCookieDirective(authService);
            directive.$inject = ['authService'];
            return directive;
        }

        constructor(private authService: AuthService) { }

        link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) => {
            var that = this;
            element.on('click', function () {
                that.authService.setTokenCookie(); 
            });

        };
    }


    angular.module("app").directive('tokenCookie', TokenCookieDirective.Factory());
}