module App {
    //////////////////////////
    // CLIENT Objects
    //////////////////////////
    export interface IOauthToken {
        access_token: string;
        username: string;
        token_type: string;
        expires_in: string;
    }

    export interface ICustomEditorOptions {
        isrequired: boolean;
        data: any;
        optionlabel: string;
        cascade: string;
        withoutValue: boolean;
    }

    export interface IApplicationException {
        Exception: string;
        Message: string;
        Translate: string;
        Translate2: string;
        TranslateParams: {[key:string]:string};
    }

    //////////////////////////
    // API View Models
    //////////////////////////

    //-----------------------------
    // ApplicationUser
    //-----------------------------

    export interface IDataSourceResult<T> {
        Data: T[];
        Total: number;
    }

    export interface IApplicationRoleViewModel {
        Id: string;
        Name: string;
        IsInternal: boolean;
    }

    export enum AuthenticatorType {
        AllowAll = -1,
        None = 0,
        Totp30SecSoftware = 1,
        Totp60SecHardware = 2
    }

    export interface IApplicationUserViewModel {
        Id: string;
        FirstName: string;
        LastName: string;
        IsEnabled: boolean;
        LastLogon: Date;
        FullName: string;
        UserName: string;
        Email: string;
        Language: Language;
        IsAuthenticatorEnabled: boolean;
        AuthenticatorType: AuthenticatorType;
        TokenSerial: string;
        IsInternal: boolean;
        PhoneNumber: string;
    }

    export interface IApplicationUserServerStatusViewModel {
        UserId: string | null;
        UserName: string;
        IsAuthenticated: boolean;
        IsTwoFactorAuthenticated: boolean;
        IsAdmin: boolean;
        IsManagement: boolean;
        IsAuthenticatorEnabled: boolean;
        IsFullLicense: boolean;
        AuthenticatorType: AuthenticatorType;
        CompanyId: string;
        Ownerships: IOwnerShipViewModel[];
        Roles: string[];
    }

    export interface IApplicationUserClientStatusViewModel {
        UserId: string | null;
        username: string;
        isAuthenticated: boolean;
        IsTwoFactorAuthenticated: boolean;
        isAdmin: boolean;
        isManagement: boolean;
        isAuthenticatorEnabled: boolean;
        isFullLicense: boolean;
        authenticatorType: AuthenticatorType;
        CompanyId: string;
        Ownerships: IOwnerShipViewModel[];
        Roles: string[];

        //only clientside
        isLoaded: boolean;
        publicPin: string;
        favorites: IFavoriteViewModel[];
        logoUrl: string | null;
    }

    export interface IResetPasswordByUserViewModel {
        OldPass: string;
        NewPass1: string;
        NewPass2: string;
    }

    export interface IResetPasswordByAdminViewModel {
        Id: string;
        NewPass1: string;
        NewPass2: string;
    }

    export interface IAuthenticatorViewModel {
        SecretKey: string;
        BarcodeUrl: string;
        Code: string;
    }

    //-----------------------------
    // Eventlog
    //-----------------------------
    export enum EventlogAction {
        File = 1,
        Folder = 2,
        Login = 3,
        Admin = 4,
        Manager = 5,
        System=6
    }

    export enum FileAccessType {
        UploadFile = 0,
        DownloadFile = 1,
        DownloadZip = 2,
        DeleteFile= 3,
        RemoveFromStorage = 4,
        CopYFile = 10,
        MoveFile = 11,
        RenameFile = 12,
        RestoreFromTrash = 30,
        RevertFromVersion = 31,
        CopyFromTimewarp = 32,
        CheckOut = 40,
        CheckIn = 41,
        CheckUndo = 42,
        None=99
    }

    export enum FolderAccesType {
        CreateFolder = 0,
        DownloadFolder = 2,
        DeleteFolder = 3,
        RemoveFromStorage = 4,
        CopyFolder = 10,
        MoveFolder = 11,
        RenameFolder = 12,
        AddPermission = 20,
        RemovePermission = 21,
        RestoreFromTrash= 30,
        CopyFromTimewarp = 32,
        None=99
    }

    export interface IEventlogViewModel {
        Id: number;
        DateTime: Date;
        TenantId: number | null;
        TenantName: string;
        Action: EventlogAction;
        User: string;
        IpAddress: string;
        Details: string;
    }

    export interface IAccessViewModel extends IEventlogViewModel {
        IsFile: boolean;
        FileAccessType: FileAccessType;
        FileOrFolderId: number | null;
        FileOrFolderName: string;
        FolderAccessType: FolderAccesType;
    }

    //-----------------------------
    // File
    //-----------------------------
    export interface IFileViewModel {
        Id: number;
        Version: string;
        Name: string;
        SizeBytes: number;
        Date: Date;
        ActionDate: Date;
        UserName: string;
        HasThumb: boolean;
        Hash: string;
        Path: string;
        FolderId: number;
        CheckOutUserId: string;
        CheckOutUserName: string;
        CheckOutDate: Date;
    }

    //-----------------------------
    // Folder
    //-----------------------------
    export interface IFolderDesignViewModel {
        Color1: string;
        Color2: string;
        Text: string;
        Text2?: string; // for sce Service
        ImageName: string;
        IsTenantImage: boolean;
    }

    export interface IFolderViewModel {
        Id: number;
        Name: string;
        IsShared: boolean;
        SizeBytes: number;
        Creator: string;
        PublicKey: string;
        PublicPin: boolean;
        ParentId: number | null;
        IsTwoFactor: boolean | null;
        OwnerId: string;
        OwnerName: string;
        Design: IFolderDesignViewModel;
    }

    export interface ITrashViewModel {
        Id: number;
        IsFolder: boolean;
        Name: string;
        Size: number;
        TrashDate: Date;
        Location: string;
        SizeReadable: string;
        ParentFolderId: number;
    }

    export interface IFavoriteViewModel {
        Id: number;
        IsFolder: boolean;
        Name: string;
    }

    export interface IOwnerShipViewModel {
        Key: number;
        Value: string;
        IsPending: boolean;
    }
    //-----------------------------
    // Messages
    //-----------------------------
    export interface IMessageViewModel {
        Id?: number;
        SendDate?: Date;
        ReadDate?: Date;
        Text: string;
        IsMe: boolean;
    }

    export interface IConversationViewModel {
        PersonId: string;
        PersonName: string;
        CountNew: number;
        CountTotal: number;
        LastDate: Date | null;
        LastMessage: string;
    }
    //-----------------------------
    // Note
    //-----------------------------
    export interface INoteViewModel {
        Id: number;
        Date: Date;
        Content: string;
        Type: number;
        ContentDate: Date | null;
    }

    export enum NoteType {
        None = 0,
        Important = 10,
        Task = 20,
        Phone = 30,
        Finished = 100
    }
    //-----------------------------
    // Permission
    //-----------------------------
    export interface ICurrentPermissionViewModel {
        CanUpload: boolean;
        CanCreateFolder: boolean;
        CanDelete: boolean;
        CanDownload: boolean;
        CanReadLogs: boolean;
        CanManagePermissions: boolean;
        CanManageOptions: boolean;
        DenyAllAccess: boolean;
        TwoFactorRequired: boolean;
        CanAdmin: boolean;
        OwnerId: string;
        OwnerName: string;
        OwnershipIsPending: boolean;
        CheckInRequired: boolean;
        BreakPermissionInheritance: boolean;
    }

    export interface ISetPermissionViewModel {
        CanUpload: boolean;
        CanCreateFolder: boolean;
        CanDelete: boolean;
        CanReadLogs: boolean;
        CanManagePermissions: boolean;
        CanManageOptions: boolean;
        DenyAllAccess: boolean;

    }

    export interface IPermissionDefinitionViewModel {
        CanUpload: boolean;
        CanCreateFolder: boolean;
        CanDelete: boolean;
        CanReadLogs: boolean;
        CanManagePermissions: boolean;
        CanManageOptions: boolean;
        DenyAllAccess: boolean;
        IsUser: boolean;
        UserOrGroupId: string;
        UserOrGroupName: string;
        IsFullLicense: boolean;
    }

    export interface ISharePermissionViewModel {
        UserPermissions: IPermissionDefinitionViewModel[];
        GroupPermissions: IPermissionDefinitionViewModel[];
        TwoFactorRequired: boolean;
        CheckInRequired: boolean;
        BreakPermissionInheritance: boolean;
        IsPublicFolder: boolean;
        PublicUrl: string;
        Pin: string;
        FolderId: number | null;
        FolderName: string;
        LevelIsInherited: boolean;
        Level: number;
    }
    //-----------------------------
    // Special
    //-----------------------------
    export interface IFolderControlViewModel {
        CurrentFolder: IFolderViewModel;
        Breadcrumb: IIdAndName[];
        ContainedFolders: IFolderViewModel[];
        ContainedFiles: IFileViewModel[];
        IsRootFolder: boolean;
        SubfolderCount: number;
        FileCount: number;
        FolderSize: number;
        PublicKeyInherited: string;
    }

    export interface IIdAndName {
        Id: string;
        Name: string;
    }

    export interface ITextValue {
        text: string;
        value: number | string;
    }

    export interface ISearchResultViewModel {
        Files: IFileViewModel[];
        Folders: IFolderViewModel[];
        FilesTotal: number;
        FoldersTotal: number;

    }

    export interface ICleanupViewModel {
        Folders: string[];
        Files: string[];
        Eventlogs: number;
    }

    export interface INoAdminViewModel {
        FolderId: number;
        FolderName: string;
        OwnerId: string;
        OwnerName: string;
        IsInherited: boolean;
        FromRoot: boolean;
        IsPending: boolean;
        CanAdmin: boolean;
    }

    export interface IEnumViewModel {
        [enumName: string]: { [enumValue: string]: string }
    }
    //-----------------------------
    // Tenant
    //-----------------------------
    export enum Language {
        De = 0,
        En = 1,
        It = 2,
    }

    export enum ColorTheme {
        AppBlue = 0,
        AppRed = 1,
        AppGreen = 2,
        AppYellow = 3,

    }

    export interface ITenantViewModel {
        Id: number;
        Name: string;
        IsEnabled: boolean;
        AdminUser: string;
        AdminEmail: string;
        AdminIsEnabled: boolean;
        IsAuthenticatorEnabled: boolean;
        LastLogon: Date | null;
        LoginCount: number;
        Language: Language;
    }

    export interface ITenantDetailViewModel {
        InUse: number;
        TenantLimit: number | null;
        ManagementLimit: number | null;
        DefaultLimit: number;
        Percent: number;
        RetentionTimeInMonths: number | null;
        TotalUsers: number;
        ActiveUsers: number;
        CompanyId: string;
        PrimaryContactUser: string;
        PrimaryContactMail: string;
        FullLicenseLimit: number;
        LightLicenseLimit: number;
        LoginCount: number;

        Max?:number; //only client
    }

    export interface ITenantDriveStatus {
        TotalLimit: number;
        TotalData: number;
        LiveData: number;
        OldData: number;
        Free: number;
    }

    export interface IBrandingViewModel {
        Header: string;
        Text: string;
        ColorTheme?: string;
        Domain?: string;
        BrandingFolderId?: number;
        DesignFolderId?: number;
    }

    export interface IShareImageViewModel {
        Id: number;
        Name: string;
        IsTenant: boolean;
    }

    export interface IThumbStatsViewModel {
        TenantId: number;
        TenantName: string;
        ThumbCount: number;
        TotalCount: number;
    }
    //-----------------------------
    // Token
    //-----------------------------
    export interface ITokenManagementViewModel {
        Id: number;
        Type: AuthenticatorType;
        Serial: string;
        Key: string;
        TenantId: number;
        TenantName: string;
    }

    export interface ITokenAdminViewModel {
        Id: number;
        Type: AuthenticatorType;
        Serial: string;
        UserId: string;
        UserName: string;
    }

}