module App {
    export class HelperService {
        public static Factory($http: ng.IHttpService,
            $q: ng.IQService,
            $translate: angular.translate.ITranslateService) {
            return new HelperService($http, $q, $translate);
        }

        constructor(
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private $translate: angular.translate.ITranslateService) {
            $translate(['Core.Buttons.Ok', 'Core.Buttons.Cancel'])
                .then(res => {
                    this.trans.OK = res['Core.Buttons.Ok'];
                    this.trans.Cancel = res['Core.Buttons.Cancel'];

                });
        }

        // PROPS
        private trans = { Cancel: "", OK: "" };

        // PUBLIC
        alert=(message:string, enableTranslate:boolean, translateParams?:any)=> {
            if (enableTranslate) {
                this.$translate(message, translateParams).then(msg => {
                    bootbox.alert(msg);
                });
            } else {
                bootbox.alert(message);
            }
        }

        prompt(message: string, defaultValue: string, okCallback: ((_:string)=>any), enableTranslate: boolean) {
            if (enableTranslate) {
                this.$translate(message).then(translatedMessage => {
                    this.promptAction(translatedMessage, defaultValue, okCallback);
                });
            } else {
                this.promptAction(message, defaultValue, okCallback);
            }

        }

        confirm(title: string, message: string, okCallback: ((_: boolean) => any), cancelCallback: ((_: boolean) => any), enableTranslate:boolean) {
            if (enableTranslate) {
                this.$translate([title, message]).then(t => {
                    this.confirmAction(t[title], t[message], okCallback, cancelCallback);
                });
            } else {
                this.confirmAction(title, message, okCallback, cancelCallback);
            }


        }

        searchRowInGrid(grid: kendo.ui.Grid, dataItem: any): (JQuery | null) {
            var uid = this.getUidFromIdInGrid(grid, dataItem.Id);
            if (!uid) return null;
            var row = grid.element.find("[data-uid='" + uid + "']");
            return row;
        }

        getBrandingColor(name:string, light:boolean) {
            if (light) {
                var lSelector = '#css2js .bg-' + name;
                return $(lSelector).css('background-color');
            } else {
                var dSelector = '#css2js .text-' + name;
                return $(dSelector).css('color');
            }
        }

        error = (errResponse:any)=> {
            console.error("helperService.error: ", errResponse);
            if (!errResponse.data) {
                this.alert('Core.Backend.ExGeneric', true);
                return;
            }
            if (angular.isString(errResponse.data)) this.alert(errResponse.data, false);

            if (errResponse.data.Exception && errResponse.data.Exception === "TwoFactorException") return;

            if (errResponse.data.Translate) {
                this.alert(errResponse.data.Translate, true, errResponse.data.TranslateParams);
            } else if (angular.isString(errResponse.data.Message)) this.alert(errResponse.data.Message, false);

        }

        toLocalTime(e: any, fieldName: string) {
            // http://www.telerik.com/forums/utc-datetime-handling-with-datepicker-and-kendo-ui-grid-edit-dialog
            if (e.type === "read") {
                var data = e.response.Data ? e.response.Data : e.response;

                for (var i = 0; i < data.length; i++) {
                    // on the wire data is without timezone information, therefore deserialized as local time although it is UTC!!!
                    var origDate = kendo.parseDate(data[i][fieldName]);
                    if (!origDate) continue;
                    // getTimezoneOffset for CEST = -120
                    var utcDate = new Date(origDate.getTime() - origDate.getTimezoneOffset() * 60000);
                    // date object has not the correct local time and the correct timezone
                    data[i][fieldName] = utcDate;
                }
            }
            return e;
        }

        average(data: number[]) {
            var total = data.reduce((sum, value) => (sum + value), 0);

            var avg = total / data.length;
            return avg;
        }

        standardDeviation(values: number[]) {
            var avg = this.average(values);
            var squareDiffs = values.map(value => {
                var diff = value - avg;
                var sqrDiff = diff * diff;
                return sqrDiff;
            });

            var avgSquareDiff = this.average(squareDiffs);

            var stdDev = Math.sqrt(avgSquareDiff);
            return stdDev;
        }

        // PRIVATE
        private promptAction(message: string, defaultValue: string, okCallback: ((_: string) => any)) {
            bootbox.prompt({
                title: message,
                value: defaultValue,

                buttons: {
                    cancel: {
                        label: this.trans.Cancel,
                        className: "btn-default"
                    },
                    confirm: {
                        label: this.trans.OK,
                        className: "btn-primary"
                    }
                },
                callback(result) {
                    if (result !== null) {
                        okCallback(result);
                    }
                }
            });

        }

        private confirmAction(title: string, message: string, okCallback: ((_: boolean) => any), cancelCallback: ((_: boolean) => any)) {
            bootbox.confirm({
                title: title,
                message: message,
                buttons: {
                    cancel: {
                        label: this.trans.Cancel,
                        className: "btn-default"
                    },
                    confirm: {
                        label: this.trans.OK,
                        className: "btn-primary"
                    }
                },
                callback(result) {
                    if (result) {
                        okCallback(result);
                    } else {
                        if (cancelCallback) cancelCallback(result);
                    };
                }
            });
        }


        private getUidFromIdInGrid(grid: any, id: number) {
            var gridItems = grid.dataItems();
            for (var i = 0; i < gridItems.length; i++) {
                if (gridItems[i].Id === id) return gridItems[i].uid;
            }
            return null;
        }

    }

    angular.module('app').factory('helperService', ['$http', '$q', '$translate', HelperService.Factory]);
}

