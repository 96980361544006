module App {
    export class AuthInterceptor implements ng.IHttpInterceptor {

        public static Factory($q: ng.IQService, localStorageService: any, navigationService: NavigationService) {
            return new AuthInterceptor($q, localStorageService, navigationService);
        }

        constructor(
            private $q: ng.IQService,
            private localStorageService: any,
            private navigationService: NavigationService,
        ) {

        }


        request = (config: ng.IRequestConfig): ng.IRequestConfig=> {
            var authData = this.localStorageService.get('authorizationData');
            if (authData) {
                config.headers = config.headers || {};
                config.headers['Authorization']  = 'Bearer ' + authData.token;
            }
            return config;
        }

        requestError = (requestFailure: any): ng.IPromise<any> => {
            return requestFailure;
        }

        response = (response: any): ng.IPromise<any> =>{
            return response || this.$q.when(response);
        }

        responseError = (rejection: any): ng.IPromise<any> =>{
            // data format: rejection {config: Object, data: "NotAuthorized", headers: function, status: 401, statusText: "Unauthorized"}
            try {
                var data = angular.fromJson(rejection.data);
                if (rejection.status === 401 && data.Exception === "AccessDeniedException") {
                    this.navigationService.redirectToLogin();
                //} else if (rejection.status === 401 && data.Message) { // WebAPI Default Filter
                //    this.navigationService.redirectToLogin();
                } else if (rejection.status === 400 && data.Exception === "RedirectAppException") {
                    this.navigationService.redirectToUrl(data.TranslateParams.target);
                } else if (rejection.status === 401 && data.Exception === "TwoFactorException") {
                    this.navigationService.redirectToTwoFactor();
                }

            } finally {
                return this.$q.reject(rejection);

            }
        }
    }

    angular.module('app').factory('authInterceptor', ['$q', 'localStorageService', 'navigationService', AuthInterceptor.Factory]);
}