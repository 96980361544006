module App {
    export class NavigationService {
        public static Factory($location: ng.ILocationService, $state: angular.ui.IStateService, $timeout: ng.ITimeoutService, $window: ng.IWindowService) {
            return new NavigationService($location, $state, $timeout, $window);
        }

        constructor(
            private $location: ng.ILocationService,
            private $state: angular.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private $window: ng.IWindowService,
            ) { 

        }

        //ToDo: Save Success State
        private redirectState: string;
        private redirectParams: { [key: string]: string };

        isRedirected() {
            return this.redirectState ? true : false;
        };

        // used for transition change
        setRedirectRoute(state: string, params: any) {
            if (state === "login" || state === "twoFactorLogin") return;
            this.redirectState = state;
            this.redirectParams = params;
            console.info("setRedirectRoute: ", this.redirectState, this.redirectParams);
        }

        // used by authInterceptor
        redirectToLogin() {
            if (this.$state.current.name === "login") return;
            this.redirectState = this.$state.current.name;
            this.redirectParams = angular.copy(this.$state.params);
            console.info("redirectToLogin: ", this.redirectState, this.redirectParams);
            this.$state.go('login');
        }

        // used by authInterceptor, authService.init and authService.kendoError
        redirectToTwoFactor() {
            if (this.$state.current.name === "twoFactorLogin") return;
            this.redirectState = this.$state.current.name;
            this.redirectParams = angular.copy(this.$state.params);
            console.info("redirectToTwoFactor: ", this.redirectState, this.redirectParams);
            this.$state.go('twoFactorLogin');
        }

        // used by successful login / 2fa
        navigateBack() {
            if (this.isRedirected()) {
                const to = "" + this.redirectState;
                const toP = angular.copy(this.redirectParams);
                this.redirectState = null;
                this.redirectParams = null;
                console.info("Navigate to ", to, toP);
                this.$state.go(to, toP);
            } else {
                this.$state.go('home');
            }
        }

        // abort authentication and navigate back to last successful view
        navigateSuccess() {
            //ToDo navigateSuccess
        }

        redirectToUrl(url: string) {
            console.info("redirectToServerDefined", url);
            if (url.indexOf('#') === 0) { // internal redirect
                var target = url.substring(1); //location service requires to strip #
                if (this.$location.path() !== "/NoAdmin") {
                    var ret = this.$location.path(target);
                }
            } else {// External Redirect
                this.$timeout(() => {
                    this.$window.location.href = url;
                }, 3000);

            }
            
        }


    }

    angular.module('app').factory('navigationService', ['$location', '$state', '$timeout', '$window', NavigationService.Factory]);
}

