module App {
    export interface IFilesystemClipboard {
        type: string;
        id: number | null;
        name: string;
        action: string;
        parentFolderId: number | null;
        timewarp: Date | null;
    }

    export class FilesystemService {
        public static Factory(
            $http: ng.IHttpService,
            $rootScope: ng.IRootScopeService,
            $timeout: ng.ITimeoutService,
            $q: ng.IQService,
            $filter: ng.IFilterService,
            $window: ng.IWindowService,
            $translate: angular.translate.ITranslateService,
            appConfig: AppConfig,
            helperService: HelperService,
            authService: AuthService) {
            return new FilesystemService($http, $rootScope, $timeout, $q, $filter, $window, $translate, appConfig, helperService, authService);
        }

        constructor(
            private $http: ng.IHttpService,
            private $rootScope: ng.IRootScopeService,
            private $timeout: ng.ITimeoutService,
            private $q: ng.IQService,
            private $filter: ng.IFilterService,
            private $window: ng.IWindowService,
            private $translate: angular.translate.ITranslateService,
            private appConfig: AppConfig,
            private helperService: HelperService,
            private authService: AuthService) {
            this.$translate([
                "Datarooms.Filesystem.PasteInvalid",
                "Datarooms.Filesystem.PasteIdent",
                "Datarooms.Filesystem.DuringCopy",
                "Datarooms.Filesystem.FinishCopy",
                "Datarooms.Filesystem.DuringMove",
                "Datarooms.Filesystem.FinishMove",
                "Datarooms.Filesystem.TimewarpDisabled",
            ]).then(t=> {
                this.trans.PasteInvalid = t["Datarooms.Filesystem.PasteInvalid"];
                this.trans.PasteIdent = t["Datarooms.Filesystem.PasteIdent"];
                this.trans.DuringCopy = t["Datarooms.Filesystem.DuringCopy"];
                this.trans.FinishCopy = t["Datarooms.Filesystem.FinishCopy"];
                this.trans.DuringMove = t["Datarooms.Filesystem.DuringMove"];
                this.trans.FinishMove = t["Datarooms.Filesystem.FinishMove"];
                this.trans.TimewarpDisabled = t["Datarooms.Filesystem.TimewarpDisabled"];
            });
        }

        // PROPS
        private trans = { PasteInvalid: "", PasteIdent: "", DuringCopy: "", FinishCopy: "", DuringMove: "", FinishMove: "", TimewarpDisabled: "" };
        private clipboard: IFilesystemClipboard = { type: "file", id: null, name: "", action: "copy", parentFolderId: null, timewarp: null };
        private timewarp: Date | null = null;

        // PUBLIC
        // CLIPBOARD
        // =========
        pasteFromClipboard(targetFolderId: number, successCallback: Function) {
            if (!this.clipboard.id) return;
            if (this.timewarp) {
                this.helperService.alert("Datarooms.Filesystem.PasteInvalid", true);
                return;
            }
            if (!this.clipboard.timewarp && targetFolderId === this.clipboard.parentFolderId) { 
                this.helperService.alert("Datarooms.Filesystem.PasteIdent", true);
                this.setClipboardMessage("");
                return;
            }
            if (this.clipboard.type === "folder") {
                if (this.clipboard.action === "copy") {
                    this.setClipboardMessage(this.clipboard.name + this.trans.DuringCopy);
                    var folderPromise = this.clipboard.timewarp
                        ? this.appConfig.request.Restore.CopyFolder(this.$http, this.clipboard.id, targetFolderId, this.clipboard.timewarp)
                        : this.appConfig.request.Folder.Copy(this.$http, this.clipboard.id, targetFolderId);

                    folderPromise.then(() => {
                        this.clipboard.id = null;
                        this.setClipboardMessage(this.clipboard.name + this.trans.FinishCopy, 5000);
                        if (angular.isFunction(successCallback)) successCallback();
                    }, errResponse => {
                        this.setClipboardMessage("");
                        this.helperService.error(errResponse);
                    });

                } else if (this.clipboard.action === "cut") {
                    if (this.clipboard.timewarp) return;
                    this.setClipboardMessage(this.clipboard.name + this.trans.DuringMove);
                    this.appConfig.request.Folder.Move(this.$http, this.clipboard.id, targetFolderId).then(() => {
                        this.clipboard.id = null;
                        this.setClipboardMessage(this.clipboard.name + this.trans.FinishMove, 5000);
                        if (angular.isFunction(successCallback)) successCallback();
                    }, errResponse => {
                        this.setClipboardMessage("");
                        this.helperService.error(errResponse);
                    });

                }

            } else if (this.clipboard.type === "file") {
                if (this.clipboard.action === "copy") {
                    this.setClipboardMessage(this.clipboard.name + this.trans.DuringCopy);
                    var filePromise = this.clipboard.timewarp
                        ? this.appConfig.request.Restore.CopyFile(this.$http, this.clipboard.id, targetFolderId, this.clipboard.timewarp)
                        : this.appConfig.request.File.Copy(this.$http, this.clipboard.id, targetFolderId);

                    filePromise.then(() => {
                        this.clipboard.id = null;
                        this.setClipboardMessage(this.clipboard.name + this.trans.FinishCopy, 5000);
                        if (angular.isFunction(successCallback)) successCallback();
                    }, errResponse => {
                        this.setClipboardMessage("");
                        this.helperService.error(errResponse);
                    });

                } else if (this.clipboard.action === "cut") {
                    if (this.clipboard.timewarp) return;
                    this.setClipboardMessage(this.clipboard.name + this.trans.DuringMove);
                    this.appConfig.request.File.Move(this.$http, this.clipboard.id, targetFolderId).then(() => {
                        this.clipboard.id = null;
                        this.setClipboardMessage(this.clipboard.name + this.trans.FinishMove, 5000);
                        if (angular.isFunction(successCallback)) successCallback();
                    }, errResponse => {
                        this.setClipboardMessage("");
                        this.helperService.error(errResponse);
                    });

                }

            } else {
                console.error("Unknown type: ", this.clipboard.type);
            }

        }

        copyFolderToClipboard(folderId: number, folderName:string, parentFolderId: number) {
            this.clipboard.action = "copy";
            this.clipboard.type = "folder";
            this.clipboard.id = folderId;
            this.clipboard.name = folderName;
            this.clipboard.parentFolderId = parentFolderId;
            this.clipboard.timewarp = this.timewarp;
            if (this.timewarp) {
                this.$translate("Datarooms.Filesystem.TimewarpFolderCopy", { name: folderName }).then(msg=> {
                    this.setClipboardMessage(msg);
                });
            } else {
                this.$translate("Datarooms.Filesystem.FolderCopy", { name: folderName }).then(msg => {
                    this.setClipboardMessage(msg);
                });
            }
        }

        cutFolderToClipboard(folderId: number, folderName: string, parentFolderId: number) {
            if (this.timewarp) {
                this.helperService.alert("Datarooms.Filesystem.TimewarpFolderInvalid", true);
                return;
            }
            this.clipboard.action = "cut";
            this.clipboard.type = "folder";
            this.clipboard.id = folderId;
            this.clipboard.name = folderName;
            this.clipboard.parentFolderId = parentFolderId;
            this.clipboard.timewarp = null;
            this.$translate("Datarooms.Filesystem.FolderCut", { name: folderName }).then(msg => {
                this.setClipboardMessage(msg);
            });
        }

        copyFileToClipboard(fileId: number, fileName: string) {
            this.clipboard.action = "copy";
            this.clipboard.type = "file";
            this.clipboard.id = fileId;
            this.clipboard.name = fileName;
            this.clipboard.timewarp = this.timewarp;
            if (this.timewarp) {
                this.$translate("Datarooms.Filesystem.TimewarpFileCopy", { name: fileName }).then(msg => {
                    this.setClipboardMessage(msg);
                });
            } else {
                this.$translate("Datarooms.Filesystem.FileCopy", { name: fileName }).then(msg => {
                    this.setClipboardMessage(msg);
                });
            }
        }

        cutFileToClipboard(fileId: number, fileName: string) {
            if (this.timewarp) {
                this.helperService.alert("Datarooms.Filesystem.TimewarpFolderInvalid", true);
                return;
            }
            this.clipboard.action = "cut";
            this.clipboard.type = "file";
            this.clipboard.id = fileId;
            this.clipboard.name = fileName;
            this.clipboard.timewarp = null;
            this.$translate("Datarooms.Filesystem.FileCut", { name: fileName }).then(msg => {
                this.setClipboardMessage(msg);
            });
        }

// DRIVE ACTIONS
// ==============
        getDriveStatus(successCallback: (status:ITenantDriveStatus)=>any) {
            this.appConfig.request.Folder.DriveStatus(this.$http).then(okResponse => {
                successCallback(okResponse.data);
            }, this.helperService.error);

        }


// FOLDER ACTIONS
// ==============
        getFolder(folderId: number): ng.IPromise<IFolderControlViewModel> {
            const promise = angular.isDate(this.timewarp)
                ? this.appConfig.request.Restore.GetFolder(this.$http, folderId, this.timewarp)
                : this.appConfig.request.Folder.Details(this.$http, folderId);

            return promise.then(f => {
                return f.data;
            }, err => {
                this.helperService.error(err);
            });
        }

        getShares(successCallback: (f: IFolderViewModel[]) => any, errorCallback: Function) {
            this.appConfig.request.Folder.GetShares(this.$http).then(okResponse=> {
                if (angular.isFunction(successCallback)) successCallback(okResponse.data);
            }, errResponse=> {
                this.helperService.alert("Datarooms.Filesystem.GetFolderErr", true);
                if (angular.isFunction(errorCallback)) errorCallback(errResponse.data);
            });
        }

        getPublicShares(companyId: string, successCallback: (f:IFolderViewModel[])=>any, errorCallback: Function) {
            this.appConfig.request.Folder.GetPublicShares(this.$http, companyId).then(okResponse => {
                if (angular.isFunction(successCallback)) successCallback(okResponse.data);
            }, errResponse=> {
                if (angular.isFunction(errorCallback)) errorCallback(errResponse.data);
                this.helperService.alert("Datarooms.Filesystem.GetFolderErr", true);
            });
        }

        getPublic(shareId: string, folderId: number, pin: string, successCallback: (f:IFolderControlViewModel)=>any, errorCallback: Function) {
            if (shareId && folderId) {
                this.appConfig.request.Folder.PublicDetails(this.$http, folderId, shareId, pin)
                    .then(response => {
                        if (angular.isFunction(successCallback)) successCallback(response.data);
                    }, response => {
                        console.error(response);
                        if (angular.isFunction(errorCallback)) errorCallback(response.data);
                    });


            } else if (shareId) {
                this.appConfig.request.Folder.PublicDetails(this.$http, null, shareId, pin)
                    .then(response => {
                        if (angular.isFunction(successCallback)) successCallback(response.data);
                    }, response => {
                        console.error(response);
                        if (angular.isFunction(errorCallback)) errorCallback(response.data);
                    });
            }
        }

        createFolder(parentFolderId: number, successCallback: ()=>any) {
            if (this.timewarp) {
                this.helperService.alert("Datarooms.Filesystem.TimewarpFolderInvalid", true);
                return;
            }
            this.helperService.prompt("Datarooms.Filesystem.CreateFolderPrompt", "", result => {
                this.appConfig.request.Folder.Create(this.$http, parentFolderId, result).then(() => {
                    successCallback();
                }, this.helperService.error);

            }, true);
        }

        renameFolder(folderId: number, oldName: string, successCallback: (newName:string)=>any) {
            if (this.timewarp) {
                this.helperService.alert("Datarooms.Filesystem.TimewarpFolderInvalid", true);
                return;
            }
            this.helperService.prompt("Datarooms.Filesystem.RenameFolderPrompt", oldName, result => {
                this.appConfig.request.Folder.Rename(this.$http, folderId, result).then(() => {
                    successCallback(result);
                }, this.helperService.error);

            }, true);

        }

        removeFolder(folderId: number, successCallback: ()=>any) {
            if (this.timewarp) {
                this.helperService.alert("Datarooms.Filesystem.TimewarpFolderInvalid", true);
                return;
            }
            this.helperService.confirm("Core.Message.Warning", "Datarooms.Filesystem.DeleteFolderPrompt", ()=> {
                this.appConfig.request.Folder.Delete(this.$http, folderId).then(()=> {
                    this.authService.refreshFavorites();
                    if (angular.isFunction(successCallback)) successCallback();
                }, this.helperService.error);

            }, null, true);
        }

// FAVORITES
// =========
        isFolderFavorite(folderId: number): ng.IPromise<boolean> {
            return this.authService.init(false, false).then(cu => {
                var items = this.$filter('filter')(cu.favorites, { 'Id': folderId });
                return items && items.length>0;
            });
        }

        // non-async call is enough; no callbacks needed
        addFolderToFavorites(folderId: number, name: string, successCallback: Function, errorCallback: Function) {

            this.appConfig.request.Folder.AddToFavorites(this.$http, folderId).then(() => {
                this.authService.refreshFavorites();
                if (angular.isFunction(successCallback)) successCallback();
            }, () => {
                this.authService.refreshFavorites();
                if (angular.isFunction(errorCallback)) errorCallback();
            });
        }

        // non-async call is enough; no callbacks needed
        removeFolderFromFavorites(folderId: number, successCallback: Function, errorCallback: Function) {
            this.appConfig.request.Folder.RemoveFromFavorites(this.$http, folderId).then(() => {
                this.authService.refreshFavorites();
                if (angular.isFunction(successCallback)) successCallback();
            }, () => {
                this.authService.refreshFavorites();
                if (angular.isFunction(errorCallback)) errorCallback();
            });

        }

// Timewarp
// ========
        setTimewarp(newTimewarp: Date) {
            //newTimewarp comes in on Local Time including local timezone
            // serialisation in http Requests is done in UTC timezone

            let oldValue = this.timewarp;

            this.timewarp = newTimewarp;
            if (this.timewarp) {
                //$rootScope.clipboardMessage = trans.TimewarpEnabled + timewarp.toLocaleString();
                this.$rootScope['clipboardMessage'] = "";
            } else if (oldValue) {
                this.setClipboardMessage(this.trans.TimewarpDisabled, 1000);
                //$rootScope.clipboardMessage = "";
            }

        }

        getTimewarp() {
            return this.timewarp;
        }


// FILE ACTIONS
// ==============
        renameFile(fileId: number, oldName: string, successCallback:(newName:string)=>any) {
            if (this.timewarp) {
                this.helperService.alert("Datarooms.Filesystem.TimewarpFileInvalid", true);
                return;
            }
            this.helperService.prompt("Datarooms.Filesystem.RenameFilePrompt", oldName, newName=> {
                this.appConfig.request.File.Rename(this.$http, fileId, newName).then(() => {
                    if (angular.isFunction(successCallback)) successCallback(newName);
                }, this.helperService.error);

            }, true);
        }

        removeFile(fileId: number, successCallback: ()=>any) {
            if (this.timewarp) {
                this.helperService.alert("Datarooms.Filesystem.TimewarpFileInvalid", true);
                return;
            }
            this.helperService.confirm("Core.Message.Warning", "Datarooms.Filesystem.DeleteFilePrompt", ()=> {
                this.appConfig.request.File.Delete(this.$http, fileId).then(() => {
                    successCallback();
                }, this.helperService.error);

            }, null, true);

        }

        fileUrl(fileId: number, shareId: string, pin: string) {
            if (angular.isDefined(shareId) && angular.isString(shareId)) {
                return this.appConfig.request.Download.Public(shareId, fileId, pin);
            } else {
                var tw:string = null;
                if (angular.isDate(this.timewarp)) {
                    tw = this.timewarp.toISOString();
                }
                return tw ? this.appConfig.request.Download.Timewarp(fileId, tw) : this.appConfig.request.Download.File(fileId);
            }
        }


        // PRIVATE
        private parseHeaderFilename(headers: ng.IHttpHeadersGetter) {
            var header = headers('content-disposition');
            var result = header.split(';')[1].trim().split('=')[1];
            return result.replace(/"/g, '');
        }

        private setClipboardMessage(message: string, timeout?: number, useTranslate?: boolean) {
            if (useTranslate) {
                this.$translate(message).then(translated => {
                    this.$rootScope['clipboardMessage'] = translated;
                });
            } else {
                this.$rootScope['clipboardMessage'] = message;
            }

            if (timeout) {
                this.$timeout(() => {
                    this.$rootScope['clipboardMessage'] = "";
                }, timeout);
            }
        };

    }


    angular.module('app').factory('filesystemService', [
        '$http', '$rootScope', '$timeout', '$q', '$filter', '$window', '$translate', 'appConfig', 'helperService', 'authService', FilesystemService.Factory
    ]);


}
