module App {
    export class SelectOnClickDirective implements ng.IDirective {
        restrict = 'A';

        static Factory(): ng.IDirectiveFactory {
            const directive = ($window: ng.IWindowService) => new SelectOnClickDirective($window);
            directive.$inject = ['$window'];
            return directive;
        }

        constructor(private $window: ng.IWindowService) {}

        link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) => {
            var that = this;
            element.on('click', function () {
                if (!that.$window.getSelection().toString()) {
                    // Required for mobile Safari
                    //this.setSelectionRange(0, this.value.length);
                }
            });
            
        };
    }


    //angular.module("app").directive('selectOnClick', [
    //    '$window', function($window) {
    //        return {
    //            restrict: 'A',
    //            link: function(scope, element, attrs) {
    //                element.on('click', function() {
    //                    if (!$window.getSelection().toString()) {
    //                        // Required for mobile Safari
    //                        this.setSelectionRange(0, this.value.length);
    //                    }
    //                });
    //            }
    //        };
    //    }
    //]);


    angular.module("app").directive('selectOnClick', SelectOnClickDirective.Factory());
}