module App {
    export class AppShareController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;

        //compute
        rootShare:IFolderViewModel;
        userShares: IFolderViewModel[] = [];
        publicShares: IFolderViewModel[] = [];
        guestShares: IFolderViewModel[] = [];
        listView = false;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$filter', '$state', '$http', 'appConfig', 'helperService', 'filesystemService'];
        constructor(
            private $filter: ng.IFilterService,
            private $state: ng.ui.IStateService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private helperService: HelperService,
            private filesystemService: FilesystemService
        ) {
        }

        $onInit(): void {
            this.refreshFolder();
        }

        // ========================================
        // CALLBACK
        // ========================================
        onSelectFolder(folder: IFolderViewModel) {
            this.$state.go("folderDesigner", { folderId: folder.Id });
        }

        // ========================================
        // PUBLIC
        // ========================================
        //getRoot() {
        //    if (!this.currentUser.isAdmin) return;
        //    this.appConfig.request.Folder.GetRoot(this.$http).then(okResponse => {
        //        this.rootShare = okResponse.data;
        //    }, e => this.helperService.error(e));
        //}

        getShares() {
            this.filesystemService.getShares(data => {
                this.userShares = data;
            }, () => {
                this.userShares = [];
            });
        };


        getPublicShares() {
            this.filesystemService.getPublicShares(this.appConfig.helper.companyId(), data => {
                this.publicShares = this.$filter('filter')(data, { 'PublicPin': false });
                this.guestShares = this.$filter('filter')(data, { 'PublicPin': true });
            }, () => {
                this.publicShares = [];
                this.guestShares = [];

            });
        };

        refreshFolder() {
            if (!this.currentUser.isLoaded) return;
            if (this.currentUser.isAuthenticated) this.getShares();
            this.getPublicShares();
        }


        ownershipNav(o: IOwnerShipViewModel) {
            return o.IsPending ? "#/NoAdmin/" + o.Key : "#/Folder/" + o.Key;
        }

        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppShareComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<'
        };

        controller = AppShareController;
        templateUrl="/GuestTemplate/ShareTemplate";
    }

    angular.module("app").component("appShare", new AppShareComponent());
}

