module App {
    export class RoomInfoController implements ng.IComponentController {
        //binding
        folder: IFolderViewModel;
        isPublic: boolean;

        //compute
        currentUser: IApplicationUserClientStatusViewModel;
        showPublicNav: boolean;
        imageUrl: string;
        publicUrl: string;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$location', 'appConfig', 'authService', '$element'];
        constructor(
            private $location: ng.ILocationService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private $element: ng.IRootElementService
        ) {
        }

        $onInit(): void {
            this.authService.init(false, false).then(cu => this.currentUser = cu);
        }

        $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj['folder'] && onChangesObj['folder'].currentValue && onChangesObj['folder'].currentValue.Id) {
                    this.setNavOptions();
            }

        }


        // ========================================
        // PUBLIC
        // ========================================
        setNavOptions() {

            if (!this.isPublic) {
                this.showPublicNav = angular.isString(this.folder.PublicKey);
            }

            if (this.folder.Id) this.imageUrl = "url('" + this.appConfig.request.Download.FolderImage(this.folder.Design.ImageName, this.folder.Design.IsTenantImage, this.folder.PublicKey) + "')";
            var port = "";
            if (this.$location.protocol() === "http" && this.$location.port() !== 80) port = ":" + this.$location.port();
            if (this.$location.protocol() === "https" && this.$location.port() !== 443) port = ":" + this.$location.port();

            if (this.folder.PublicKey) this.publicUrl = this.$location.protocol() + "://" + this.$location.host() + port + "/Guest/" + this.folder.PublicKey;
        }

        // ========================================
        // PRIVATE
        // ========================================

    }


    export class RoomInfoComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            folder: '<',
            isPublic: '<',
        };

        controller = RoomInfoController;
        //templateUrl='';
        template = '<div class="roomInfo" ng-show="$ctrl.folder" >' +
                        '<div class="row">' +
                            '<div class="col-lg-offset-1 col-md-4" >' +
                                    '<div class="roomImage" ng-style="{\'background-image\': $ctrl.imageUrl}"></div>' +
                            '</div>' + //COL1
                            '<div class="col-md-6">' +
                                '<div class="designText" ng-bind-html="$ctrl.folder.Design.Text2"></div>' +

                                '<div class="publicShareInfo bg-info" ng-show="$ctrl.folder.PublicKey && $ctrl.currentUser.isAuthenticated && $ctrl.currentUser.isFullLicense">' +
                                    '<h4><span class="glyphicon glyphicon-globe"></span> <span translate>Datarooms.RoomInfo.PublicTip</span></h4>' +
                                    '<p translate>Datarooms.RoomInfo.LinkDescription</p>' +
                                    '<direct-link public-key="$ctrl.folder.PublicKey"></direct-link>' +
                                    //'<input type="text" ng-model="$ctrl.publicUrl" class="form-control input-sm" readonly select-on-click />' +
                                    '<div ng-show="!$ctrl.isPublic"><a class="btn btn-primary btn-xs" ui-sref="public({shareId: $ctrl.folder.PublicKey})" translate>Datarooms.RoomInfo.GoToPublic</a></div>' +
                                    '<div ng-show="$ctrl.isPublic"><a class="btn btn-success btn-xs" ui-sref="folder({folderId: $ctrl.folder.Id})" translate>Datarooms.RoomInfo.GoToPrivate</a></div>' +
                                '</div>' +
                            '</div>' + //COL2
                        '</div>' + //row
                    '</div>'
        ;
    }

    angular.module("app").component("roomInfo", new RoomInfoComponent());
}



