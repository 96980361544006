module App {
    export class AppStatisticsController implements ng.IComponentController {
        //binding

        //compute
        driveStatus: ITenantDriveStatus;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['filesystemService'];
        constructor(
            private filesystemService: FilesystemService,
        ) {
        }

        $onInit(): void {
            this.getDriveStatus();
        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        getDriveStatus() {
            this.filesystemService.getDriveStatus(data => {
                this.driveStatus = data;
            });
        }

    }


    export class AppStatisticsComponent implements ng.IComponentOptions {
        controller = AppStatisticsController;
        templateUrl= "/UserTemplate/StatisticsTemplate";
    }

    angular.module("app").component("appStatistics", new AppStatisticsComponent());
}
