module App {
    export class AppSearchController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;

        //compute
        search = "";
        searchResults:ISearchResultViewModel;
        activeFile: IFileViewModel = null;
        myPermissions:ICurrentPermissionViewModel = null;
        searchCount = 0;
        pageCount = 0;

        onlyMine = false;
        sortNew = true;
        page = 1;

        searchInProgess = false;
        private processedSearch: string = null;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void { }
        // ========================================
        // PUBLIC
        // ========================================
        setMine(val:boolean) {
            this.onlyMine = val;
            this.searchDelay();
        };

        setSort(val:boolean) {
            this.sortNew = val;
            this.searchDelay();
        };

        setCombined (mine:boolean, sort:boolean) {
            this.onlyMine = mine;
            this.sortNew = sort;
            this.searchDelay();
        };

        searchDelay() {
            if (this.searchInProgess) return;
            this.searchInProgess = true;
            this.$timeout(() => this.searchAction(), 500); 

        };



        // ========================================
        // CALLBACK
        // ========================================
        onSelectFile(file: IFileViewModel) {
            if (file === this.activeFile) {
                this.activeFile = null;
            } else {
                this.activeFile = file;
                this.getMyPermissions(file.FolderId);
                //console.log("onSelectFile", this.activeFile);
            }

        };

        onRefresh() {
            this.searchAction();
        }

        // ========================================
        // PRIVATE
        // ========================================
        private getMyPermissions(folderId: number) {
            this.appConfig.request.Folder.GetMyPermissions(this.$http, folderId).then(okResponse => {
                this.myPermissions = okResponse.data;
            }, e => this.helperService.error(e));
        };


        private searchAction () {
            this.processedSearch = this.search;
            this.appConfig.request.Search.Search(this.$http, this.search, this.onlyMine, this.sortNew, this.page)
                .then(okResult => {
                    this.searchResults = okResult.data;
                    this.searchCount = this.searchResults.FilesTotal + this.searchResults.FoldersTotal;
                    this.pageCount = Math.ceil(this.searchCount / 10);
                    if (this.search !== this.processedSearch) {
                        this.searchAction();
                    } else {
                        this.searchInProgess = false;
                    }

                }, e=> this.helperService.error(e));
        };

        private range(min: number, max: number, step: number) {
            step = step || 1;
            var input: number[] = [];
            for (var i = min; i <= max; i += step) {
                input.push(i);
            }
            return input;
        };

    }


    export class AppSearchComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<'
        };

        controller = AppSearchController;
        templateUrl="/UserTemplate/SearchTemplate";
    }

    angular.module("app").component("appSearch", new AppSearchComponent());
}