module App {
    export class LanguageSwitcherController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;

        //compute

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['localeService'];
        constructor(
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void { }
        // ========================================
        // PUBLIC
        // ========================================

        // ========================================
        // PRIVATE
        // ========================================
        private lang(locale:string) {
            this.localeService.setLocale(locale);
        }

    }


    export class LanguageSwitcherComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            //currentUser: '<'
        };

        controller = LanguageSwitcherController;
        //templateUrl='';
        // https://www.iconfinder.com/iconsets/flags_gosquared
        template = `
                <div class="languages">
                    <img src="/Content/icons/en-uk.png" ng-click="$ctrl.lang('en')" />
                    <img src="/Content/icons/en-us.png" ng-click="$ctrl.lang('en')" />
                    <img src="/Content/icons/de-at.png" ng-click="$ctrl.lang('de')" />
                    <img src="/Content/icons/de-de.png" ng-click="$ctrl.lang('de')" />
                    <img src="/Content/icons/it.png" ng-click="$ctrl.lang('it')" />
                </div>'
        `;
    }

    angular.module("app").component("languageSwitcher", new LanguageSwitcherComponent());
}
