module App {
    export interface ISelectFileCallbackObject {
        file: IFileViewModel
    }

    export class FileButtonController implements ng.IComponentController {
        //binding
        file: IFileViewModel;
        shareId: string;
        pin: string;
        activeFile: IFileViewModel;
        selectFile: (cbo: ISelectFileCallbackObject)=>void;
        listView: boolean;

        //compute
        filetypeClass: string;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['appConfig', 'authService', 'filesystemService', '$element'];
        constructor(
            private appConfig: AppConfig,
            private authService: AuthService,
            private filesystemService: FilesystemService,
            private $element: ng.IRootElementService
        ) {
        }

        $onInit(): void { }

        $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj['file'] && onChangesObj['file'].currentValue) {
                this.filetypeClass = this.getFiletype();
            };

    }


        // ========================================
        // PUBLIC
        // ========================================
        clickHandler() {
            if (angular.isDefined(this.activeFile)) {
                this.selectFile({file: this.file});
            } else {
                this.authService.setTokenCookie();
                window.location.href = this.filesystemService.fileUrl(this.file.Id, this.shareId, this.pin);
            }
        };



        getFiletype():string {
            if (this.file.HasThumb) {
                let path: string;
                if (this.shareId) {
                    path = this.appConfig.request.Download.PublicThumb(this.shareId, this.file.Id, this.pin);
                    
                } else if (this.filesystemService.getTimewarp()) {
                    path = this.appConfig.request.Download.ThumbTimewarp(this.file.Id, this.filesystemService.getTimewarp().toISOString());
                } else {
                    path = this.appConfig.request.Download.Thumb(this.file.Id);
                }

                var iconContainer = $(this.$element).find('.icon div');
                this.authService.setTokenCookie();
                iconContainer.css('background-image', 'url(' + path + ')');
                //iconContainer.css('background-size', 'cover');

                return "";
            }
            if (!this.file || !this.file.Name || !(this.file.Name.split('.').length > 1)) return "";
            var parts = this.file.Name.split('.');
            var fileextension = parts[parts.length - 1].toLowerCase();

            for (var type in this.filetypeDefinition) {
                if (this.filetypeDefinition.hasOwnProperty(type)) {
                    var pos = this.filetypeDefinition[type].indexOf(fileextension);
                    if (pos !== -1) return type;
                }
            }
            return "filetype-other";
        };
        // ========================================
        // PRIVATE
        // ========================================
        private filetypeDefinition: {[typename:string]:string[]} = {
            'filetype-word': ['doc', 'docx', 'dot', 'dotx', 'docm', 'dotm', 'rtf', 'odt', 'wpd'],
            'filetype-excel': ['xls', 'xlsx', 'xslm', 'ods', 'csv',],
            'filetype-powerpoint': ['ppt', 'pptx', 'pptm', 'odp'],
            'filetype-text': ['txt', 'log', 'ini'],
            'filetype-image': ['gif', 'jpg', 'jpeg', 'png', 'ico', 'bmp', 'tif', 'ico'],
            'filetype-pdf': ['pdf'],
            'filetype-sound': ['wav', 'mp3', 'aac', 'ogg', 'wma', 'flac', 'm4a'],
            'filetype-video': ['avi', 'divx', 'mpg', 'mp4', 'mkv', 'mov', 'qt', 'mp2'],
            'filetype-archive': ['zip', 'rar', '7z', 'tar', 'gz', 'bz2']
        };


    }


    export class FileButtonComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            file: '<', // required
            shareId: '<', // public access key
            pin: '<',
            activeFile: '<',
            selectFile: '&',
            listView: '=',
        };

        controller = FileButtonController;
        //templateUrl='';
        template = 
            '<div class="btn-file" ng-class="{box: $ctrl.listView==false, list: $ctrl.listView===true}" ng-click="$ctrl.clickHandler()">' +
                '<div class="icon" >' +
                    '<div class="{{$ctrl.filetypeClass}}"></div>' +
                '</div>' +
                '<div class="content">' +
                    '<p>{{$ctrl.file.Name}} <span ng-if="$ctrl.file.CheckOutDate" class="btn btn-info btn-xs">CHECKED OUT by {{$ctrl.file.CheckOutUserName}}</span></p>' +
                    '<p class="pull-right text-muted" ng-show="$ctrl.listView">{{$ctrl.file.Date | date:"short"}} | {{$ctrl.file.SizeBytes | byteReadable}}</p>' +
                '</div>' +
            '</div>';
    }

    angular.module("app").component("fileButton", new FileButtonComponent());
}


