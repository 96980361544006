module App {
    export class FastLinkController implements ng.IComponentController {

        //binding
        favoriteFolders: IFavoriteViewModel[];

        //compute

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        $onInit(): void {
        }

        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class FastLinkComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            favoriteFolders: '<',
        };

        controller = FastLinkController;
        template = `
            <h4 translate>Datarooms.FastLink.Header</h4>
            <a ng-repeat="f in $ctrl.favoriteFolders" ui-sref="folder({folderId: f.Id})">{{f.Name}}</a>


            
            <span class="text-muted" translate>Datarooms.FastLink.Description</span>
        `;
        //<folder-button ng-repeat="f in $ctrl.favoriteFolders" folder="f" click-button="nav" show-icon="false"></folder-button>
    }

    angular.module("app").component("fastLink", new FastLinkComponent());
}


