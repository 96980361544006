module App {
    export class FolderActionController implements ng.IComponentController {
        //binding
        folder: IFolderViewModel;
        permissions: ICurrentPermissionViewModel;
        currentUser: IApplicationUserClientStatusViewModel;
        refresh: ()=>void;
        showTimewarp: boolean;

        //compute
        timewarpEnabled: boolean;
        cutConfirm: boolean;
        copyConfirm: boolean;
        pasteConfirm: boolean;
        IsFavorite: boolean;

        view = {
            showSecurity: false, // Used for Security Page Navigation
            showDesign: false,
            showCreateFolder: false, // Used for NEW/RENAME Buttons
            showDelete: false, //Used for CUT/DELTE Buttons
            showUpload: false, //Used for PASTE Button
            showInformation: true,
            copyConfirm: false,
            cutConfirm: false,
            pasteConfirm: false,
            showTimewarpButton: false,
            showNoAdmin: false,
        }

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$state', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService', 'filesystemService'];
        constructor(
            private $scope: ng.IScope,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService,
            private filesystemService: FilesystemService
        ) {
        }

        $onInit(): void {
            this.timewarpEnabled = this.filesystemService.getTimewarp() !== null;
        }

        //$onChanges(onChangesObj: angular.IOnChangesObject): void {
        $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj['folder'] && onChangesObj['folder'].currentValue) {
                this.timewarpEnabled = this.filesystemService.getTimewarp() !== null;

                this.getFavoriteStatus(this.folder.Id);
                if (this.permissions) this.setViewOptions();

                this.cutConfirm = false;
                this.copyConfirm = false;
                this.pasteConfirm = false;
                
            }

            if (onChangesObj['permissions'] && onChangesObj['permissions'].currentValue) {
                this.setViewOptions();
            }

        }


        // ========================================
        // PUBLIC
        // ========================================
        close(duration:number = 300) {
            this.$timeout(()=> { this.folder = null; }, duration);
        }

        toggleTimewarpArea() {
            this.showTimewarp = !this.showTimewarp;
            this.close();
        }

        setViewOptions() {
            var p = this.permissions;
            this.view.showUpload = !this.timewarpEnabled && (p.CanAdmin || p.CanUpload);
            this.view.showSecurity = !this.timewarpEnabled && p.CanAdmin; 
            this.view.showDesign = !this.timewarpEnabled && p.CanAdmin;
            this.view.showCreateFolder = !this.timewarpEnabled && (p.CanAdmin || p.CanCreateFolder);
            this.view.showDelete = !this.timewarpEnabled && (p.CanAdmin || p.CanDelete);
            this.view.showTimewarpButton = p.CanAdmin;
            this.view.showNoAdmin = !this.timewarpEnabled && this.currentUser.isFullLicense; 
            this.view.showInformation = !this.timewarpEnabled;
        }


        newFolder () {
            if (!this.view.showCreateFolder) return;
            this.filesystemService.createFolder(this.folder.Id, ()=> {
                this.folder = null;
                if (angular.isFunction(this.refresh())) this.refresh();
            });
        };

        upload() {
            if (!this.view.showUpload) return;
            this.$state.go("upload", {folderId: this.folder.Id});
        }

        security() {
            if (!this.view.showSecurity) return;
            this.$state.go("folderSecurity", { folderId: this.folder.Id });
        }

        noadmin() {
            if (!this.view.showNoAdmin) return;
            this.$state.go("noAdmin", { folderId: this.folder.Id });
        }

        design() {
            if (!this.view.showDesign) return;
            this.$state.go("folderDesigner", { folderId: this.folder.Id });
        }
        info() {
            if (!this.view.showInformation) return;
            this.$state.go("folderInformation", { folderId: this.folder.Id });
        }

        renameFolder () {
            if (!this.view.showCreateFolder) return;
            this.filesystemService.renameFolder(this.folder.Id, this.folder.Name, data => {
                this.folder.Name = data;
                this.folder = null;
            });
        };

        removeFolder(folderId:number) {
            if (!this.view.showDelete) return;
            if (!folderId) return;
            this.filesystemService.removeFolder(folderId, () => {
                if (this.folder.ParentId) this.$state.go("folder", { folderId: this.folder.ParentId }); 
                this.close();
                if (angular.isFunction(this.refresh)) this.refresh();
            });
        };

        copyToClipboard() {
            this.copyConfirm = true;
            this.filesystemService.copyFolderToClipboard(this.folder.Id, this.folder.Name, this.folder.ParentId);
            this.close();

            if (this.timewarpEnabled) {
                this.helperService.alert("Datarooms.FolderAction.RestoreDescription", true, {name: this.folder.Name});
            }
        }

        cutToClipboard() {
            if (!this.view.showDelete) return;
            this.cutConfirm = true;
            this.filesystemService.cutFolderToClipboard(this.folder.Id, this.folder.Name, this.folder.ParentId);
            this.close();
        }

        pasteFromClipboard() {
            if (!this.view.showUpload) return;
            this.pasteConfirm = true;
            this.filesystemService.pasteFromClipboard(this.folder.Id, () => {
                this.refresh();
            });
            this.close();
        }

        getFavoriteStatus(folderId:number) {
            if (!isFinite(folderId)) return;
            this.filesystemService.isFolderFavorite(folderId).then(okResponse => {
                this.IsFavorite = okResponse;
            });
        }

        toggleFavorite () {
            if (this.IsFavorite) {
                this.IsFavorite = false;
                this.filesystemService.removeFolderFromFavorites(this.folder.Id, null, null);
            } else {
                this.IsFavorite = true;
                this.filesystemService.addFolderToFavorites(this.folder.Id, this.folder.Name, null, null);
            }
            this.close();
        }


        // ========================================
        // PRIVATE
        // ========================================

    }


    export class FolderActionComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            folder: '<',
            permissions: '<',
            refresh: '&',
            currentUser: '<',
            showTimewarp: '=',
        };

        controller = FolderActionController;
        //templateUrl='';
        template = 
            '<div ng-show="$ctrl.folder" class="folderAction">' +
                '<h3 class="header"><span translate>Datarooms.FolderAction.Title</span> &quot;{{$ctrl.folder.Name}}&quot; </h3>' +
                '<h3 class="close"><span class="glyphicon glyphicon-remove-circle pull-right clickable" ng-click="$ctrl.folder=null"></span></h3>' +
                //'<h3 class="pull-right clickable"><span class="glyphicon glyphicon-remove-circle" ng-click="folder=null"></span></h3>' +
                //'<h3>Aktionen für {{folder.Name}} </h3>'+
                '<p ng-show="!$ctrl.currentUser.isFullLicense" translate>Datarooms.FolderAction.LimitationLightLicense</p>' +
                '<div class="row" ng-hide="$ctrl.timewarpEnabled">' +
                    '<div class="col-xs-6">' +
                        '<a ng-disabled="!$ctrl.view.showCreateFolder" ng-show="$ctrl.currentUser.isFullLicense" class="btn btn-primary btn-block btn-sm " ng-click="$ctrl.newFolder()"><span class="glyphicon glyphicon-folder-open"></span> <span translate>Datarooms.FolderAction.NewFolder</span></a>' +
                        '<a ng-disabled="!$ctrl.view.showUpload" class="btn btn-primary btn-block btn-sm " ng-click="$ctrl.upload()"><span class="glyphicon glyphicon-cloud-upload"></span> <span translate>Datarooms.FolderAction.Upload</span></a>' +
                        '<a ng-disabled="!$ctrl.view.showDelete" ng-show="$ctrl.currentUser.isFullLicense" class="btn btn-primary btn-block btn-sm " ng-click="$ctrl.removeFolder($ctrl.folder.Id)"><span class="glyphicon glyphicon-trash"></span> <span translate>Datarooms.FolderAction.Delete</span></a>' +
                        '<a ng-disabled="!$ctrl.view.showDelete" ng-show="$ctrl.currentUser.isFullLicense" class="btn btn-default btn-block btn-sm " ng-click="$ctrl.cutToClipboard()"><span class="glyphicon glyphicon-scissors"></span> <span translate>Datarooms.FolderAction.Cut</span><span class="glyphicon glyphicon-ok pull-right" ng-show="$ctrl.cutConfirm"></span></a>' +
                        '<a ng-show="$ctrl.currentUser.isFullLicense" class="btn btn-default btn-block btn-sm " ng-click="$ctrl.copyToClipboard()"><span class="glyphicon glyphicon-copy" ></span> <span translate>Datarooms.FolderAction.Copy</span><span class="glyphicon glyphicon-ok pull-right" ng-show="$ctrl.copyConfirm"></span></a>' +
                        '<a ng-disabled="!$ctrl.view.showUpload" ng-show="$ctrl.currentUser.isFullLicense" class="btn btn-default btn-block btn-sm " ng-click="$ctrl.pasteFromClipboard()"><span class="glyphicon glyphicon-paste"></span> <span translate>Datarooms.FolderAction.Paste</span><span class="glyphicon glyphicon-ok pull-right" ng-show="$ctrl.pasteConfirm"></span></a>' +
                        '<a ng-disabled="!$ctrl.view.showCreateFolder" ng-show="$ctrl.currentUser.isFullLicense" class="btn btn-default btn-block btn-sm " ng-click="$ctrl.renameFolder()"><span class="glyphicon glyphicon-pencil"></span> <span translate>Datarooms.FolderAction.Rename</span></a>' +
                    '</div>' +
                    '<div class="col-xs-6">' +
                        '<a ng-show="$ctrl.permissions.CanAdmin" ng-disabled="!$ctrl.view.showSecurity" class="btn btn-warning btn-block btn-sm " ng-click="$ctrl.security()" ><span class="glyphicon glyphicon-lock"></span> <span translate>Datarooms.FolderAction.Security</span></a>' +
                        '<a ng-show="$ctrl.permissions.CanAdmin" ng-disabled="!$ctrl.view.showNoAdmin" class="btn btn-warning btn-block btn-sm " ng-click="$ctrl.noadmin()" ><span class="glyphicon glyphicon-ban-circle"></span> <span translate>Datarooms.FolderAction.NoAdmin</span></a>' +
                        '<a ng-show="$ctrl.permissions.CanAdmin" ng-disabled="!$ctrl.view.showDesign" class="btn btn-warning btn-block btn-sm " ng-click="$ctrl.design()"><span class="glyphicon glyphicon-eye-open"></span> <span translate>Datarooms.FolderAction.Designer</span></a>' +
                        '<a ng-show="$ctrl.permissions.CanAdmin" class="btn btn-block btn-sm" ng-click="$ctrl.toggleTimewarpArea()" ng-class="{\'btn-warning\': !$ctrl.showTimewarp, \'btn-success\': $ctrl.showTimewarp}">' +
                        '<span class="glyphicon glyphicon-time"></span> <span translate>Datarooms.FolderAction.Timewarp</span>' +
                        '</a>' +


                        '<a ng-show="$ctrl.currentUser.isFullLicense" ng-disabled="!$ctrl.view.showInformation" class="btn btn-default btn-block btn-sm " ng-click="$ctrl.info()"><span class="glyphicon glyphicon-info-sign"></span> <span translate>Datarooms.FolderAction.Information</span></a>' +
                        '<a class="btn btn-default btn-block btn-sm " ng-click="$ctrl.toggleFavorite()" ng-class="{\'btn-default\': !$ctrl.IsFavorite, \'btn-success\': $ctrl.IsFavorite}">' +
                        '<span class="glyphicon glyphicon-pushpin"></span> <span translate>Datarooms.FolderAction.Favorite</span>' +
                        '</a>' +

                    '</div>' +
                '</div>' +
                '<div class="row" ng-show="$ctrl.timewarpEnabled">' +
                    '<div class="col-xs-6">' +
                        '<a ng-show="$ctrl.currentUser.isFullLicense" class="btn btn-default btn-block btn-sm " ng-click="$ctrl.copyToClipboard()"><span class="glyphicon glyphicon-time" ></span> <span translate>Datarooms.FolderAction.Restore</span><span class="glyphicon glyphicon-ok pull-right" ng-show="$ctrl.copyConfirm"></span></a>' +
                    '</div>' +
                    '<div class="col-xs-6">' +
                    '</div>' +
                '</div>' +
            '</div>' +
            '<div ng-class="{background: $ctrl.folder}" ng-click="$ctrl.close(0)" ></div>';
    }

    angular.module("app").component("folderAction", new FolderActionComponent());
}
