module App {
    export interface IFolderButtonCallbackObject {
        folder: IFolderViewModel
    }

    export class FolderButtonController implements ng.IComponentController {
        //binding
        folder: IFolderViewModel;
        clickButton: 'shareId' | 'nav' | 'download';
        clickIcon: (folder: IFolderButtonCallbackObject)=>void;
        showIcon: boolean;

        //compute

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$element', '$state', 'uploadService', 'appConfig', 'authService'];
        constructor(
            private $element: ng.IRootElementService,
            private $state: ng.ui.IStateService,
            private uploadService: UploadService,
            private appConfig: AppConfig,
            private authService: AuthService
        ) {
        }

        $onInit(): void {
            this.addEventListener();
        }

        $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj['folder'] && onChangesObj['folder'].currentValue) {
                $(this.$element).tooltip();
            }
        }

        // ========================================
        // PUBLIC
        // ========================================
        nav() {
            if (this.clickButton === 'download') {
                this.authService.setTokenCookie();
                window.location.href = this.appConfig.request.Download.ZipFolder(this.folder.Id);
            } else if (this.clickButton === 'nav') {
                this.$state.go("folder", {folderId: this.folder.Id});
            } else if (angular.isString(this.clickButton)) {
                this.$state.go("public", { shareId: this.clickButton, folderId: this.folder.Id}); 
            } else {
                console.error("Invalid Parameter clickButton: ", this.clickButton);
            }
        }

        // ========================================
        // PRIVATE
        // ========================================
        private addEventListener() {
            this.$element[0].addEventListener('drop', (e: any) => {
                e.stopPropagation();
                e.preventDefault();
                this.uploadService.setFolder(this.folder);
                
                //used from flow.js
                let flow = this.uploadService.flow as any;
                var dataTransfer = (e as any).dataTransfer;
                if (dataTransfer.items && dataTransfer.items[0] &&
                    dataTransfer.items[0].webkitGetAsEntry) {
                    flow.webkitReadDataTransfer(e);
                } else {
                    flow.addFiles(dataTransfer.files, e);
                }

            });
        }
    }


    export class FolderButtonComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            folder: '<', // required
            clickButton: '@', // shareId, nav, download
            clickIcon: '&', //optional, otherwise only nav button
            showIcon: '@', //bool: dummy icon instead of option icon
        };

        controller = FolderButtonController;
        //templateUrl='';
        template = 
            '<div class="btn-folder list" ng-click="$ctrl.nav()">' +
                '<div class="icon" >' +
                    '<div class="filetype-folder" ng-show="$ctrl.showIcon!==\'true\'" ng-click="$ctrl.clickIcon({folder: $ctrl.folder}); $event.stopPropagation()"  data-toggle="tooltip" data-placement="bottom" title="Ordner Aktionen..."></div>' +
                    '<div class="filetype-folder2" ng-show="$ctrl.showIcon===\'true\'"></div>' +
                '</div>' +

                '<div class="content">' +
                    '{{$ctrl.folder.Name}} ' +
                    '<span ng-show="$ctrl.folder.OwnerId" class="label label-danger" kendo-tooltip k-content="$ctrl.folder.OwnerName"> NO-Admin </span>' +
        '<span ng-show="$ctrl.folder.PublicKey" class="glyphicon glyphicon-globe pull-right" data-toggle="tooltip" data-placement="bottom" title="{{\'Datarooms.Folder.PublicLinkTooltip\' | translate}}"></span>' +
        '<span ng-show="$ctrl.folder.IsShared" class="glyphicon glyphicon-user pull-right" data-toggle="tooltip" data-placement="bottom" title="{{\'Datarooms.Folder.UserTooltip\' | translate}}"></span>' +
        '<span class="text-danger"><span ng-show="$ctrl.folder.IsTwoFactor" class="glyphicon glyphicon-lock pull-right" data-placement="bottom" title="{{\'Datarooms.Folder.SecureTooltip\' | translate}}"></span></span>' +
                '</div>' +
            '</div>';
    }

    angular.module("app").component("folderButton", new FolderButtonComponent());
}
