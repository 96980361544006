module App {
    export class AppNoAdminController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;

        //compute
        folderId: number;
        folderIsValid = false;
        vm: INoAdminViewModel;

        input = {
            codeGive: "",
            codeTake: "",
            keyTake: "",
            codeRecover: "",
            keyRecover: "",
            keyRecoverNew: "",
            keyRemove: "",
            codeRemove: "",
        }

        view = {
            NoAccess: false,
            GiveFolderOwnership: false,
            TakeOwnership: false,
            RecoverOwnership: false,
            RemoveOwnership: false,
        }

        usersOptions: kendo.ui.DropDownListOptions;
        newUser: kendo.ui.DropDownList;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', '$q', '$scope', '$timeout', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService'];
        constructor(
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void {
            this.getData();
        }
        // ========================================
        // PUBLIC
        // ========================================
        setView() {
            this.view.NoAccess = !this.folderIsValid;

            // cases: has ownership set? --> is it me? --> is it pending?

            // has ownership set? 
            if (this.vm && !this.vm.IsInherited) {
                if (this.vm.OwnerId === this.currentUser.UserId) { //my ownership
                    if (this.vm.IsPending) { // my ownership, is pending
                        this.view.GiveFolderOwnership = false;
                        this.view.TakeOwnership = true;
                        this.view.RecoverOwnership = false;
                        this.view.RemoveOwnership = false;
                    } else { // my ownership, not pending
                        this.view.GiveFolderOwnership = false;
                        this.view.TakeOwnership = false;
                        this.view.RecoverOwnership = false;
                        this.view.RemoveOwnership = true;
                    }
                } else { // foreign ownership
                    this.view.GiveFolderOwnership = false;
                    this.view.TakeOwnership = false;
                    this.view.RecoverOwnership = this.currentUser.isFullLicense;
                    this.view.RemoveOwnership = this.currentUser.isFullLicense;

                }
            } else { // no ownership set
                this.view.GiveFolderOwnership = this.vm.CanAdmin;
                this.view.TakeOwnership = false;
                this.view.RecoverOwnership = false;
                this.view.RemoveOwnership = false;

            }

            if (this.view.GiveFolderOwnership) this.usersOptions = this.getUsersDataSource();
        }

        getData() {
            this.$q.all<ng.IHttpPromiseCallbackArg<INoAdminViewModel>, IApplicationUserClientStatusViewModel>([
                this.appConfig.request.NoAdmin.GetOwnership(this.$http, this.folderId),
                this.authService.init(true, true),
            ]).then(returnValues => {
                let p1 = returnValues[0] as ng.IHttpPromiseCallbackArg<INoAdminViewModel>;
                this.vm = p1.data;
                this.currentUser = returnValues[1] as IApplicationUserClientStatusViewModel;
                this.folderIsValid = true;
                this.setView();

            }, err => {
                this.folderIsValid = false;
                this.setView();
            });

        }

        giveFolderOwnership() {
            this.appConfig.request.NoAdmin.GiveFolderOwnership(this.$http, this.folderId, this.newUser.dataItem().Id, this.input.codeGive)
                .then(okResponse => {
                    this.helperService.alert("Datarooms.NoAdmin.Success", true);
                    this.input.codeGive = "";
                    this.getData();
                }, errResponse => {
                    this.helperService.error(errResponse);
                    this.input.codeGive = "";
                });
        }

        takeOwnershipRequest() {
            this.appConfig.request.NoAdmin.TakeFolderOwnershipRequest(this.$http, this.folderId)
                .then(okResponse => {
                    this.input.keyTake = okResponse.data;
                });
        }

        takeOwnershipCommit() {
            this.appConfig.request.NoAdmin.TakeOwnershipCommit(this.$http, this.folderId, this.input.codeTake)
                .then(okResponse => {
                    this.helperService.alert("Datarooms.NoAdmin.Success", true);
                    this.input.codeTake = "";
                    this.input.keyTake = "";
                    this.getData();

                }, errResponse => {
                    this.helperService.error(errResponse);
                    this.input.codeTake = "";

                });
        }

        recoverOwnership() {
            this.appConfig.request.NoAdmin.RecoverOwnership(this.$http, this.folderId, this.input.keyRecover, this.input.codeRecover)
                .then(okResponse => {
                    this.helperService.alert("Datarooms.NoAdmin.Success", true);
                    this.input.keyRecover = "";
                    this.input.codeRecover = "";
                    this.input.keyRecoverNew = okResponse.data;
                    this.getData();

                }, errResponse => {
                    this.helperService.error(errResponse);
                    this.input.codeRecover = "";
                });
        }

        removeOwnership() {
            this.appConfig.request.NoAdmin.RemoveOwnership(this.$http, this.folderId, this.input.keyRemove, this.input.codeRemove)
                .then(okResponse => {
                    this.helperService.alert("Datarooms.NoAdmin.Success", true);
                    this.input.keyRemove = "";
                    this.input.codeRemove = "";
                    this.getData();
                }, errResponse => {
                    this.helperService.error(errResponse);
                    this.input.codeRemove = "";
                });
        }



        // ========================================
        // PRIVATE
        // ========================================
        private getUsersDataSource(): kendo.ui.DropDownListOptions {
            return {
                "dataSource": {
                    "transport": {
                        read: this.appConfig.helper.http('get', this.appConfig.request.UserManagement.UsersUrl, this.authService.getBearerTokenObject()),
                    },
                    "filter": [
                        { "field": "IsInternal", "operator": "eq", "value": true },
                        { "field": "IsEnabled", "operator": "eq", "value": true },
                    ],
                    "schema": {
                        "model": {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "string" },
                                "FirstName": { "type": "string" },
                                "LastName": { "type": "string" },
                                "IsEnabled": { "type": "boolean" },
                                "LastLogon": { "editable": false, "type": "date", "defaultValue": null as any },
                                "FullName": { "type": "string" },
                                "UserName": { "type": "string" },
                                "Email": { "type": "string" },
                                "IsAuthenticatorEnabled": { "type": "boolean" },
                                "IsInternal": { "type": "boolean", "editable": false },
                                "AuthenticatorType": { "type": "number" },
                                "TokenSerial": { "type": "string" },
                                "Language": { "type": "number", "field": "Language" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    },

                },
                "dataTextField": "UserName",
                "dataValueField": "Id",
                "filter": "contains"
            }
        };

    }


    export class AppNoAdminComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            folderId: '<',
        };

        controller = AppNoAdminController;
        templateUrl = "/AdminTemplate/NoAdminTemplate";
    }

    angular.module("app").component("appNoAdmin", new AppNoAdminComponent());
}
