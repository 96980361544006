module App {
    interface IAppTenantTokenTrans {
        'Tenant.Token.ExcelPrefix': string;
    }
    export class AppTenantTokenController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        trans: IAppTenantTokenTrans;

        //compute
        grid: kendo.ui.Grid;
        gridOptions: kendo.ui.GridOptions;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', '$scope', '$timeout', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService'];
        constructor(
            private $http: ng.IHttpService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void {
            this.gridOptions = this.getGridOptions();

        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        private getGridOptions(): kendo.ui.GridOptions {
            var that = this;
            return {
                //1. DataSource
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.CompanyManagement.GetTokensUrl, this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 50,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "serverGrouping": true,
                    "serverAggregates": true,
                    "filter": [],
                    "error": function (e:any) {
                        that.authService.kendoError(e, that.grid);
                    },
                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "number" },
                                "Type": { "type": "number", defaultValue: 2 },
                                "Serial": { "type": "string" },
                                "UserId": { "type": "string" },
                                "UserName": { "type": "string" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    }

                },
                //2. Columns
                "columns": [
                    {
                        "field": "Serial",
                        "title": "{{'Tenant.Token.Serial' | translate}}",
                        "filterable": true,
                        "width": 150,
                    }, {
                        "field": "Type",
                        "title": "{{'Tenant.Token.Type' | translate}}",
                        "filterable": true,
                        "values": [
                            //{ value: -1, text: "Hardware & Software" },
                            //{ value: 0, text: "Keine" },
                            { value: 1, text: "{{'Tenant.Token.MobileApp' | translate}}" },
                            { value: 2, text: "{{'Tenant.Token.SecurityToken' | translate}}" },
                        ]
                    }, {
                        "field": "UserName",
                        "title": "{{'Tenant.Token.User' | translate}}",
                        "filterable": true,
                    }
                ],

                // 3. Features
                "editable": false,
                "toolbar": [
                    { name: "excel", "buttonType": "ImageAndText", "text": "{{'Tenant.Token.ExcelExport' | translate}}" }
                ],
                "pageable": true,
                "sortable": true,
                "scrollable": false,
                "filterable": {
                    mode: "menu",
                    operators: this.localeService.getKendoFilterTranslationObject()
                },
                "excel": { allPages: true, fileName: this.trans["Tenant.Token.ExcelPrefix"] + ".xlsx", filterable: true },
            }
        }
    }


    export class AppTenantTokenComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            trans: '<',
        };

        controller = AppTenantTokenController;
        templateUrl = "/AdminTemplate/TenantTokenTemplate";
    }

    angular.module("app").component("appTenantToken", new AppTenantTokenComponent());
}

