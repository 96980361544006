module App {
    export class SelectAuthorizeController implements ng.IComponentController {
        //binding
        blockExtern: boolean;
        onSelect: Function;

        //compute
        current: IPermissionDefinitionViewModel;
        window: kendo.ui.Window;
        windowOptions: kendo.ui.WindowOptions;
        title: string;

        newInternalUserOptions: kendo.ui.DropDownListOptions;
        newExternalUserOptions: kendo.ui.DropDownListOptions;
        newInternalGroupOptions: kendo.ui.DropDownListOptions;
        newExternalGroupOptions: kendo.ui.DropDownListOptions;

        newInternalUser: kendo.ui.DropDownList;
        newExternalUser: kendo.ui.DropDownList;
        newInternalGroup: kendo.ui.DropDownList;
        newExternalGroup: kendo.ui.DropDownList;


        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', '$scope', '$translate', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService'];
        constructor(
            private $http: ng.IHttpService,
            private $scope: ng.IScope,
            private $translate: ng.translate.ITranslateService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void {
            this.$translate('Datarooms.SelectAuthorize.Title').then(t => {
                this.title = t;
                this.windowOptions = this.getWindowOptions();
            });

            this.newInternalUserOptions = this.getUsersOptions(true);
            this.newExternalUserOptions = this.getUsersOptions(false);
            this.newInternalGroupOptions = this.getGroupOptions(true);
            this.newExternalGroupOptions = this.getGroupOptions(false);

        }
        $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj && onChangesObj['show'] && onChangesObj['show'].currentValue) {
                this.open();
            }

        }

        // ========================================
        // PUBLIC
        // ========================================
        open() {
            this.current = this.defaultValue();
            this.window.center().open();
        }

        ok() {
            if (this.blockExtern && !this.current.IsFullLicense) {
                this.helperService.alert('Datarooms.SelectAuthorize.NoExternalDescription', true, null);
                return;
            }

            if (this.current.IsUser) {
                this.current.UserOrGroupId = this.current.IsFullLicense
                    ? this.newInternalUser.dataItem().Id
                    : this.newExternalUser.dataItem().Id;
                this.current.UserOrGroupName = this.current.IsFullLicense
                    ? this.newInternalUser.dataItem().UserName
                    : this.newExternalUser.dataItem().UserName;
            } else {
                this.current.UserOrGroupId = this.current.IsFullLicense
                    ? this.newInternalGroup.dataItem().Id
                    : this.newExternalGroup.dataItem().Id;
                this.current.UserOrGroupName = this.current.IsFullLicense
                    ? this.newInternalGroup.dataItem().Name
                    : this.newExternalGroup.dataItem().Name;
            }

            if (!this.current.UserOrGroupId) {
                this.helperService.alert('Core.Message.ErrorInField', true, null);
                return;
            }


            this.onSelect({ auth: this.current });
            this.current = this.defaultValue();
            this.window.close();
        }

        cancel() {
            this.current = this.defaultValue();
            this.window.close();
        }
        // ========================================
        // PRIVATE
        // ========================================
        private defaultValue():IPermissionDefinitionViewModel {
            return {
                IsUser: false,
                IsFullLicense: true,
                CanCreateFolder: false,
                CanDelete: false,
                CanUpload: false,
                UserOrGroupId: null,
                UserOrGroupName: null,
                CanReadLogs: false,
                CanManagePermissions: false,
                CanManageOptions: false,
                DenyAllAccess: false,
            }
        }

        private getUsersOptions(isInternal: boolean): kendo.ui.DropDownListOptions {
            return {
                dataTextField: 'UserName',
                dataValueField: 'Id',
                dataSource: {
                    "type": 'webapi',
                    transport: {
                        read: this.appConfig.helper.http('get', this.appConfig.request.UserManagement.UsersUrl, this.authService.getBearerTokenObject()),
                    },
                    filter: { field: "IsInternal", operator: "eq", value: isInternal },
                    "schema": {
                        "model": {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "string" },
                                "FirstName": { "type": "string" },
                                "LastName": { "type": "string" },
                                "IsEnabled": { "type": "boolean" },
                                "FullName": { "type": "string" },
                                "UserName": { "type": "string" },
                                "IsInternal": { "type": "boolean" },

                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    },


                },
                filter: 'contains'

            }
        }

        private getGroupOptions(isInternal: boolean): kendo.ui.DropDownListOptions {
            return {
                dataTextField: 'Name',
                dataValueField: 'Id',
                dataSource: {
                    "type": 'webapi',
                    transport: {
                        read: this.appConfig.helper.http('get', this.appConfig.request.UserManagement.GroupsUrl, this.authService.getBearerTokenObject()),
                    },
                    filter: { field: "IsInternal", operator: "eq", value: isInternal },
                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "string" },
                                "Name": { "type": "string" },
                                "IsInternal": { "type": "boolean", "defaultValue": true },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",
                    }

                },
                filter: 'contains'

            }

        }

        private getWindowOptions(): kendo.ui.WindowOptions {
            return {
                title: this.title,
                visible: false,
                modal: true,
                draggable: true,
                width: 250
            }
        }

    }


    export class SelectAuthorizeComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            blockExtern: '<',
            onSelect: '&'
        };

        controller = SelectAuthorizeController;
        template = `
            <div class="btn btn-default" translate ng-click="$ctrl.open()" >Core.Buttons.Add</div>
            <div kendo-window="$ctrl.window" k-options="$ctrl.windowOptions" >
                <p class="strong" translate>Datarooms.SelectAuthorize.Select</p>

                <br/>
                <p translate>Datarooms.SelectAuthorize.InternExtern</p>
                <div class="btn-group btn-group-justified" role="group">
                    <a type="button" class="btn" translate ng-class="{'btn-default': !$ctrl.current.IsFullLicense, 'btn-success': $ctrl.current.IsFullLicense}" ng-click="$ctrl.current.IsFullLicense = true">Datarooms.SelectAuthorize.Intern</a>
                    <a type="button" class="btn" translate ng-class="{'btn-default': $ctrl.current.IsFullLicense, 'btn-success': !$ctrl.current.IsFullLicense}" ng-click="$ctrl.current.IsFullLicense = false">Datarooms.SelectAuthorize.Extern</a>
                </div>

                <br/>
                <p translate>Datarooms.SelectAuthorize.UserGroup</p>
                <div class="btn-group btn-group-justified" role="group">
                    <a type="button" class="btn" translate ng-class="{'btn-default': !$ctrl.current.IsUser, 'btn-success': $ctrl.current.IsUser}" ng-click="$ctrl.current.IsUser = true">Datarooms.SelectAuthorize.User</a>
                    <a type="button" class="btn" translate ng-class="{'btn-default': $ctrl.current.IsUser, 'btn-success': !$ctrl.current.IsUser}" ng-click="$ctrl.current.IsUser = false">Datarooms.SelectAuthorize.Group</a>
                </div>

                <br/>
                <p translate>Datarooms.SelectAuthorize.Name</p>
                <select kendo-drop-down-list="$ctrl.newInternalGroup" k-options="$ctrl.newInternalGroupOptions" ng-show="$ctrl.current.IsFullLicense && !$ctrl.current.IsUser" style="width: 100%"></select>
                <select kendo-drop-down-list="$ctrl.newInternalUser" k-options="$ctrl.newInternalUserOptions" ng-show="$ctrl.current.IsFullLicense && $ctrl.current.IsUser" style="width: 100%"></select>
                <select kendo-drop-down-list="$ctrl.newExternalGroup" k-options="$ctrl.newExternalGroupOptions"  ng-disabled="$ctrl.blockExtern"  ng-show="!$ctrl.current.IsFullLicense && !$ctrl.current.IsUser" style="width: 100%"></select>
                <select kendo-drop-down-list="$ctrl.newExternalUser" k-options="$ctrl.newExternalUserOptions" ng-disabled="$ctrl.blockExtern"  ng-show="!$ctrl.current.IsFullLicense && $ctrl.current.IsUser" style="width: 100%"></select>
                
                <br/>
                <hr/>
                <div>
                    <span class="btn btn-default pull-right" ng-click="$ctrl.cancel()" translate>Core.Buttons.Cancel</span>
                    <span class="btn btn-default pull-right strong" ng-click="$ctrl.ok()" translate>Core.Buttons.Add</span>
                </div>
            </div>
        `;
    }

    angular.module("app").component("selectAuthorize", new SelectAuthorizeComponent());
}