module App {
    export class TimewarpController implements ng.IComponentController {
        //binding
        isVisible: boolean;
        onChange: ()=>void;

        //compute
        timewarp: Date = this.filesystemService.getTimewarp();
        timewarpEnabled: boolean = this.filesystemService.getTimewarp() !== null;
        showTimewarpFutureWarning: boolean = false;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['filesystemService', 'helperService'];
        constructor(
            private filesystemService: FilesystemService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void { }

        $onChanges(onChangesObj: ng.IOnChangesObject): void {
            if (onChangesObj['isVisible']) {
                if (onChangesObj['isVisible'].currentValue === onChangesObj['isVisible'].previousValue) return;
                if (onChangesObj['isVisible'].currentValue) {
                    if (this.timewarp === null) this.timewarp = new Date();
                } else {
                    this.setTimewarp(false);
                }
            }

        }

        // ========================================
        // PUBLIC
        // ========================================
        setTimewarp(isEnabled: boolean) {
            const oldIsEnabled = this.timewarpEnabled;
            const oldValue = this.timewarp;
            if (isEnabled && !angular.isDate(this.timewarp)) {
                this.helperService.alert('Tenant.Timewarp.NoDateErr', true);
                return;
            }
            this.timewarpEnabled = isEnabled;
            this.setValue(oldIsEnabled, oldValue);
        }

        addDay () {
            const oldValue = this.timewarp;
            this.timewarp = moment(this.timewarp).add(1, 'days').toDate();
            this.setValue(this.timewarpEnabled, oldValue);
        }
        addHour () {
            const oldValue = this.timewarp;
            this.timewarp = moment(this.timewarp).add(1, 'hours').toDate();
            this.setValue(this.timewarpEnabled, oldValue);
        }
        subDay () {
            const oldValue = this.timewarp;
            this.timewarp = moment(this.timewarp).subtract(1, 'days').toDate();
            this.setValue(this.timewarpEnabled, oldValue);
        }
        subHour () {
            const oldValue = this.timewarp;
            this.timewarp = moment(this.timewarp).subtract(1, 'hours').toDate();
            this.setValue(this.timewarpEnabled, oldValue);
        }
        // ========================================
        // PRIVATE
        // ========================================
        private setValue(oldIsEnabled: boolean, oldValue: Date) {
            if (this.timewarpEnabled) {
                this.filesystemService.setTimewarp(this.timewarp);
            } else {
                this.filesystemService.setTimewarp(null);
            }

            if (oldIsEnabled !== this.timewarpEnabled) {
                this.onChange();
            } else if(this.timewarpEnabled && oldValue!==this.timewarp) {
                this.onChange();
            }

            var current = new Date();
            this.showTimewarpFutureWarning = this.timewarp > current;

        }
    }


    export class TimewarpComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            //timewarp: '=',
            isVisible: '<',
            //timewarpEnabled: '=',
            onChange: '&',
        };

        controller = TimewarpController;
        template =
            '<div ng-show="$ctrl.isVisible">'+
                '<h2 translate>Tenant.Timewarp.Heading</h2>' +
                '<p translate>Tenant.Timewarp.Description</p>' +
                '<div class="row">' +
                    '<div class="col-md-6">'+
                        '<input kendo-date-time-picker k-ng-model="$ctrl.timewarp" style="width: 100%;" />' +
                    '</div>' +
                    '<div class="col-md-6">' +
                        '<div class="btn-group" role="group" aria-label="..." >' +
                            '<span class="btn btn-default" ng-click="$ctrl.subDay()"><span class="glyphicon glyphicon-fast-backward clickable" ></span></span>' +
                            '<span class="btn btn-default" ng-click="$ctrl.subHour()"><span class="glyphicon glyphicon glyphicon-step-backward" ></span></span>' +
                            '<span class="btn btn-default" ng-click="$ctrl.addHour()"><span class="glyphicon glyphicon-step-forward" ></span></span>' +
                            '<span class="btn btn-default" ng-click="$ctrl.addDay()"><span class="glyphicon glyphicon-fast-forward" ></span></span>' +
                            '<button type="button" class="btn" ng-click="$ctrl.setTimewarp(true)" ng-class="{\'btn-default\': !$ctrl.timewarpEnabled, \'btn-success\': $ctrl.timewarpEnabled, disabled: !$ctrl.timewarp || $ctrl.timewarpEnabled}"><span translate>Core.Buttons.On</span></button>' +
                            '<button type="button" class="btn" ng-click="$ctrl.setTimewarp(false)" ng-class="{\'btn-danger\': !$ctrl.timewarpEnabled, \'btn-default\': $ctrl.timewarpEnabled, disabled: !$ctrl.timewarpEnabled}"><span translate>Core.Buttons.Off</span></button>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
                '<p ng-show="$ctrl.showTimewarpFutureWarning" class="alert alert-warning" translate>Tenant.Timewarp.FutureWarning</p>'+
                '<p class="alert alert-success" ng-show="timewarpEnabled" translate>Tenant.Timewarp.EnabledInfo</p>' +
            '</div>'
    ;
    }

    angular.module("app").component("timewarp", new TimewarpComponent());
}

