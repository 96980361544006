module App {
    declare var Flow: any;

    export interface IUploadServiceData {
        Folder: IFolderViewModel;
    }

    export interface IUploadServiceStats {
        isUploading: boolean;
        currentFile: IAppFlowFile;
        filesInQueue: IAppFlowFile[];
        filesFinished: IAppFlowFile[];
        filesError: IAppFlowFile[];
        totalCount: number;
        bytesTotal: number;
        bytesFinished: number;
        percentFinished: number;

    }

    export class UploadService {
        public static Factory($http: ng.IHttpService,
            $q: ng.IQService,
            filterFilter: any,
            appConfig: AppConfig,
            helperService: HelperService,
            conflictService: ConflictService,
            filesystemService: any,
            uploadPrepareService: UploadPrepareService,
            authService: AuthService) {
            return new UploadService($http, $q, filterFilter, appConfig, helperService, conflictService,
                filesystemService, uploadPrepareService, authService);
        }

        constructor(
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private filterFilter: any,
            private appConfig: AppConfig,
            private helperService: HelperService,
            private conflictService: ConflictService,
            private filesystemService: any,
            private uploadPrepareService: UploadPrepareService,
            private authService: AuthService) {

            this.flow = new Flow({
                target: appConfig.request.Upload.FlowUploadUrl,
                withCredentials: true,
                simultaneousUploads: 1,
                chunkSize: 3 * 1024 * 1024, // 3 MB because Azure Block Size Limit is 4 MB
                forceChunkSize: true, // when false (=default) the last chunk can have up to 2 chunkSizes (-1byte)
                allowDuplicateUploads: true, // do not skip file if it is already in the list
                query: (file: IAppFlowFile, chunk: any, isTest: boolean) => ({
                    folderId: file.folderId,
                    overwrite: file.overwrite
                }),
                headers: (file: IAppFlowFile, chunk: any, isTest: boolean) => authService.getBearerTokenObject(),
            });

            this.flow.on('fileSuccess', (file: IAppFlowFile, message: string, chunk: any) => {
                file.isFinished = file.isComplete();
                if (angular.isFunction(this._successCallback)) {this._successCallback(file);}
            });

            this.flow.on('fileProgress', (file: IAppFlowFile, chunk: any) => {
                file.currentProgress = file.progress(false);
            });

            this.flow.on('fileAdded', (file: IAppFlowFile, event: any) => {
                // no target folder selected
                if (!this.Data || !this.Data.Folder || !this.Data.Folder.Id) return false;

                file.baseFolderId = this.Data.Folder.Id;
                file.folderName = this.Data.Folder.Name;
                file.isFinished = false;
                file.errormessage = "";

                // override filename in case of branding usage
                file.name = this.overrideFileName(file);

                uploadPrepareService.add(file);
                return false;
            });


            this.flow.on('filesAdded', (array: any[], event: any) => {
            });

            this.flow.on('filesSubmitted', (array: any[], event: any) => {
            });

            this.flow.on('fileRemoved', (file: IAppFlowFile) => {
            });

            this.flow.on('fileRetry', (file: IAppFlowFile, chunk:any) => {

            });

            this.flow.on('fileError', (file: IAppFlowFile, message: string, chunk: any) => {
                file.errormessage = message;
                console.error("Upload Error at " + file.name + ": " + message);
            });

            this.flow.on('uploadStart', () => {
            });

            this.flow.on('complete', () => {
            });

            this.flow.on('progress', () => {
            });

            this.flow.on('error', (message:string, file: IAppFlowFile, chunk: any) => {
            });

            this.flow.on('catchAll', (event: any, infos: any) => {
                this.updateStats();
            });

            uploadPrepareService.Config.supportDirectory = this.flow.supportDirectory;
            uploadPrepareService.Config.successCallback = (file: IAppFlowFile) => {
                this.flow.files.push(file);
                this.flow.upload();
            };


        }
        // PROPS
        Data: IUploadServiceData = { Folder: null };
        flow: flowjs.IFlow;
        stats: IUploadServiceStats = {
            isUploading: false,
            currentFile: null,
            filesInQueue: [],
            filesFinished: [],
            filesError: [],
            totalCount: 0,
            bytesTotal: 0,
            bytesFinished: 0,
            percentFinished: 0
        };

        private _fileName: (string) = null;
        private _successCallback: (file: IAppFlowFile)=> any;


        // PUBLIC
        setFolder(folder: IFolderViewModel) {
            this.Data.Folder = folder;
        }

        setFolderById(folderId: number): ng.IPromise<IFolderViewModel> {
            return this.getFolder(folderId);
        }

        setFileName(fileName: string) {
            this._fileName = fileName;
        }

        setFolderName(folderName: string) {
            this.Data.Folder.Name = folderName;
        }

        setSuccessCallback(fn: (file: IAppFlowFile) => void) {
            this._successCallback = fn;
        }

        rollback(file: IAppFlowFile) {
            this.appConfig.request.Upload.UploadCleanup(this.$http, file.folderId, file.name);
            file.cancel();
        }

        resetList() {
            this.flow.files = [];
            this.updateStats();
            this.conflictService.clear();
        }


        // PRIVATE
        private currentFile () {
            var enabledForUpload = this.filterFilter(this.flow.files, { isFinished: false, error: false, paused: false });
            if (enabledForUpload.length === 0) {return null;}
            return enabledForUpload[0];
        }

        private filesInQueue () {
            return this.filterFilter(this.flow.files, { isFinished: false, error: false });
        }

        private filesFinished () {
            return this.filterFilter(this.flow.files, { isFinished: true });
        }

        private filesError () {
            return this.filterFilter(this.flow.files, { error: true });
        }

        private isUploading () {
            return this.filterFilter(this.flow.files, { isFinished: false, error: false, paused: false }).length > 0;
        }

        private overrideFileName(file: IAppFlowFile) {
            // exit if the filename is not manually overwritten
            if (this._fileName === null) {return file.name;}

            // if there is no extension, return basic filename
            var parts = file.name.split('.');
            if (parts.length < 2) {return file.name;}

            // take the extension from the input file and use it with the overwritten name
            var extension = parts[parts.length - 1];
            var ret = this._fileName + "." + extension;
            this._fileName = null;
            return ret;
        }

        private getFolder(folderId: number): ng.IPromise<IFolderViewModel> {
            return this.filesystemService.getFolder(folderId).then((data:IFolderControlViewModel) => {
                this.Data.Folder = data.CurrentFolder;
                return this.Data.Folder;
            });
        };

        private updateStats () {
            this.stats.isUploading = this.isUploading();
            this.stats.currentFile = this.currentFile();
            this.stats.filesInQueue = this.filesInQueue();
            this.stats.filesFinished = this.filesFinished();
            this.stats.filesError = this.filesError();
            this.stats.totalCount = this.flow.files.length;
            this.stats.bytesTotal = this.flow.getSize();
            this.stats.bytesFinished = this.flow.sizeUploaded();
            this.stats.percentFinished = this.stats.totalCount > 1
                ? 100. * (this.stats.filesFinished.length + this.stats.filesError.length) / this.stats.totalCount
                : 100. * this.stats.bytesFinished / this.stats.bytesTotal;

        }


    }

    angular.module('app').factory('uploadService', ['$http', '$q', 'filterFilter', 'appConfig', 'helperService', 'conflictService', 'filesystemService',
        'uploadPrepareService', 'authService', UploadService.Factory]);
}

