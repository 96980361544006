module App {
    export class AppWorkerJobsController implements ng.IComponentController {
        //binding

        //compute
        isRunning: boolean;
        message: string;
        ThumbStats: any[];

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', '$scope', '$timeout', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'helperService', 'localeService'];
        constructor(
            private $http: ng.IHttpService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private helperService: HelperService,
            private localeService: LocaleService,
        ) {
        }

        $onInit(): void {
            this.getThumbStats();
        }
        // ========================================
        // PUBLIC
        // ========================================
        getThumbStats () {
            this.isRunning = true;
            this.message = "Please Wait... Loading Stats";
            this.appConfig.request.TenantManagement.ThumbStats(this.$http)
                .then(okResponse => {
                        this.message = okResponse.data.length + " Tenants loaded with Stats";
                        this.isRunning = false;
                        this.ThumbStats = okResponse.data;
                    },
                    errResponse => {
                        this.message = "ERROR";
                        this.isRunning = false;
                        this.helperService.error(errResponse);
                    });
        }

        createThumbs(tenant: IThumbStatsViewModel) {
            if (this.isRunning) return;
            this.isRunning = true;
            this.message = "Please Wait... Creating Thumbs!";
            this.appConfig.request.TenantManagement.CreateThumb(this.$http, tenant.TenantId)
                .then(okResponse => {
                        this.isRunning = false;
                        this.message = "Created " + okResponse.data;
                        tenant.ThumbCount += okResponse.data;
                    },
                    errResponse => {
                        this.helperService.error(errResponse);
                        this.isRunning = false;
                        this.message = "ERROR";
                    });
        }
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class AppWorkerJobsComponent implements ng.IComponentOptions {
        controller = AppWorkerJobsController;
        templateUrl = "/AdminTemplate/WorkerJobsTemplate";
    }

    angular.module("app").component("appWorkerJobs", new AppWorkerJobsComponent());
}
