module App {
    export class ListViewButtonController implements ng.IComponentController {
        //binding
        val: boolean;

        //compute

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService'];
        constructor(
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            if (!angular.isDefined(this.val)) this.val = false;
        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================

    }


    export class ListViewButtonComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            val: '=', 
        };

        controller = ListViewButtonController;
        template = `
                <span class="btn btn-default" ng-show="$ctrl.val" ng-click="$ctrl.val = false"><span class="glyphicon glyphicon-th"></span> <span translate>Datarooms.ListViewButton.GridView</span></span>
                <span class="btn btn-default" ng-show="!$ctrl.val" ng-click="$ctrl.val = true"><span class="glyphicon glyphicon-th-list"></span> <span translate>Datarooms.ListViewButton.ListView</span></span>
        `;
    }

    angular.module("app").component("listViewButton", new ListViewButtonComponent());
}
