module App {
    export class AppFileInformationController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        fileAccessType: ITextValue[];
        fileId: number;

        //compute
        file: IFileViewModel;
        folder: IFolderControlViewModel;
        canHaveThumb: boolean;
        thumbUrl: string;
        myPermissions: ICurrentPermissionViewModel;
        versions: IFileViewModel[];
        shareId: string;
        pin: string;
        gridObject: kendo.ui.Grid;
        gridOptions: kendo.ui.GridOptions;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$timeout', '$http', '$window', 'appConfig', 'authService', 'coreDataService', 'filesystemService', 'localeService', 'helperService', 'navigationService'];
        constructor(
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private $window: ng.IWindowService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private filesystemService: FilesystemService,
            private localeService: LocaleService,
            private helperService: HelperService,
            private navigationService: NavigationService,
        ) {
        }

        $onInit(): void {
            this.getDetails(this.fileId);
        }
        // ========================================
        // PUBLIC
        // ========================================
        fileUrl(fileId:number, shareId:string, pin:string) {
            return this.filesystemService.fileUrl(fileId, shareId, pin);
        }

        downloadCheckOut() {
            this.authService.setTokenCookie();
            this.$window.location.href = this.appConfig.request.Download.FileCheckOut(this.fileId);
        }

        getVersions(fileId: number) {
            this.appConfig.request.Restore.GetFileVersions(this.$http, fileId)
                .then(okResponse => {
                    this.versions = okResponse.data;
                }, e=> this.helperService.error(e));
        }


        getDetails(fileId:number) {
            this.appConfig.request.File.Details(this.$http, fileId)
                .then(okResponse => {
                    this.file = okResponse.data;
                    this.canHaveThumb = this.detectIfThumb();
                    if (this.file.HasThumb) {
                        this.authService.setTokenCookie();
                        this.thumbUrl = this.appConfig.request.Download.Thumb(this.file.Id);
                    }

                    this.getFolder(this.file.FolderId);

                    this.appConfig.request.Folder.GetMyPermissions(this.$http, this.file.FolderId).then(pResponse => {
                        this.myPermissions = pResponse.data;
                        this.getVersions(this.fileId);
                        if (this.myPermissions.CanReadLogs) this.gridOptions = this.getGridOptions();

                    }, e=> this.helperService.error(e));

                }, e=> this.helperService.error(e));
        }

        getFolder(folderId: number) {
            this.appConfig.request.Folder.Details(this.$http, folderId).then(ok => {
                this.folder = ok.data;
            });
        }

        revertVersion(id: number, date: Date) {
            if (!this.myPermissions.CanUpload) {
                this.helperService.alert("Datarooms.FileInformation.RevertPermission", true);
                return;
            }

            this.helperService.confirm('Datarooms.FileInformation.RevertConfirmHeader', 'Datarooms.FileInformation.RevertConfirmText',
                ()=> {
                    console.log(date as any);
                    this.appConfig.request.Restore.RevertFileFromVersion(this.$http, id, date)
                        .then(okResponse => {
                            this.getDetails(this.fileId);
                        }, e=> this.helperService.error(e));
                }, null, true);
        }

        generateThumb() {
            this.appConfig.request.File.GenerateThumb(this.$http, this.fileId)
                .then(okResponse => {
                    this.file.HasThumb = true;
                    this.authService.setTokenCookie();
                    this.thumbUrl = this.appConfig.request.Download.Thumb(this.file.Id);
                }, e=> this.helperService.error(e));
        }

        checkOut() {
            this.helperService.confirm("Datarooms.FileAction.CheckOut", "Datarooms.FileAction.CheckOutDescription", () => {
                this.appConfig.request.File.CheckOut(this.$http, this.fileId).then(ok => {
                    this.file = ok.data;
                }, err => this.helperService.error(err));
            }, null, true);
        }

        checkIn() {
            this.helperService.confirm("Datarooms.FileAction.CheckIn", "Datarooms.FileAction.CheckInDescription", () => {
                this.appConfig.request.File.CheckIn(this.$http, this.fileId).then(ok => {
                    this.file = ok.data;
                }, err => this.helperService.error(err));
            }, null, true);

        }

        checkUndo() {
            this.helperService.confirm("Datarooms.FileAction.CheckUndo", "Datarooms.FileAction.CheckUndoDescription", () => {
                this.appConfig.request.File.CheckUndo(this.$http, this.fileId).then(ok => {
                    this.file = ok.data;
                }, err => this.helperService.error(err));
            }, null, true);

        }

        // ========================================
        // PRIVATE
        // ========================================
        private detectIfThumb() {
            if (this.file.SizeBytes < 10 * 1024) return false;
            if (this.file.SizeBytes > 4 * 1024 * 1024) return false;
            var parts = this.file.Name.split('.');
            var fileextension = parts[parts.length - 1].toLowerCase();
            var allowedExtensions = ['jpg', 'jpeg', 'png', 'bmp', 'gif'];
            if (allowedExtensions.indexOf(fileextension) === -1) return false;
            return true;
        }

        private getGridOptions(): kendo.ui.GridOptions {
            var that = this;
            return {
                // 1. DATASOURCE
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.Eventlog.FileAccessUrl(this.fileId), this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 20,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "sort": { "field": "DateTime", "dir": "desc" },
                    "filter": [],
                    "error": function(e:any) {
                        that.authService.kendoError(e, that.gridObject);
                    },
                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "number" },
                                "DateTime": { "type": "date" },
                                "FileAccessType": { "type": "string" },
                                "User": { "type": "string" },
                                "Details": { "type": "string" },
                                "IpAddress": { "type": "string" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors",

                    },

                },
                // 2. COLUMNS
                "columns": [
                    {
                        "field": "DateTime",
                        "title": "{{'Datarooms.FileInformation.DateTime' | translate}}",
                        "format": "{0:g}",
                        "filterable": true
                    }, {
                        "field": "IpAddress",
                        "title": "{{'Datarooms.FileInformation.IpAddress' | translate}}",
                    }, {
                        "field": "FileAccessType",
                        "title": "{{'Datarooms.FileInformation.FileAccessType' | translate}}",
                        "filterable": true,
                        "values": this.fileAccessType
                    }, {
                        "field": "User",
                        "title": "{{'Datarooms.FileInformation.User' | translate}}",
                        "filterable": true
                    }, {
                        "field": "Details",
                        "title": "{{'Datarooms.FileInformation.Details' | translate}}",
                        "filterable": true
                    }
                ],

                // 3. FEATURES
                "autoBind": true,
                "pageable": true,
                "sortable": true,
                "scrollable": false,
                "filterable": {
                    operators: this.localeService.getKendoFilterTranslationObject()

                },

            }
        };
    }


    export class AppFileInformationComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            fileAccessType: '<',
            fileId: '<',
        };

        controller = AppFileInformationController;
        templateUrl="/UserTemplate/FileInformationTemplate";
    }

    angular.module("app").component("appFileInformation", new AppFileInformationComponent());
}

