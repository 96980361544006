module App {
    export class AuthService {
        public static Factory(
            $cookies: angular.cookies.ICookiesService,
            $http: ng.IHttpService,
            $q: ng.IQService,
            $sce: ng.ISCEService,
            $state: angular.ui.IStateService,
            $location: ng.ILocationService,
            $timeout: ng.ITimeoutService,
            $translate: angular.translate.ITranslateService,
            appConfig: AppConfig,
            helperService: HelperService,
            localeService: LocaleService,
            coreDataService: CoreDataService,
            localStorageService: any,
            navigationService: NavigationService) {
            return new AuthService($cookies, $http, $q, $sce, $state, $location, $timeout, $translate, appConfig, helperService, localeService, coreDataService, localStorageService, navigationService);
        }

        constructor(
            private $cookies: angular.cookies.ICookiesService,
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private $sce: ng.ISCEService,
            private $state: angular.ui.IStateService,
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $translate: angular.translate.ITranslateService,
            private appConfig: AppConfig,
            private helperService: HelperService,
            private localeService: LocaleService,
            private coreDataService: CoreDataService,
            private localStorageService: any,
            private navigationService: NavigationService) {
        }

        data: IApplicationUserClientStatusViewModel = {
            // current user data
            UserId: null,
            username: "",
            isAuthenticated: false,
            IsTwoFactorAuthenticated: false,
            isAdmin: false,
            isManagement: false,
            isFullLicense: false,
            isAuthenticatorEnabled: false,
            authenticatorType: AuthenticatorType.None,
            CompanyId: "",
            Ownerships: [],
            Roles: [],

            // dynamic status data
            isLoaded: false, // currentuser Object already available or still loading?

            // other
            publicPin: "",
            favorites: [],
            logoUrl: null,
        };



        private bearerToken = {
            value: <string> ""
        };

        brandingData = {
            brandingHeader: <string>'',
            brandingText: <string>'',
        }


        //public promise
        refreshFavorites() {
            if (this.data.isAuthenticated) {
                this.appConfig.request.Folder.GetFavorites(this.$http).then(okResponse => {
                    this.data.favorites = okResponse.data ? okResponse.data : [];
                }, errResponse => {
                    console.error(errResponse);
                });
            } else {
                this.data.favorites = [];
            }
        }

        private refreshBranding() {
            this.setTokenCookie();
            $('.brand-background').css('background-image', this.appConfig.request.Download.Background(this.appConfig.helper.companyId(), true));
            $('.brand-banner').css('background-image', this.appConfig.request.Download.Banner(this.appConfig.helper.companyId(), true));
            $('.brand-logo img').attr('src', this.appConfig.request.Download.Logo(this.appConfig.helper.companyId()));
            this.data.logoUrl = this.appConfig.request.Download.Logo(this.appConfig.helper.companyId());
        }


        // private
        private getCurrentUser(): ng.IPromise<IApplicationUserClientStatusViewModel> {
            var deferred = this.$q.defer<IApplicationUserClientStatusViewModel>();
            this.data.isLoaded = false;

            this.appConfig.request.Login.GetCurrentUser(this.$http).then(okResponse => {
                const publicView = this.appConfig.helper.companyId().length > 10;
                if (okResponse.data && !publicView) {
                    this.data.UserId = okResponse.data.UserId;
                    this.data.username = okResponse.data.UserName;
                    this.data.isAuthenticated = okResponse.data.IsAuthenticated;
                    this.data.IsTwoFactorAuthenticated = okResponse.data.IsTwoFactorAuthenticated;
                    this.data.isAdmin = okResponse.data.IsAdmin;
                    this.data.isManagement = okResponse.data.IsManagement;
                    this.data.isFullLicense = okResponse.data.IsFullLicense;
                    this.data.isAuthenticatorEnabled = okResponse.data.IsAuthenticatorEnabled;
                    this.data.authenticatorType = okResponse.data.AuthenticatorType;
                    this.data.CompanyId = okResponse.data.CompanyId;
                    this.data.Ownerships = okResponse.data.Ownerships;
                    this.data.Roles = okResponse.data.Roles;
                } else {
                    this.data.UserId = "";
                    this.data.username = "";
                    this.data.isAuthenticated = false;
                    this.data.IsTwoFactorAuthenticated = false;
                    this.data.isAdmin = false;
                    this.data.isManagement = false;
                    this.data.isFullLicense = false;
                    this.data.isAuthenticatorEnabled = false;
                    this.data.authenticatorType = AuthenticatorType.None;
                    this.data.CompanyId = "";
                    this.data.Ownerships = [];
                    this.data.Roles = [];
                    
                }

                this.refreshBranding();

                //3. Favorites
                this.refreshFavorites();
                
                this.$q.all([
                    //appConfig.request.Login.AntiForgeryToken($http),
                    this.appConfig.request.CompanyManagement.GetBranding(this.$http, this.appConfig.helper.companyId()),
                    this.localeService.init(),
                    //this.coreDataService.loadEnums(),
                ]).then(okResponses => {
                    let brandingResponse = okResponses[0] as ng.IHttpPromiseCallbackArg<IBrandingViewModel>;
                    let branding = brandingResponse.data;
                    this.brandingData.brandingHeader = branding ? branding.Header : "";
                    this.brandingData.brandingText = branding ? this.$sce.trustAsHtml(branding.Text) : "";
                    let colorTheme = branding ? branding.ColorTheme : "";
                    $('head link[href^="/Content/styles/"]').attr('href', '/Content/styles/' + colorTheme + '.css');
                        
                    this.data.isLoaded = true;
                    deferred.resolve(this.data);
                }, errResponse => {
                    console.error(errResponse);
                    deferred.reject(errResponse);
                });

            }, errResponse => {
                console.error(errResponse);
                deferred.reject(errResponse);

            });


            return deferred.promise;

        }

        private deferredCache: (ng.IDeferred<IApplicationUserClientStatusViewModel> | null) = null;

        init(forceReload: boolean, twoFactorRequired: boolean): ng.IPromise<IApplicationUserClientStatusViewModel> {
            var deferred = this.$q.defer<IApplicationUserClientStatusViewModel>();

            if (this.data.isLoaded && twoFactorRequired && !this.data.IsTwoFactorAuthenticated) {
                this.navigationService.redirectToTwoFactor();
                deferred.reject();
                return deferred.promise;
            }


            if (this.deferredCache) return this.deferredCache.promise;

            if (this.data.isLoaded && !forceReload) {
                deferred.resolve(this.data);
            } else {
                this.deferredCache = deferred;
                this.getCurrentUser().then((currentUser: IApplicationUserClientStatusViewModel) => {
                    this.deferredCache = null;
                    deferred.resolve(currentUser);
                }, errResponse=> {
                    this.deferredCache = null;
                    deferred.reject(errResponse);
                });
            }

            return deferred.promise;
        }


        logout() {
            this.appConfig.request.Login.Logout(this.$http).finally(() => {
                this.data.isLoaded = false;
                this.data.isAuthenticated = false;
                this.localStorageService.remove('authorizationData');
                this.localeService.clearLanguage();
                this.bearerToken.value = null;
                this.$cookies.remove('token');
                this.init(true, false).then(() => {
                    this.$location.path("/");
                });
            });

        };

        login(companyid: string, username: string, password: string) {
            var deferred = this.$q.defer();
            //appConfig.request.Login.Login($http, companyid, username, password).then(function (okResponse) {
            this.appConfig.request.Login.TokenLogin(this.$http, companyid, username, password).then(okResponse => {
                var t = {
                    token: okResponse.data ? okResponse.data.access_token : null,
                    userName: okResponse.data ? okResponse.data.username : null
                }

                this.localStorageService.set('authorizationData', t);
                this.bearerToken.value = t.token;
                this.init(true, false).then(ok => {
                    deferred.resolve(okResponse.data);
                });
            }, errResponse => {
                if (this.data.isAuthenticated) this.logout();
                //console.error(angular.fromJson(errResponse.data.error));
                deferred.reject(angular.fromJson(errResponse.data.error));

            });

            return deferred.promise;

        };

        loadAuth() {
            var authData = this.localStorageService.get('authorizationData');
            if (authData) {
                this.bearerToken.value = authData.token;
                this.init(true, false);
            }
        }

        // =====================================
        // 2 Factor Auth
        // =====================================

        // get challenge code from server
        enableAuthenticator(callback: (res: IAuthenticatorViewModel)=>any) {
            this.appConfig.request.Login.ChallengeAuthenticator(this.$http).then(okResponse => {
                callback(okResponse.data);
            }, this.helperService.error);
        }

        enableToken(callback: (res:ITextValue[])=>any) {
            this.appConfig.request.Login.GetUnusedTokens(this.$http).then(okResponse => {
                callback(okResponse.data);
            }, this.helperService.error);
        }

        // bind Software Token to user
        activateAuthenticator(secretKey: string, code: string, callbackSuccess: ()=>any, callbackError: ()=>any) {
            this.appConfig.request.Login.EnableAuthenticator(this.$http, secretKey, code).then(okResponse => {
                if (okResponse.data) {
                    if (angular.isFunction(callbackSuccess)) callbackSuccess();
                } else {
                    if (angular.isFunction(callbackError)) callbackError();
                }
            }, () => {
                if (angular.isFunction(callbackError)) callbackError();
            });

        }

        activateToken(serial: string, code: string, callbackSuccess: ()=>any, callbackError: ()=>any) {
            this.appConfig.request.Login.EnableToken(this.$http, serial, code).then(okResponse => {
                if (okResponse.data) {
                    if (angular.isFunction(callbackSuccess)) callbackSuccess();
                } else {
                    if (angular.isFunction(callbackError)) callbackError();
                }
            }, () => {
                if (angular.isFunction(callbackError)) callbackError();
            });
        }

        disableAuthenticator(callbackSuccess: Function, callbackError: Function) {
            this.helperService.prompt("Core.TwoFactorLogin.DisableAuthenticatorConfirm", null,
                (code: string) => {
                    this.appConfig.request.Login.DisableTwoFactor(this.$http, code).then(okResponse => {
                        if (okResponse.data) {
                            if (angular.isFunction(callbackSuccess)) callbackSuccess(true);
                        } else {
                            if (angular.isFunction(callbackError)) callbackError(false);
                        }
                        if (angular.isFunction(callbackSuccess)) callbackSuccess(okResponse);
                    }, () => {
                        if (angular.isFunction(callbackError)) callbackError();
                    });

                }, true);
        };

        // login with 2 factor credentials
        verifyAuthenticator(code: string, callbackSuccess: (res: boolean)=>any, callbackError: Function) {
            this.appConfig.request.Login.VerifyTwoFactor(this.$http, code).then(okResponse => {
                if (okResponse.data) {
                    if (angular.isFunction(callbackSuccess)) callbackSuccess(true);
                } else {
                    if (angular.isFunction(callbackError)) callbackError(false);
                }
            }, errResponse => {
                if (angular.isFunction(callbackError)) callbackError(errResponse.data);
            });


        }

        // =============================
        // other
        // =============================

        kendoError(e: any, gridObject: kendo.ui.Grid) {
            console.error(e);
            // gridObject is only needed for edit Functions!
            if (gridObject) gridObject.cancelChanges();
            if (e.xhr.status === 401 && e.xhr.responseJSON.Exception === "TwoFactorException") {
                this.navigationService.redirectToTwoFactor();

            } else if (e.xhr.responseJSON.Exception) {
                this.$translate([
                    "Core.Message.Error",
                    "Core.Message.ErrorInField"
                ]).then(t => {
                    // Message Type from Exception Handler
                    var exceptionMessage = "<strong>" + t["Core.Message.Error"] + ": </strong>" + e.xhr.responseJSON.Message + "</br>";
                    angular.forEach(e.xhr.responseJSON.Errors, (val, key) => {
                        exceptionMessage += "</br>" + t["Core.Message.ErrorInField"] + " " + key + "</br>" +
                            angular.forEach(val, errMsg => {
                                exceptionMessage += errMsg + "</br>";
                            });
                    });
                    this.helperService.alert(exceptionMessage, false);
                });


            } else {
                // Message in Kendo DataSourceResponse Format
                var msg = "<strong>Fehler: </strong></br>";
                angular.forEach(e.errors, (val, key) => {
                    if (val.errors)
                        angular.forEach(val.errors, errMsg => {
                            msg += key + ": " + errMsg + "</br>";
                        });
                });
                this.helperService.alert(msg, false);
            }

        }

        getBearerTokenValue() {
            return this.bearerToken.value;
        }

        getBearerTokenObject() {
            var ret: { [key: string]: string } = {};
            if (this.bearerToken.value) {
                ret['Authorization'] = 'Bearer ' + this.bearerToken.value;
            }
            return ret;
        }

        setTokenCookie() {
            if (this.bearerToken.value) {
                const current = new Date();
                const expire = new Date(current.getTime() + 30000);
                this.$cookies.put('token', this.bearerToken.value, { expires: expire });
            }
        }

        inRole(roleName: string): boolean {
            return this.data.Roles.indexOf(roleName) > -1;
        }

    }

    angular.module("app").service("authService", [
        '$cookies', '$http', '$q', '$sce', '$state', '$location', '$timeout', '$translate', 'appConfig', 'helperService', 'localeService', 'coreDataService', 'localStorageService', 'navigationService', AuthService.Factory
    ]);
}