module App {
    export class AppSystemeventController implements ng.IComponentController {
        //binding
        eventlogAction: ITextValue[];

        //compute
        gridOptions: kendo.ui.GridOptions;
        gridObject: kendo.ui.Grid;
        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['appConfig', 'authService', 'localeService', 'helperService'];

        constructor(
            private appConfig: AppConfig,
            private authService: AuthService,
            private localeService: LocaleService,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            this.gridOptions = this.getGridOptions();
        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        private getGridOptions(): kendo.ui.GridOptions {
            var that = this;
            return {
                "columns": [
                    {
                        "field": "DateTime",
                        "title": "{{'Datarooms.Eventlog.DateTime' | translate}}",
                        "format": "{0:g}",
                        "filterable": true
                    }, {
                        "field": "IpAddress",
                        "title": "{{'Datarooms.Eventlog.IpAddress' | translate}}",
                    }, {
                        "field": "TenantName",
                        "title": "{{'Datarooms.Eventlog.Tenant' | translate}}",
                        "filterable": true
                    }, {
                        "field": "Action",
                        "title": "{{'Datarooms.Eventlog.Action' | translate}}",
                        "filterable": true,
                        "values": this.eventlogAction
                    }, {
                        "field": "User",
                        "title": "{{'Datarooms.Eventlog.User' | translate}}",
                        "filterable": true
                    }, {
                        "field": "Details",
                        "title": "{{'Datarooms.Eventlog.Details' | translate}}",
                        "filterable": true,
                        "template": "<translate-content input='dataItem.Details'></translate-content>",
                    }
                ],

                "pageable": true,
                "sortable": true,
                "scrollable": false,
                "filterable": {
                    operators: this.localeService.getKendoFilterTranslationObject()
                },
                "dataSource": {
                    "type": 'webapi',
                    "transport": {
                        "read": this.appConfig.helper.http('get', this.appConfig.request.Eventlog.SystemUrl, this.authService.getBearerTokenObject()),
                    },
                    "serverPaging": true,
                    "pageSize": 20,
                    "serverSorting": true,
                    "serverFiltering": true,
                    "sort": [{ "field": "DateTime", "dir": "desc" }],
                    "filter": [],
                    "error": function(e:any) {
                        that.authService.kendoError(e, that.gridObject);
                    },
                    "schema": {
                        model: {
                            "id": "Id",
                            "fields": {
                                "Id": { "type": "number" },
                                "DateTime": { "type": "date" },
                                "Action": { "type": "string" },
                                "User": { "type": "string" },
                                "Details": { "type": "string" },

                                "TenantId": { "type": "number" },
                                "TenantName": { "type": "string" },
                                "IpAddress": { "type": "string" },
                            }
                        },
                        "data": "Data",
                        "total": "Total",
                        "errors": "Errors"
                    }

                }
            }
        };
    }


    export class AppSystemeventComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            eventlogAction: '<'
        };

        controller = AppSystemeventController;
        templateUrl = "/UserTemplate/EventlogTemplate";

    }

    angular.module("app").component("appSystemevent", new AppSystemeventComponent());
}

