module App {
    interface IAppDownloadFolderTrans {
        'Datarooms.DownloadFolder.NotFound': string;
        'Datarooms.DownloadFolder.Logon': string;
    }

    export class AppDownloadFolderController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;
        trans: IAppDownloadFolderTrans;
        folderId: number;
        error: string;

        //compute
        messages: string[] = [];
        data: IFolderControlViewModel;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$http', 'appConfig', 'helperService'];
        constructor(
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private helperService: HelperService
        ) {
        }

        $onInit(): void {
            if (this.error) this.messages.push(this.error);
            this.getData(this.folderId);

        }
        // ========================================
        // PUBLIC
        // ========================================
        // ========================================
        // PRIVATE
        // ========================================
        private getData(folderId:number) {
            if (!folderId) {
                this.messages.push(this.trans["Datarooms.DownloadFolder.NotFound"]);
                return;
            }
            this.appConfig.request.Folder.Details(this.$http, folderId).then(okResponse => {
                this.data = okResponse.data;
                if (this.messages.length === 0) this.messages.push(this.trans["Datarooms.DownloadFolder.Logon"]);
            }, e=> this.helperService.error(e));
        }

    }


    export class AppDownloadFolderComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
            trans: '<',
            folderId: '<',
            error: '<',
        };

        controller = AppDownloadFolderController;
        templateUrl="/UserTemplate/DownloadFolderTemplate";
    }

    angular.module("app").component("appDownloadFolder", new AppDownloadFolderComponent());
}
