module App {
    PercentFilter.$inject = ["$filter"];

    function PercentFilter($filter: ng.IFilterService): Function {
        return function (input:number, decimals:number) {
            return $filter('number')(input * 100, decimals) + '%';
        };

    }

        angular.module("app").filter("percent", PercentFilter);
}