module App {
    export interface IFileConflict {
        file: IAppFlowFile;
        retryCallback: Function;
        dropCallback?: Function;
    }

    export enum ConflictType {
        otherError = 0,
        otherUser = 1,
        fileExists = 2
    }

    export interface IConflictCollection {
        Count: number;
        otherUser: IFileConflict[];
        fileExists: IFileConflict[];
        otherError: IFileConflict[];
    }

    export class ConflictService {
        public static Factory($translate: angular.translate.ITranslateService) {
            return new ConflictService($translate);
        }

        constructor(private $translate: angular.translate.ITranslateService) {
            $translate([
                "Datarooms.Conflict.OtherUser",
                "Datarooms.Conflict.FileExist",
                "Datarooms.Conflict.Overwrite",
                "Datarooms.Conflict.Skip",
                "Datarooms.Conflict.OverwriteAll",
                "Datarooms.Conflict.SkipAll",
                "Datarooms.Conflict.Conflict",
                "Datarooms.Conflict.OtherError",
            ]).then(t => {
                this.conflictMessages.otherUser = t["Datarooms.Conflict.OtherUser"];
                this.conflictMessages.fileExists = t["Datarooms.Conflict.FileExist"];
                this.conflictMessages.otherError = t["Datarooms.Conflict.OtherError"];
                this.trans.Overwrite = t["Datarooms.Conflict.Overwrite"];
                this.trans.Skip = t["Datarooms.Conflict.Skip"];
                this.trans.OverwriteAll = t["Datarooms.Conflict.OverwriteAll"];
                this.trans.SkipAll = t["Datarooms.Conflict.SkipAll"];
                this.trans.Conflict = t["Datarooms.Conflict.Conflict"];
            });
        }


        // PROPS
        Data: IConflictCollection = { Count: 0, otherUser: [], fileExists: [], otherError: [] };

        private conflictMessages = { otherUser: "", fileExists: "", otherError :""};
        private trans = { Overwrite: "", Skip: "", OverwriteAll: "", SkipAll: "", Conflict: "" };


        // PUBLIC
        add(file: IAppFlowFile | null, conflictName: ConflictType, retryCallback: Function, dropCallback?: Function) {
            if (file === null) return;
            this.Data.Count++;
            this.getConflictObject(conflictName).push({
                file: file,
                retryCallback: retryCallback,
                dropCallback: dropCallback
            });
        }

        resolve(file: IAppFlowFile, conflictName: ConflictType, overwrite: boolean) {
            // search for file with specific conflict
            var conflicts = this.getConflictObject(conflictName).filter(c => c.file.uniqueIdentifier === file.uniqueIdentifier);
            if (!conflicts.length) {
                console.error("File not found for resolving conflict");
                return;
            }
            var obj: IFileConflict = conflicts[0];

            if (overwrite) {
                this.removeFromStack(obj.file, conflictName);
                if (angular.isFunction(obj.retryCallback)) obj.retryCallback(obj.file, conflictName);
            } else if (overwrite === false) {
                this.removeFromStack(obj.file, conflictName);
                if (angular.isFunction(obj.dropCallback)) obj.dropCallback(obj.file, conflictName);
            } else {
                console.log("No overwrite value for conflict file", file, conflictName, overwrite);
            }
        }

        resolveAll(conflictName: ConflictType, overwrite: boolean) {
            while (this.getConflictObject(conflictName).length > 0) {
                var obj = <IFileConflict>this.getConflictObject(conflictName).shift();
                this.Data.Count--;
                if (overwrite) {
                    if (angular.isFunction(obj.retryCallback)) obj.retryCallback(obj.file, conflictName);
                } else {
                    if (angular.isFunction(obj.dropCallback)) obj.dropCallback(obj.file, conflictName);
                }
            }

        }

        clear() {
            this.Data.Count = 0;
            this.Data.fileExists = [];
            this.Data.otherUser = [];
            this.Data.otherError = [];
        }


        // PRIVATE
        private removeFromStack(file: IAppFlowFile, conflictName: ConflictType) {
            for (var i = 0; i < this.getConflictObject(conflictName).length; i++) {
                if (this.getConflictObject(conflictName)[i].file === file) {
                    this.getConflictObject(conflictName).splice(i, 1);
                    this.Data.Count--;
                    return;
                }
            }

            console.error("file not found for remove from stack");
            console.log(file as any);
        }

        private getConflictObject(conflictName: ConflictType|string): IFileConflict[] {
            switch (conflictName) {
                case ConflictType.otherUser:
                case 'otherUser':
                    return this.Data.otherUser;
                case ConflictType.fileExists:
                case 'fileExists':
                    return this.Data.fileExists;
            default:
                return this.Data.otherError;
            }
        }
}

angular.module('app').factory('conflictService', ['$translate', ConflictService.Factory]);
}