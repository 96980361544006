module App {
    function GroupByFilter(): Function {
        return function (input:any, field:any) {
            if (!input || !field) return input;
            var ret:{[groupName:string]:any[]} = {};
            angular.forEach(input, function (val) {
                var groupName = val[field];
                if (!groupName) groupName = '?';

                if (!(groupName in ret)) ret[groupName] = [];
                ret[groupName].push(input);
            });
            return ret;
        };
    }

    angular.module("app").filter("groupBy", GroupByFilter);
}