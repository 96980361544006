module App {
    export class AppTwoFactorLoginController implements ng.IComponentController {
        //binding
        currentUser: IApplicationUserClientStatusViewModel;

        //compute
        view = "auth"; // auth | select (-1) | deny (0) | regsw (1) | reghw (2)
        code = "";

        isRedirected = false;
        verifySw: IAuthenticatorViewModel;
        verifyHw: ITextValue[];
        warningmessage: string;
        successmessage: string;
        serial: ITextValue;

        // ========================================
        // INFRASTRUCTURE
        // ========================================
        static $inject: string[] = ['$scope', '$translate', '$location', '$timeout', '$http', 'appConfig', 'authService', 'coreDataService', 'localeService', 'helperService', 'navigationService'];
        constructor(
            private $scope: ng.IScope,
            private $translate: angular.translate.ITranslateService,
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $http: ng.IHttpService,
            private appConfig: AppConfig,
            private authService: AuthService,
            private coreDataService: CoreDataService,
            private localeService: LocaleService,
            private helperService: HelperService,
            private navigationService: NavigationService,
        ) {
        }

        $onInit(): void {
            this.isRedirected = this.navigationService.isRedirected();

            this.initView();
        }
        // ========================================
        // PUBLIC
        // ========================================
        setView(view: string) {
            if (view === "regsw") this.enableAuthenticator();
            if (view === "reghw") this.enableToken();
            this.view = view;
        }

        activateAuthenticator() {
            if (!this.code) {
                this.successmessage = "";
                this.$translate('Core.TwoFactorLogin.ActivateAuthenticatorInput').then(msg => {
                    this.warningmessage = msg;
                });
                return;
            }
            this.authService.activateAuthenticator(this.verifySw.SecretKey, this.code, () => {
                this.$translate('Core.TwoFactorLogin.ActivateAuthenticatorSuccess').then(msg => {
                    this.successmessage = msg;
                });
                this.warningmessage = "";
                this.redirect();
            }, () => {
                this.successmessage = "";
                this.$translate('Core.TwoFactorLogin.ActivateAuthenticatorFail').then(msg => {
                    this.warningmessage = msg;
                });
                $('#codesw').focus();
            });
        };

        activateToken() {
            if (!this.serial) {
                this.successmessage = "";
                this.$translate('Core.TwoFactorLogin.ActivateTokenSelect').then(msg => {
                    this.warningmessage = msg;
                });
                return;
            }
            if (!this.code) {
                this.successmessage = "";
                this.$translate('Core.TwoFactorLogin.ActivateTokenInput').then(msg => {
                    this.warningmessage = msg;
                });
                return;
            }

            this.authService.activateToken(this.serial.text, this.code, () => {
                this.$translate('Core.TwoFactorLogin.ActivateTokenSuccess').then(msg => {
                    this.successmessage = msg;
                });
                this.warningmessage = "";
                this.redirect();
            }, () => {
                this.successmessage = "";
                this.$translate('Core.TwoFactorLogin.ActivateTokenFail').then(msg => {
                    this.warningmessage = msg;
                });
                $('#codehw').focus();
            });
        }

        verifyAuthenticator() {
            this.authService.verifyAuthenticator(this.code, ()=> {
                this.$translate('Core.TwoFactorLogin.VerifySuccess').then(msg => {
                    this.successmessage = msg;
                });
                this.warningmessage = "";
                this.redirect();
            }, () => {
                this.successmessage = "";
                this.$translate('Core.TwoFactorLogin.VerifyFail').then(msg => {
                    this.warningmessage = msg;
                });
            });

        }

        // ========================================
        // PRIVATE
        // ========================================
        private initView() {
            if (this.currentUser.IsTwoFactorAuthenticated) {
                this.view = "alreadyOk";
                return;
            }

            if (this.currentUser.isAuthenticatorEnabled) {
                this.view = "auth";
                $('#codeauth').focus();
            } else {
                switch (this.currentUser.authenticatorType) {
                case -1: // allow all
                case 0: // not allowed 2fa
                    this.view = "select";
                    break;
                case 1: // allow sw
                    this.setView("regsw");
                    //this.view = "regsw";
                    break;
                case 2: // allow hw
                    this.setView("reghw");
                    //this.view = "reghw";
                    break;
                default:
                    console.error("invalid value for authenticatorType");
                }
            }

        };


        private redirect() {
            this.authService.init(true, false).then(ok => {
                this.$timeout(() => {
                    this.navigationService.navigateBack();
                }, 3000);
            });
        }

        private enableAuthenticator() {
            this.authService.enableAuthenticator(data => {
                this.verifySw = data;
            });
        }

        private enableToken() {
            this.authService.enableToken(data => {
                this.verifyHw = data;
            });
        }
    }


    export class AppTwoFactorLoginComponent implements ng.IComponentOptions {
        bindings: { [index: string]: string; } = {
            currentUser: '<',
        };

        controller:any = AppTwoFactorLoginController;
        templateUrl="/UserTemplate/TwoFactorLoginTemplate";
    }

    angular.module("app").component("appTwoFactorLogin", new AppTwoFactorLoginComponent());
}

